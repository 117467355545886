import React from 'react';
import { useNavigate } from 'react-router-dom';

const Terminos = () => {
    const navigate = useNavigate();

    const handleBackToLogin = () => {
        navigate('/');
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="bg-white p-6 rounded-lg shadow-lg max-w-2xl">
                <h2 className="text-2xl font-bold mb-4">Términos y Condiciones</h2>
                <p className="text-gray-700 mb-4">
                Entrada en vigencia: 1 de Agosto de 2020 <br/><br/>

Estos Términos y Condiciones (“Términos y Condiciones”) forman un acuerdo legal entre Docty Inc., y usted, el individuo (“usted” y “su”) en relación con su acceso y uso de los diversos servicios ofrecidos a través de los Sitios web y las Plataformas (como se define a continuación). El uso de cualquiera de los Sitios web o Plataformas constituye su aceptación de estos Términos y Condiciones. 
<br/><br/>
Docty Inc. en su propio nombre y en nombre de sus filiales y/o subsidiarias de propiedad absoluta, (colectivamente denominados “Docty Inc.” “nosotros”, “nos” o “nuestro”), pone a su disposición información relacionada con la telesalud y/o facilita su acceso a la telemedicina y a los servicios médicos expertos (“Servicios de Docty Inc.”). Docty Inc. es propietaria y opera (i) varios sitios web disponibles públicamente, incluyendo sin limitación, docty.ai, co.docty.ai (colectivamente, “Sitios Web”), y (ii) varias aplicaciones web y móviles que requieren que usted cree una cuenta, con un usuario y una contraseña por usted suministrada, para poder utilizar un Docty Inc. (colectivamente, “Plataformas”). 
<br/><br/>
Al utilizar los Sitios web y/o las Plataformas, usted acepta expresamente estos Términos y condiciones. Si no está de acuerdo con estos Términos y condiciones, deberá cesar inmediatamente todo uso y acceso a todos los Sitios web y Plataformas. Por favor, imprima una copia de estos Términos y Condiciones para sus registros. 
<br/><br/>
Contrato Electrónico de Licencia y Términos y Condiciones De Uso de la Plataforma Pacientes del Software Docty 
<br/><br/>
<strong>MODIFICACIONES DE ESTOS TÉRMINOS Y CONDICIONES </strong>
 <br/><br/>
Docty Inc. puede, a su entera discreción y sin previo aviso, revisar estos Términos y Condiciones en cualquier momento. En caso de que estos Términos y Condiciones cambien materialmente, Docty Inc. actualizará la fecha de vigencia indicada anteriormente y publicará un aviso sobre los Términos y Condiciones actualizados en los Sitios Web. Los Términos y Condiciones enmendados también aparecerán cuando usted acceda a las Plataformas y tendrá que reconocer su acuerdo con los Términos y Condiciones actualizados y/o enmendados antes de poder seguir utilizando las Plataformas. Si no está de acuerdo con los términos de los Términos y Condiciones enmendados, su único y exclusivo remedio es interrumpir su uso de los Sitios web y Plataformas y se considerará que ha terminado estos Términos y Condiciones. Los Términos y Condiciones enmendados entrarán en vigor a partir de la Fecha de entrada en vigor, a menos que se indique lo contrario. Al acceder o utilizar los Sitios web y las Plataformas después de que se publiquen dichos cambios, usted acepta y consiente todos los cambios. 
<br/><br/>
<strong>ACCESO A LAS PLATAFORMAS </strong>
<br/><br/>
Usted puede acceder y utilizar las Plataformas a través de la conectividad de acceso remoto: Docty Inc., le concede una licencia limitada, no exclusiva, no sublicenciable, revocable e intransferible para utilizar las Plataformas de acuerdo con estos Términos y Condiciones. Para poder utilizar las Plataformas, se le pedirá que registre una cuenta y que cree una información de acceso, incluyendo sin limitación, el nombre de usuario y las contraseñas. Debe salvaguardar la información de inicio de sesión que utiliza para acceder a las Plataformas, la cual es de su entera responsabilidad, y no debe revelar esta información a nadie. Deberá notificar inmediatamente a Docty Inc. cualquier uso no autorizado de su cuenta de usuario o cualquier otra violación de la seguridad de la que tenga conocimiento que involucre y esté relacionada con las Plataformas por correo electrónico a privacy@docty.ai. 
<br/><br/>
<strong>EL ROL DE DOCTY INC.</strong> 
<br/><br/>
Docty Inc. no es un proveedor de seguros ni tampoco un almacén de recetas. El papel de Docty Inc. se limita a poner a su disposición información relacionada con la telesalud y/o a facilitarle el acceso a la telemedicina y a los servicios médicos, generales y especializados. Docty Inc. es independiente de los proveedores de servicios de salud que le prestarán dichos servicios de telemedicina a través de la Plataforma y no se hace responsable de los actos, omisiones o contenido de las comunicaciones que realicen dichos proveedores de servicios de salud. Docty Inc. no se dedica a la práctica de la medicina ni proporciona ninguna clase de servicio de salud. 
<br/><br/>

Al registrarse en las Plataformas, usted representa y garantiza lo siguiente: (i) que tiene al menos dieciocho (18) años de edad, (ii) que tiene la capacidad y la autoridad legal para suscribir estos Términos y Condiciones con Docty Inc, (iii) la información que ha proporcionado a Docty Inc. en su registro es exacta y completa, (iv) cumplirá con todas y cada una de las leyes aplicables al uso de los Sitios web y Plataformas, (v) no interferirá con el uso y disfrute de los Sitios web y Plataformas por parte de un tercero, (vi) no interferirá ni interrumpirá el funcionamiento de Docty Inc. o sus proveedores, (vii) si cualquier información que proporcione a Docty Inc. se vuelve inexacta, incompleta o falsa o engañosa, notificará inmediatamente a Docty Inc, (viii), usted reconoce que el acceso a los servicios de atención médica proporcionados a través de las Plataformas no son “servicios asegurados” bajo ningún plan de salud provincial, (ix) usted reconoce que cualquier tarifa pagada por usted o por cualquier otra persona en su nombre no se proporciona a cambio de ningún compromiso por parte de Docty Inc. o sus representantes de que dichos servicios de atención médica se pondrán a su disposición, y (x) usted está accediendo a los Sitios web y Plataformas por usted mismo o ejerciendo la representación legal de un menor de dieciocho años del que es el tutor legal. 
<br/><br/>
<strong>TERMINACIÓN</strong>
<br/><br/>
Si usted viola estos Términos y Condiciones, su capacidad de utilizar los Sitios Web y/o Plataformas será terminada. Docty Inc. podrá, a su entera discreción, cancelar su acceso a los Sitios Web y/o Plataformas, o a cualquier parte de los mismos, por cualquier motivo y sin previo aviso. Estas acciones se suman a cualquier otro derecho o recurso que Docty Inc. pueda tener disponible por ley. Además, Docty Inc. no será responsable ante usted ni ante ningún tercero por dicha terminación o interrupción. Usted puede dar por terminados estos Términos y Condiciones dejando de acceder y utilizar los Sitios Web y las Plataformas. Tras la finalización de estos Términos y Condiciones, deberá cesar inmediatamente el uso de los Sitios web y las Plataformas. En la medida en que lo permita la ley aplicable, las renuncias, las limitaciones de responsabilidad, la terminación y sus garantías e indemnizaciones sobrevivirán a cualquier terminación de estos Términos y Condiciones. 
<br/><br/>
<strong>USOS PROHIBIDOS </strong>
<br/><br/>
Al usar los Sitios Web y Plataformas, usted acepta no hacerlo: 
<br/><br/>
Enviar o transmitir de otro modo a o a través de los Sitios web y Plataformas cualquier información inexacta y/o falsa, material ilegal, infractor, dañino, acosador, difamatorio, amenazador, odioso o de otro modo objetable de cualquier tipo, cualquier material que pueda causar daño o retraso a los Sitios web y Plataformas o a las computadoras de cualquier tipo, y cualquier publicidad, solicitud o material promocional no solicitado; 
<br/><br/>
Tergiversar su identidad o afiliación de cualquier manera; 
<br/><br/>
Restringir o inhibir el uso de los Sitios Web y Plataformas por parte de cualquier persona, revelar información personal obtenida de los Sitios Web y Plataformas o recopilar información sobre los usuarios de los Sitios Web y Plataformas; 
<br/><br/>
Realizar ingeniería inversa, desarmar o descompilar cualquier sección o tecnología de los Sitios Web y las Plataformas, o intentar hacer cualquiera de lo anterior; 
<br/><br/>
Obtener acceso no autorizado a los Sitios web y Plataformas, a las cuentas, nombres, información de identificación personal u otra información de otros usuarios, o a otras computadoras o sitios web conectados o vinculados a los Sitios web y Plataformas; 
<br/><br/>
Lanzar o utilizar cualquier sistema automatizado, incluidos, entre otros, “robots”, “arañas” o “lectores fuera de línea”, que accedan a los Sitios web y las Plataformas de manera que envíen más mensajes de solicitud a nuestros servidores en un período de tiempo determinado de los que un humano pueda producir razonablemente en el mismo período utilizando un navegador web convencional; 
<br/><br/>
Enviar o transmitir de otro modo a o a través de los Sitios y Plataformas cartas en cadena, mensajes no solicitados, los llamados mensajes de “spam” o “phishing”, o mensajes de comercialización o publicidad de bienes y servicios; 
<br/><br/>
Publicar, transmitir o poner a disposición de cualquier otro modo cualquier virus, gusano, spyware o cualquier otro código, archivo o programa informático que pueda o esté destinado a dañar o secuestrar el funcionamiento de cualquier hardware, software o equipo de telecomunicaciones; 
<br/><br/>
Violar cualquier ley o reglamento aplicable de cualquier manera; 
<br/><br/>
Alterar o modificar cualquier parte del contenido o los servicios ofrecidos en o a través de los Sitios Web y Plataformas; 
<br/><br/>
Permitir que cualquier otra persona utilice las Plataformas con su información de registro o acceso; 
<br/><br/>
Violar o burlar de alguna manera las medidas de seguridad o autenticación de Docty Inc. 
<br/><br/>
Ayudar o permitir a cualquier persona a participar en cualquiera de las actividades descritas anteriormente. 
<br/><br/>
<strong>DERECHOS DE PROPIEDAD INTELECTUAL Y CONTENIDO </strong>
<br/><br/>
Docty Inc. es el único y exclusivo propietario de los Sitios Web y Plataformas, incluidos todos y cada uno de los derechos de autor, patentes, marcas comerciales, secretos comerciales y otros derechos de propiedad y propiedad intelectual, en y para los Sitios Web y Plataformas y cualquier material y documentación relacionados. No se le transfiere ningún título o propiedad de los Sitios web y Plataformas ni ninguna parte de los mismos. Docty Inc. se reserva todos los derechos que no se concedan expresamente en el presente documento. Usted se compromete a no cambiar o eliminar ningún aviso de derechos de autor o de propiedad relacionado con los materiales descargados de los Sitios Web y/o Plataformas. 
<br/><br/>
Usted puede proporcionar información, comentarios o sugerencias con respecto a los Sitios y Plataformas o a Docty Inc. (en adelante, “comentarios”). Usted reconoce y acepta que Docty Inc. puede utilizar cualquier Feedback sin ninguna obligación hacia usted y por el presente concede a Docty Inc. una licencia mundial, perpetua, irrevocable, libre de regalías y transferible para reproducir, mostrar, ejecutar, distribuir, publicar, modificar, editar o utilizar de cualquier otra manera el Feedback que Docty Inc. considere apropiado, sin restricciones, para cualquier y todo propósito comercial y/o no comercial, a su única discreción. 
<br/><br/>
<strong>ENLACES EXTERNOS </strong>
<br/><br/>
Los sitios web y las plataformas pueden contener enlaces a sitios web de terceros. Los sitios vinculados no están bajo el control de Docty Inc. y Docty Inc. no es responsable del contenido de ningún sitio vinculado. Los enlaces se proporcionan sólo por conveniencia, y un enlace no implica que Docty Inc. respalde, patrocine o esté afiliado con el sitio enlazado. El uso que usted haga de los sitios web de terceros es bajo su propio riesgo y está sujeto a los términos y condiciones de uso de dichos sitios; estos términos no se aplican a otros sitios web. Docty Inc. renuncia a cualquier y toda responsabilidad por cualquier información, incluyendo, pero sin limitación, cualquier información sobre tratamientos médicos y de salud establecidos en los sitios enlazados. 
<br/><br/>
<strong>NO HAY ENDOSOS. </strong>
<br/><br/>
La referencia a cualquier producto, grabación, evento, proceso, publicación, servicio u oferta de un tercero por su nombre, nombre comercial, marca registrada, marca de servicio, nombre de la empresa o de otro modo no constituye ni implica el respaldo o la recomendación de los mismos por parte de Docty Inc. Cualquier opinión expresada por terceros en los Sitios Web y Plataformas es únicamente la opinión de dicho tercero y Docty Inc. no asume ninguna responsabilidad por la exactitud o veracidad de cualquier declaración hecha por dicho tercero. 
<br/><br/>
<strong>INDEMNIZACIÓN Y LIMITACIÓN DE RESPONSABILIDAD </strong>
<br/><br/>
Usted acepta defender, indemnizar y mantener a Docty Inc.., sus funcionarios, directores, empleados, accionistas, afiliados, terceros contratistas, agentes, licenciatarios y proveedores (cada uno de ellos una Docty Party y colectivamente Docty Parties), de y contra cualquier reclamación, acción o demanda, pérdidas, responsabilidades, daños, costos, gastos y acuerdos (incluyendo sin limitación los honorarios razonables de abogados y contabilidad), que resulten de o supuestamente resulten de, directa o indirectamente, su (a) violación de estos Términos y Condiciones; (b) acceso o uso de la Plataforma y los Sitios Web; y (c) provisión de otra divulgación a Docty Inc. de cualquier otra información o datos y el uso de los mismos por parte de Docty Inc. u otra Docty Party según se contempla en el presente documento. 
<br/><br/>
EN NINGÚN CASO DOCTY PARTIES HEALTH SERÁ RESPONSABLE DE NINGÚN DAÑO DIRECTO, INDIRECTO, ESPECIAL, PUNITIVO, INCIDENTAL, EJEMPLAR O CONSECUENTE, NI DE NINGÚN DAÑO QUE RESULTE DE CUALQUIER PÉRDIDA DE USO, PÉRDIDA DE BENEFICIOS, LITIGIO O CUALQUIER OTRA PÉRDIDA PECUNIARIA, YA SEA POR INCUMPLIMIENTO DE CONTRATO, AGRAVIO (INCLUYENDO NEGLIGENCIA), RESPONSABILIDAD POR PRODUCTOS O DE CUALQUIER OTRA FORMA, QUE SURJA O ESTÉ RELACIONADA DE ALGUNA MANERA CON LOS SITIOS WEB O LAS PLATAFORMAS O CON LA PROVISIÓN O NO PROVISIÓN DE DICHOS PRODUCTOS, BIENES O SERVICIOS, INCLUSO SI SE HA ADVERTIDO DE LA POSIBILIDAD DE DICHOS DAÑOS. 
<br/><br/>
Docty Inc. no será responsable del incumplimiento de las obligaciones en virtud del presente documento cuando dicho incumplimiento se deba a cualquier causa que escape al control razonable de Docty Inc., incluyendo, sin limitación, fallos o degradación mecánica, electrónica o de las comunicaciones. 
<br/><br/>
El alcance de estos Términos y Condiciones que limitan la responsabilidad reflejan una asignación voluntaria e informada del riesgo; dicha asignación representa una parte material de estos Términos y Condiciones. Usted acepta que las limitaciones de responsabilidad establecidas en estos Términos y Condiciones son justas y razonables en las circunstancias en las que se permite la utilización de las plataformas y el acceso a los servicios que se prestan a través de estas. 
<br/><br/>
<strong>DESCARGO DE RESPONSABILIDAD </strong>
<br/><br/>
Los sitios web y las plataformas se proporcionan “tal cual” y “según disponibilidad” y sin garantías de ningún tipo, ni expresas ni implícitas. En la medida en que lo permita la legislación aplicable, Docty Inc. renuncia a todas las representaciones, garantías y condiciones, expresas o implícitas, incluidas, entre otras, las condiciones o garantías implícitas de comerciabilidad e idoneidad para un fin determinado. Docty Inc. no garantiza que los sitios web y las plataformas no se interrumpan o estén libres de errores, que los defectos se corrijan o que los sitios web y las plataformas o el servidor que los pone a disposición estén libres de virus u otros componentes dañinos. 
<br/><br/>
Docty Inc. no garantiza y rechaza cualquier garantía o representación implícita sobre su exactitud, relevancia, puntualidad, integridad o adecuación de cualquier contenido publicado en los Sitios Web y Plataformas para un propósito particular. Docty Heath no asume ninguna responsabilidad derivada o relacionada con el retraso, fallo, interrupción o corrupción de cualquier dato u otra información transmitida en relación con el uso de los Sitios web y/o Plataformas. 
<br/><br/>
Es posible que la legislación aplicable no permita la exclusión de las garantías implícitas, por lo que la exclusión anterior puede no aplicarse a usted. 
<br/><br/>
<strong>NIÑOS </strong>
<br/><br/>
Los presentes términos y condiciones están destinados única y exclusivamente para permitir el uso de personas mayores de dieciocho (18) años. Los Sitios Web y Plataformas no están destinados a niños menores de 13 años. Si usted es menor de 13 años, por favor no utilice o acceda a los Sitios Web o Plataformas en ningún momento o de ninguna manera. Al usar los Sitios web o las Plataformas, usted afirma que es mayor de 13 años. Docty Inc. no busca a través de los Sitios web o Plataformas recopilar información personal de o sobre personas menores de 13 años sin el consentimiento expreso y a través de un padre o tutor. 
<br/><br/>
<strong>ELECCIÓN DE LA LEY Y LA JURISDICCIÓN </strong>
<br/><br/>
Estos Términos y Condiciones se rigen por las leyes Colombianas. Las partes se someten irrevocablemente a la jurisdicción exclusiva de Colombia respecto a todos los asuntos y disputas que surjan en virtud del presente, y renuncian a cualquier defensa de falta de jurisdicción personal en esa jurisdicción. Estos Términos y Condiciones no se rigen por la Convención de las Naciones Unidas sobre los Contratos de Compraventa Internacional de Mercaderías, cuya aplicación queda expresamente excluida por el presente documento. Si cualquier disposición de estos Términos y Condiciones es considerada inválida por cualquier tribunal con jurisdicción competente, la invalidez de dicha disposición no afectará a la validez del resto de las disposiciones de estos Términos y Condiciones, que permanecerán en pleno vigor y efecto. 
<br/><br/>
<strong>INFORMACIÓN PERSONAL Y POLÍTICA DE PRIVACIDAD </strong>
<br/><br/>
En estos Términos y Condiciones, “información personal” significa cualquier información sobre un individuo identificable, como su nombre, dirección de correo electrónico, dirección postal, sexo, fecha de nacimiento, cualquier información personal o de salud protegida, o cualquier dato sobre usted que elija proporcionar electrónicamente a través de los Sitios Web y Plataformas y cualquier otra información que identifique quién es usted. La información personal será utilizada por Docty Inc. únicamente de acuerdo con estos Términos y Condiciones, y con la Política de Privacidad Web y Móvil de Docty Inc. (o según lo acordado por escrito entre usted y Docty Inc.). 
<br/><br/>
Usted acepta que Docty Inc. tiene el derecho de monitorear y revisar su uso de los Sitios Web y Plataformas de manera regular, y de usar “cookies”, “archivos de registro” y sus “datos de navegación” de acuerdo con la Política de Cookies. Ver ampliación Anexo No. 1   Política de Privacidad 
<br/><br/>
<strong>RENUNCIA </strong>
<br/><br/>
Ninguna demora u omisión por parte de Docty Inc. para ejercer cualquier derecho o poder que tenga en virtud de estos Términos y Condiciones o para objetar el incumplimiento de cualquier convenio de usted para ser ejecutado de manera oportuna y completa, perjudicará cualquier derecho o poder de este tipo o se interpretará como una renuncia a cualquier incumplimiento posterior o cualquier otro convenio. Toda renuncia por parte de Docty Inc. deberá hacerse por escrito y estar firmada por un representante autorizado de Docty Inc. 
<br/><br/>
<strong>ACUERDO COMPLETO </strong>
<br/><br/>
Estos Términos y Condiciones constituyen el acuerdo completo entre usted y Docty Inc. en lo que se refiere al acceso y uso de la Plataforma y los Sitios Web y el objeto de estos Términos y Condiciones y sustituyen todos los acuerdos, negociaciones, representaciones y propuestas anteriores o contemporáneas, escritas u orales entre Docty Inc. y usted. 
<br/><br/>
<strong>DOCUMENTOS ELECTRÓNICOS </strong>
<br/><br/>
Este documento electrónico, y todos los demás documentos electrónicos a los que se haga referencia o se incorporen al mismo, serán: a) Considerados a todos los efectos como un “escrito” o “por escrito”, y que cumplen con todos los requisitos legales, contractuales y de otro tipo de un escrito; y b) jurídicamente exigibles como un acuerdo firmado. La versión impresa de las presentes Condiciones Generales y cualquier aviso dado en forma electrónica serán admisibles en los procedimientos judiciales o administrativos basados en las presentes Condiciones Generales o relacionados con ellas en la misma medida y con sujeción a las mismas condiciones que otros documentos y registros comerciales originalmente generados y mantenidos en forma impresa. 
<br/><br/>
<strong>CESIÓN </strong>
<br/><br/>
Estos Términos y Condiciones son personales para usted, y no son asignables, transferibles o sublicenciables por usted, excepto con el consentimiento previo por escrito de Docty Inc. Docty Inc. puede asignar, transferir o delegar cualquiera de sus derechos y obligaciones en virtud del presente documento sin su consentimiento. 
<br/><br/>
<strong>DERECHOS DE PROPIEDAD INTELECTUAL Y PUBLICIDAD </strong>
<br/><br/>
El material gráfico, audiovisual, publicitario, fotográfico y afines contenido en el Software Licenciado, así como el código software que soporta el Software Licenciado, es de propiedad exclusiva de Docty , o de terceros que han autorizado a Docty  para su uso y/o explotación económica.  Cualquier uso o actividad de los USUARIOS que infrinja las anteriores disposiciones constituirá una violación a estos Términos y Condiciones y a las normas de propiedad intelectual aplicables en la República de Colombia. 
<br/><br/>
<strong>INDEMNIDAD </strong>
<br/><br/>
El Usuario mantendrá indemne a Docty , así como a sus filiales, empresas controladas y/o controlantes, funcionarios, directivos, sucesores, administradores, representantes y empleados, por cualquier reclamo iniciado cualquier tercero o autoridad, relacionado con sus actividades en el Software Licenciado, el cumplimiento y/o el incumplimiento de los Términos y Condiciones Generales o demás Políticas, así como respecto de cualquier violación de leyes o derechos de terceros. 
<br/><br/>
<strong>CONSENTIMIENTO INFORMADO. </strong>
<br/><br/>
Al aceptar los términos de servicio se informa el alcance, los riesgos, los beneficios, las responsabilidades, el manejo de la privacidad y confidencialidad, el manejo de sus datos personales, los protocolos de contacto de acuerdo al servicio de telesalud o telemedicina que elija utilizar. 
<br/><br/>
<strong>LEY </strong>
<br/><br/>
Estos Términos y Condiciones se regirán e interpretarán de acuerdo con las leyes de la República de Colombia, y estará sujeto a la jurisdicción ordinaria colombiana. 
<br/><br/>
<strong>IDIOMA </strong>
<br/><br/>
El español será el idioma de estos Términos y las partes renuncian a cualquier derecho a usar y confiar en cualquier otro idioma o traducciones. 
<br/><br/>
<strong>CONTACTO / AVISOS </strong>
<br/><br/>
Si tiene alguna pregunta sobre los Términos y Condiciones, o necesita notificar o comunicarse con Docty Inc. en virtud de los Términos y Condiciones, póngase en contacto con Docty Inc. utilizando los siguientes datos de contacto: 
<br/><br/>
Docty Inc. 
<br/><br/>
Privacidad 
<br/><br/>
179 W Rocks, Rd. 
<br/><br/>
Norwalk, CT 06851 
<br/><br/>
privacy@docty.ai 
<br/><br/>
Docty Inc. puede proporcionarle avisos o comunicaciones en los Sitios Web y/o Plataformas y usted acepta que dichos avisos constituirán una notificación para usted, independientemente de que acceda o no a la notificación. 
                </p>
                <button
                    onClick={handleBackToLogin}
                    className="mt-4 px-4 py-2 bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white rounded-lg hover:bg-blue-600"
                >
                    Volver
                </button>
            </div>
        </div>
    );
};

export default Terminos;
