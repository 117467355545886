import React, { useState } from 'react';

const CrearConsentimientoModal = ({ isOpen, onClose, servicios, onSave }) => {
    const [nombreConsentimiento, setNombreConsentimiento] = useState('');
    const [servicioLigado, setServicioLigado] = useState('');
    const [consentimiento, setConsentimiento] = useState('');

    const handleSave = () => {
        const newConsentimiento = {
            nombreConsentimiento,
            servicioLigado,
            consentimiento,
        };
        onSave(newConsentimiento);
        setNombreConsentimiento('');
        setServicioLigado('');
        setConsentimiento('');
        onClose();
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded shadow-lg">
                <h2 className="text-xl font-semibold mb-4">Crear Consentimiento</h2>
                <div className="mb-4">
                    <label className="block text-gray-700">Nombre del Consentimiento:</label>
                    <input
                        type="text"
                        required
                        value={nombreConsentimiento}
                        onChange={(e) => setNombreConsentimiento(e.target.value)}
                        className="w-full px-3 py-2 border rounded"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700">Servicio Ligado:</label>
                    <select
                        value={servicioLigado}
                        required
                        onChange={(e) => setServicioLigado(e.target.value)}
                        className="w-full px-3 py-2 border rounded"
                    >
                        <option value="" required>Seleccione una opcion</option>
                        {servicios.map((servicio) => (
                            <option key={servicio._id} value={servicio.service}>
                                {servicio.service}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700">Consentimiento:</label>
                    <textarea
                        required
                        value={consentimiento}
                        onChange={(e) => setConsentimiento(e.target.value)}
                        className="w-full px-3 py-2 border rounded"
                    />
                </div>
                <div className="flex justify-end">
                    <button
                        onClick={handleSave}
                        className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white px-4 py-2 rounded mr-2"
                    >
                        Guardar
                    </button>
                    <button
                        onClick={onClose}
                        className="bg-gray-500 text-white px-4 py-2 rounded"
                    >
                        Cancelar
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CrearConsentimientoModal;
