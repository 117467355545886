import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import Swal from 'sweetalert2';
import Select from 'react-select';
import { useCombobox } from 'downshift';


const AyudasDiagnosticas = ({ appointmentId }) => {
  const [appointmentInfo, setAppointmentInfo] = useState(null);
  const [enfermedadesOptions, setEnfermedadesOptions] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [cupsOptions, setCupsOptions] = useState([]);
  const [searchQueryCups, setSearchQueryCups] = useState('');
  const [diagnosticos, setDiagnosticos] = useState([]); // Para almacenar los diagnósticos


  const [diagnostico, setDiagnostico] = useState({
    cie10: null,
    descripcionDiagnostico: '',
  });

  const fetchAppointmentInfo = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/clinic/appointment-info/${appointmentId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setAppointmentInfo(response.data);
    } catch (error) {
      console.error('Error al obtener la información de la cita:', error);
    }
  };

  useEffect(() => {
    const fetchDiagnosticos = async () => {
      try {
        const token = localStorage.getItem('token');

        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/clinic/diagnosticos/${appointmentId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Guardamos los diagnósticos en el estado
        setDiagnosticos(response.data);
      } catch (error) {
        console.error('Error fetching diagnósticos:', error);
      }
    };

    fetchDiagnosticos();
  }, [appointmentId]);

  useEffect(() => {
    fetchAppointmentInfo();
  }, [appointmentId]);

  const [examenes, setExamenes] = useState([]);

  const handleChangeDiagnostico = (selectedOption) => {
    setDiagnostico({ ...diagnostico, cie10: selectedOption });
  };

  const validateExamenes = () => {
    for (const examen of examenes) {
      if (!examen.codigo || !examen.cantidad) {
        return false;
      }
    }
    return true;
  };

  const handleChangeDescripcion = (e) => {
    setDiagnostico({ ...diagnostico, descripcionDiagnostico: e.target.value });
  };

  const handleExamenChange = (index, field, value) => {
    console.log('handleExamenChange llamado con:', { index, field, value });

    setExamenes(prevExamenes => {
      const newExamenes = [...prevExamenes];
      console.log('Antiguo examen:', newExamenes[index]);

      if (index >= 0 && index < newExamenes.length) {
        newExamenes[index] = { ...newExamenes[index], [field]: value };
        console.log('Nuevo examen:', newExamenes[index]);
      } else {
        console.error('Índice inválido:', index);
      }

      return newExamenes;
    });
  };
  const addExamen = () => {
    setExamenes([...examenes, { codigo: '', descripcion: '', cantidad: '', observacion: '' }]);
  };

  const removeExamen = (index) => {
    const updatedExamenes = examenes.filter((_, i) => i !== index);
    setExamenes(updatedExamenes);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (diagnosticos.length === 0) {
      Swal.fire({
        title: 'Error',
        text: 'Debe tener al menos un diagnóstico.',
        icon: 'warning',
        confirmButtonText: 'Aceptar',
      });
      return;
    }

    if (examenes.length === 0) {
      Swal.fire('Error', 'Debe agregar al menos un examen.', 'error');
      return;
    }

    if (!validateExamenes()) {
      Swal.fire('Error', 'Debe completar todos los campos de los exámenes.', 'error');
      return;
    }

    try {
      const token = localStorage.getItem('token');
      const clinicId = localStorage.getItem('clinicId');

      const response = await axios({
        url: `${process.env.REACT_APP_BACKEND_URL}/api/pdf/generateAyudasDiagnosticasPDF`,
        method: 'POST',
        data: {
          clinicId: clinicId,
          appointmentId,
          patientInfo: {
            patientId: appointmentInfo.patientId,
            firstName: appointmentInfo.firstName,
            secondName: appointmentInfo.secondName,
            firstLastName: appointmentInfo.firstLastName,
            secondLastName: appointmentInfo.secondLastName,
            documentNumber: appointmentInfo.documentNumber,
            dob: appointmentInfo.dob,
            idType: appointmentInfo.idType,
            gender: appointmentInfo.gender,
            cellNumber: appointmentInfo.cellNumber,
          },
          diagnosticos,
          examenes,
          doctorInfo: {
            doctorId: appointmentInfo.doctorId,
            fullName: appointmentInfo.fullName,
            service: appointmentInfo.service,
            idNumber: appointmentInfo.idNumber,
            cellNumber: appointmentInfo.cellNumber,
            notes: appointmentInfo.notes,
          },
        },
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        responseType: 'blob',
      });

      // Crear un enlace y descargar el archivo
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `ayudas-diagnosticas-${appointmentId}.pdf`); // Nombre del archivo PDF
      document.body.appendChild(link);
      link.click();
      link.remove();

      Swal.fire({
        title: '¡PDF Generado!',
        text: 'El archivo PDF ha sido generado y descargado exitosamente.',
        icon: 'success',
        confirmButtonText: 'Aceptar',
      });
    } catch (error) {
      console.error('Error al generar el PDF de exámenes de laboratorio:', error);
      Swal.fire({
        title: 'Error',
        text: 'Hubo un problema al generar el PDF.',
        icon: 'error',
        confirmButtonText: 'Aceptar',
      });
    }
  };


  const normalizeAndSplit = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().split(" ");
  };

  const fetchCups = async (query) => {
    setLoading(true);

    try {
      const normalizedQueryWords = normalizeAndSplit(query); // Normaliza y divide el query
      const token = localStorage.getItem('token');

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/clinic/searchCups`,
        {
          params: { query: normalizedQueryWords.join(" ") }, // Unir las palabras normalizadas para enviar al backend
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const options = response.data.map((cup) => ({
        value: cup.Codigo,
        label: cup.Nombre,
        descripcion: cup.Descripcion
      }));
      setCupsOptions(options);
    } catch (error) {
      console.error('Error al obtener la lista de CUPS:', error);
    } finally {
      setLoading(false);
    }
  };

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getItemProps,
    highlightedIndex,
  } = useCombobox({
    items: cupsOptions,
    onInputValueChange: ({ inputValue }) => {
      fetchCups(inputValue);
    },
    onSelectedItemChange: ({ selectedItem }) => {
      if (!selectedItem) {
        console.log('No se seleccionó ningún elemento');
        return;
      }

      // Encontrar el índice en `cupsOptions`
      const index = cupsOptions.findIndex(option => option.value === selectedItem.value);

      if (index !== -1) {
        // Actualizar el estado con los valores seleccionados
        handleExamenChange(index, 'codigo', selectedItem.value);
        handleExamenChange(index, 'descripcion', selectedItem.label);
      } else {
        console.error('Elemento seleccionado no encontrado en cupsOptions');
      }

      console.log('Estado actualizado:', examenes);
    },

    itemToString: (item) => (item ? item.label : ''),
  });


  const fetchEnfermedadOptions = async (query) => {
    setLoading(true);

    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/clinic/searchCie10`,
        {
          params: { query },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log('Response:', response.data);
      const options = response.data.map((med) => ({
        value: med.Codigo,  // Usamos Icd10Code como valor
        label: med.Nombre,
        descripcion: med.Descripcion
      }));
      setEnfermedadesOptions(options);
    } catch (error) {
      console.error('Error al obtener la lista de medicamentos:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchQueryChangeCups = async (inputValue) => {
    setSearchQueryCups(inputValue);
    if (inputValue.length > 2) {
      await fetchCups(inputValue);
    } else {
      setCupsOptions([]);
    }
  };


  const handleCupsChange = (index, selectedOption) => {
    const updatedExamenes = [...examenes];
    if (selectedOption) {
      updatedExamenes[index] = {
        ...updatedExamenes[index],
        codigo: selectedOption.value,
        descripcion: selectedOption.label,
      };
    } else {
      updatedExamenes[index] = {
        ...updatedExamenes[index],
        codigo: '',
        descripcion: '',
      };
    }
    setExamenes(updatedExamenes);
  };

  const handleSearchQueryChange = async (inputValue) => {
    setSearchQuery(inputValue);
    if (inputValue.length > 2) {
      await fetchEnfermedadOptions(inputValue);
    } else {
      setEnfermedadesOptions([]);
    }
  };

  const handleDenominacionChange = (selectedOption) => {
    setDiagnostico((prevState) => ({
      ...prevState,
      cie10: selectedOption ? {
        codigo: selectedOption.value, // Código (A01.2, por ejemplo)
        descripcion: selectedOption.label // Título de la enfermedad
      } : null,
      descripcionDiagnostico: selectedOption ? selectedOption.descripcion : '' // Actualiza la descripción automáticamente
    }));
  };




  return (
    <div className="h-[400px] overflow-y-auto p-4"> {/* Añadido contenedor con scroll */}
      <h2 className="text-center font-bold bg-blue-600 p-2 text-white text-lg">Ayuda Diagnóstica</h2>
      <hr className="my-4" />

      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200 border">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">
                Código CIE-10
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">
                Nombre del Diagnóstico
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">
                Tipo de Diagnóstico
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {diagnosticos.map((diagnostico) => (
              <tr key={diagnostico._id}>
                <td className="px-6 py-4 max-w-xs whitespace-normal break-words text-sm font-medium text-gray-900">
                  {diagnostico.codigo}
                </td>
                <td className="px-6 py-4 max-w-xs whitespace-normal break-words text-sm text-gray-500">
                  {diagnostico.nombre}
                </td>
                <td className="px-6 py-4 max-w-xs whitespace-normal break-words text-sm text-gray-500">
                  {diagnostico.tipo}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

      </div>
      <hr className="my-4" />

      {/* Tabla de Exámenes */}
      <h3 className="text-lg font-semibold mb-4">Listado de Exámenes</h3>

      <div className="space-y-4">
        {examenes.map((examen, index) => (
          <div key={index} className="bg-white shadow-md rounded-lg p-4">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold">Examen {index + 1}</h3>
              <button
                type="button"
                onClick={() => removeExamen(index)}
                className="bg-red-500 text-white px-2 py-1 rounded-md hover:bg-red-600"
              >
                Eliminar
              </button>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">Código / Nombre CUPS:</label>
              <div className="mt-1 block w-full">
                <input
                  {...getInputProps()}
                  placeholder="Buscar..."
                  className="border rounded p-2 w-full"
                />
                <ul {...getMenuProps()} className="border rounded max-h-48 overflow-y-auto">
                  {isOpen && (loading ? (
                    <li>Cargando...</li>
                  ) : (
                    cupsOptions.map((option, optionIndex) => (
                      <li
                        key={option.value}
                        {...getItemProps({ item: option, index: optionIndex })}
                        data-index={optionIndex} // Asegúrate de que esto sea el índice correcto
                        className={`p-2 cursor-pointer ${highlightedIndex === optionIndex ? 'bg-gray-200' : ''}`}
                      >
                        {option.label}
                      </li>
                    ))
                  ))}
                </ul>

              </div>
            </div>


            {/*<div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">Descripción:</label>
              <input
                type="text"
                name="descripcion"
                value={examen.descripcion}
                onChange={(e) => handleExamenChange(index, 'descripcion', e.target.value)}
                className="w-full border p-2 border-gray-300 rounded-md"
                placeholder="Descripción"
              />
            </div>*/}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">Cantidad:</label>
              <input
                type="number"
                name="cantidad"
                value={examen.cantidad}
                onChange={(e) => handleExamenChange(index, 'cantidad', e.target.value)}
                className="w-full border p-2 border-gray-300 rounded-md"
                placeholder="Cantidad"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">Observación:</label>
              <input
                type="text"
                name="observacion"
                value={examen.observacion}
                onChange={(e) => handleExamenChange(index, 'observacion', e.target.value)}
                className="w-full border p-2 border-gray-300 rounded-md"
                placeholder="Observación"
              />
            </div>
          </div>
        ))}
      </div>


      <button
        type="button"
        onClick={addExamen}
        className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white px-4 py-2 rounded-md hover:bg-blue-600 mb-4"
      >
        Añadir
      </button>

      <button
        type="submit"
        onClick={handleSubmit}
        className="w-full bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white py-2 rounded-md hover:bg-blue-700"
      >
        Enviar
      </button>
    </div>
  );
};

export default AyudasDiagnosticas;
