import React, { useEffect, useState } from 'react';
import axios from 'axios';

const HistoriasOffline = () => {
  const [historias, setHistorias] = useState([]);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedHistoria, setSelectedHistoria] = useState(null);

  const fetchHistorias = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/mobile/getHistoriaClinicaOffline`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      setHistorias(response.data.data);
    } catch (err) {
      setError('Error al obtener las historias clínicas.');
      console.error('Error al obtener los datos:', err);
    }
  };

  useEffect(() => {
    fetchHistorias();
  }, []);

  const openModal = (historia) => {
    setSelectedHistoria(historia);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedHistoria(null);
  };

  const handleOutsideClick = (e) => {
    if (e.target.id === 'modal-background') {
      closeModal();
    }
  };

  if (error) {
    return <p className="text-red-500 text-center">{error}</p>;
  }

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-3xl font-semibold mb-6 text-center text-blue-900">Historias Clínicas Offline</h2>
      <div className="flex justify-end mb-6">
        <button
          onClick={fetchHistorias}
          className="bg-gradient-to-r from-blue-500 to-blue-700 text-white px-6 py-2 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:-translate-y-1"
        >
          Actualizar
        </button>
        
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {historias.map((historia, index) => (
          <div
            key={index}
            className="bg-white shadow-lg rounded-xl p-6 border border-gray-300 hover:shadow-2xl transition-shadow duration-300"
          >
            <p><span className="font-bold text-gray-700">ID:</span> {historia._id}</p>
            <p><span className="font-bold text-gray-700">Nombre:</span> {historia.nombre}</p>
            <p><span className="font-bold text-gray-700">Cédula:</span> {historia.cedula}</p>
            <p><span className="font-bold text-gray-700">Correo:</span> {historia.correo}</p>
            <p><span className="font-bold text-gray-700">Teléfono:</span> {historia.telefono}</p>
            <p><span className="font-bold text-gray-700">Dirección:</span> {historia.direccion}</p>
            <p><span className="font-bold text-gray-700">Sexo:</span> {historia.sexo}</p>

            <button
              onClick={() => openModal(historia)}
              className="mt-6 px-6 py-2 bg-gradient-to-r from-blue-500 to-blue-700 text-white rounded-lg hover:bg-blue-600 transition-all duration-300"
            >
              Ver Diagnósticos
            </button>
          </div>
        ))}
      </div>

      {/* Modal para mostrar los diagnósticos */}
      {showModal && selectedHistoria && (
        <div
          id="modal-background"
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50"
          onClick={handleOutsideClick}
        >
          <div className="bg-white p-8 rounded-2xl max-w-2xl w-full max-h-[80vh] overflow-y-scroll shadow-lg relative">
            <h3 className="text-2xl font-bold mb-6">Diagnósticos de {selectedHistoria.nombre}</h3>
            {Object.keys(selectedHistoria.diagnosticos).map((key) => {
              const diagnostico = selectedHistoria.diagnosticos[key];
              return (
                <div key={key} className="mt-4 border-t border-gray-300 pt-4">
                  <p><span className="font-bold text-gray-600">Motivo de consulta:</span> {diagnostico.motivo_de_consulta}</p>
                  <p><span className="font-bold text-gray-600">Resultados paraclínicos:</span> {diagnostico.resultados_paraclinicos}</p>
                  <p><span className="font-bold text-gray-600">Alertas:</span> {diagnostico.alertas}</p>
                  <p><span className="font-bold text-gray-600">Alergias:</span> {diagnostico.alergias}</p>
                  <p><span className="font-bold text-gray-600">Análisis y plan:</span> {diagnostico.analisis_y_plan}</p>
                  <p><span className="font-bold text-gray-600">Diagnóstico (CIE-10):</span> {diagnostico.diagnostico_CIE_10}</p>
                  <p><span className="font-bold text-gray-600">Recomendaciones:</span> {diagnostico.recomendaciones}</p>
                </div>
              );
            })}
            <button
              onClick={closeModal}
              className="mt-6 px-6 py-2 bg-gradient-to-r from-red-500 to-red-700 text-white rounded-lg hover:bg-red-600 transition-all duration-300"
            >
              Cerrar
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default HistoriasOffline;
