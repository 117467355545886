import React, { useState, useEffect } from 'react';

const CapacityModal = ({ isOpen, onClose, onSave, sedes }) => {
    const [selectedSede, setSelectedSede] = useState('');
    const [selectedSedeName, setSelectedSedeName] = useState('');
    const [formData, setFormData] = useState({
        group: '',
        concept: '',
        quantity: ''
    });

    const groupOptions = [
        { value: 'ambulancias', label: 'Ambulancias' },
        { value: 'camas', label: 'Camas' },
        { value: 'camillas', label: 'Camillas' },
        { value: 'consultorios', label: 'Consultorios' },
        { value: 'salas', label: 'Salas' },
        { value: 'sillas', label: 'Sillas' },
        { value: 'unidad_movil', label: 'Unidad Móvil' },
    ];

    const conceptOptions = {
        ambulancias: [
            'Básica', 'Especializada'
        ],
        camas: [
            'Pediátrica', 'Adultos', 'Obstetricia', 'Cuidado intermedio neonatal', 'Cuidado intensivo neonatal',
            'Cuidado intermedio pediátrico', 'Cuidado intensivo pediátrico', 'Cuidado intermedio adulto',
            'Cuidado intensivo adulto', 'Unidad de quemados adulto', 'Psiquiatría', 'Farmacodependencia',
            'Unidad de quemados pediátrico', 'Cuidado agudo mental', 'Cuidado intermedio mental', 'Institución paciente crónico',
            'Transplante de progenitores hematopoyeticos', 'Cuidado básico neonatal', 'Salud mental', 'TPR',
            'Salud mental adulto', 'Salud mental pediátrico', 'Paciente crónico con ventilador',
            'Paciente crónico sin ventilador', 'SPA adultos', 'SPA pediátricas', 'SPA básico adultos',
            'SPA básico pediátricos', 'Incubadora intermedia neonatal', 'Intermedia pediátrica',
            'Intermedia adultos', 'Incubadora intensiva neonatal', 'Intensiva pediátrica', 'Intensiva adultos',
            'Incubadora básico neonatal', 'Cuna intensiva neonatal', 'Cuna intensiva pediátrica',
            'Intensiva quemado adulto', 'Cuna básico neonatal', 'Intensiva quemado pediátrica', 'Atención del parto'
        ],
        camillas: [
            'Observación pediátrica', 'Salud mental', 'SPA', 'Otras patologías',
            'Observación adultos hombres', 'Observación adultos mujeres'
        ],
        consultorios: ['Urgencias', 'Consulta externa'],
        salas: ['Quirófano', 'Partos', 'Procedimientos', 'Sala de cirugía', 'Sala de radioterapia'],
        sillas: [
            'Sillas de quimioterapia', 'Sillas de hemodiálisis', 'Salud mental', 'SPA',
            'Otras patologías', 'Ambiente de transición urgencias'
        ],
        unidad_movil: ['Unidad móvil'],
    };

    useEffect(() => {
        // Actualiza el nombre de la sede cuando cambia el código de la sede
        const selected = sedes.find(sede => sede.code === selectedSede);
        setSelectedSedeName(selected ? selected.name : '');
    }, [selectedSede, sedes]);

    const handleSedeChange = (e) => {
        setSelectedSede(e.target.value);
    };

    const handleCapacityChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (selectedSede) {
            onSave({ ...formData, sedeCode: selectedSede, sedeName: selectedSedeName });
            setFormData({
                group: '',
                concept: '',
                quantity: ''
            });
            setSelectedSede('');
            setSelectedSedeName('');
        }
    };

    if (!isOpen) {
        return null;
    }

    return (
        <div className="modal-overlay fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="modal-content bg-white p-6 rounded-lg shadow-lg w-full max-w-3xl">
                <h2 className="text-2xl font-bold mb-4">Agregar Capacidad</h2>
                <form onSubmit={handleSubmit}>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                        <div className="form-group">
                            <label className="block text-gray-700">Sede</label>
                            <select
                                name="sede"
                                value={selectedSede}
                                onChange={handleSedeChange}
                                className="w-full px-3 py-2 border rounded"
                                required
                            >
                                <option value="">Seleccione una sede</option>
                                {sedes.map(sede => (
                                    <option key={sede.code} value={sede.code}>
                                        {sede.name} - {sede.departmentName} - {sede.City || sede.mainCity}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label className="block text-gray-700">Grupo</label>
                            <select
                                name="group"
                                value={formData.group}
                                onChange={handleCapacityChange}
                                className="w-full px-3 py-2 border rounded"
                                required
                            >
                                <option value="">Seleccione Grupo</option>
                                {groupOptions.map(group => (
                                    <option key={group.value} value={group.value}>{group.label}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label className="block text-gray-700">Concepto</label>
                            <select
                                name="concept"
                                value={formData.concept}
                                onChange={handleCapacityChange}
                                className="w-full px-3 py-2 border rounded"
                                required
                            >
                                <option value="">Seleccione Concepto</option>
                                {conceptOptions[formData.group]?.map((concept, index) => (
                                    <option key={index} value={concept}>{concept}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label className="block text-gray-700">Cantidad</label>
                            <input
                                type="number"
                                name="quantity"
                                value={formData.quantity}
                                onChange={handleCapacityChange}
                                placeholder="Cantidad"
                                className="w-full px-3 py-2 border rounded"
                                required
                            />
                        </div>
                    </div>
                    <div className="flex justify-between mt-4">
                        <button
                            type="button"
                            onClick={onClose}
                            className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-700"
                        >
                            Cancelar
                        </button>
                        <button
                            type="submit"
                            className="px-4 py-2 bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white rounded hover:bg-blue-900"
                        >
                            Agregar
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CapacityModal;
