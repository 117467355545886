import React, { useState, useEffect, useRef } from "react";
import { Realtime } from "ably";

function App({ appointmentId }) { // Pasamos el ID de la videollamada como prop
  const [message, setMessage] = useState(""); // Mensaje que escribe el usuario
  const [messages, setMessages] = useState([]); // Todos los mensajes
  const ably = useRef(
    new Realtime({
      key: "6bgz8Q.pc07CQ:rjC34iblLGHkCAcy4YUVArd0gFn0cg4WKVuXgEKsNR4", // Reemplaza con tu clave real de Ably
    })
  ); // Reutilizar la misma instancia de Ably
  const channel = useRef(null); // Referencia al canal
  const messagesEndRef = useRef(null);

  useEffect(() => {
    // Usa el ID de la videollamada para generar un canal único
    const uniqueChannelName = `chat-video-${appointmentId}`;
    channel.current = ably.current.channels.get(uniqueChannelName); // Canal específico por videollamada

    // Escuchar los mensajes que llegan en el canal
    channel.current.subscribe((msg) => {
      // Diferenciar si el mensaje es del usuario actual o de otro
      const isCurrentUser = msg.connectionId === ably.current.connection.id;
      setMessages((prevMessages) => [
        ...prevMessages,
        { from: isCurrentUser ? "Me" : "You", body: msg.data },
      ]);
    });

    // Limpiar la suscripción cuando el componente se desmonta
    return () => {
      channel.current.unsubscribe();
    };
  }, [appointmentId]); // Escucha el cambio en el videoCallId para cambiar de canal si es necesario
  
  // useEffect para mandar hacia abajo el mensaje
  useEffect(() => {
    // Desplazar hacia abajo al añadir un nuevo mensaje
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  // Función para enviar un mensaje
  const handleSubmit = (e) => {
    e.preventDefault(); // Evitar el comportamiento por defecto del formulario
    if (message.trim() === "") return; // No permitir mensajes vacíos

    // Enviar el mensaje a través de Ably
    channel.current.publish("message", message);

    // Limpiar el campo de texto
    setMessage("");
  };

  return (
    <div className="h-[570px] flex items-center justify-center bg-gray-100">
      <div className="w-full max-w-md bg-white rounded-lg shadow-lg">
        <div className="p-4 border-b">
          <h1 className="text-2xl font-bold text-gray-800">Chat</h1>
        </div>
        <ul className="max-h-60 overflow-y-auto p-4">
          {messages.map((message, i) => (
            <li
              key={i}
              className={`my-2 p-2 rounded-md ${
                message.from === "Me" ? "bg-sky-700 text-white ml-auto" : "bg-gray-200 text-black"
              }`}
            >
              <strong>{message.from}: </strong>{message.body}
            </li>
          ))}
          <div ref={messagesEndRef} />
        </ul>
        <form onSubmit={handleSubmit} className="flex items-center p-4 border-t">
          <input
            type="text"
            placeholder="Escribe el mensaje"
            className="flex-1 border-2 border-gray-300 rounded-md p-2"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <button
            type="submit"
            className="ml-2 bg-blue-500 text-white rounded-md px-4 py-2 hover:bg-blue-600"
          >
            Enviar
          </button>
        </form>
      </div>
    </div>
  );
}

export default App;
