import React, { useState } from "react";
import PatientDoctorDocumentsTable from "./PatientDoctorDocumentsTable";

const DoctorAssignedDocuments = () => {
    const [doctorId, setDoctorId] = useState(localStorage.getItem('doctorId'));

    return (
        <div className="flex justify-center items-center m-0">
            <div className="w-full max-w-screen-lg">
                <PatientDoctorDocumentsTable doctorId={doctorId} isDownload={true} />
            </div>

        </div>
    )
}

export default DoctorAssignedDocuments;