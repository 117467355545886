import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import esLocale from '@fullcalendar/core/locales/es';
import { format } from '@formkit/tempo';
import Swal from 'sweetalert2';
import '../../styles/Patient.css';

const DoctorCalendar = ({ doctor, show, setActiveComponent }) => {
    if (!show) return null;

    const generateEvents = (calendarioOficina) => {
        const events = [];
        const ocupadosDates = doctor.diasOcupados.map(dateStr => new Date(dateStr));

        // Función para crear un evento de ocupado
        const createOccupiedEvent = (start, end) => {
            return {
                title: '',
                start: start,
                end: end,
                classNames: ['marketplace-event-ocupado'], // Clase para mostrar como ocupado
                display: 'block',
                editable: false, // No se puede editar
                selectable: false, // No se puede seleccionar
                durationEditable: false, // No se puede cambiar la duración
            };
        };

        const isIntervalOccupied = (start) => {
            return ocupadosDates.some(ocupado => {
                return (
                    ocupado.getDate() === start.getDate() &&
                    ocupado.getMonth() === start.getMonth() &&
                    ocupado.getFullYear() === start.getFullYear() &&
                    ocupado.getHours() === start.getHours() &&
                    ocupado.getMinutes() === start.getMinutes()
                );
            });
        };

        const currentDate = new Date();
        const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 2, 0); // Hasta el final de dos meses

        // Iterar sobre cada día desde hoy hasta el último día del mes
        for (let d = new Date(currentDate); d <= lastDayOfMonth; d.setDate(d.getDate() + 1)) {
            calendarioOficina.forEach(({ dia, intervalos }) => {
                const dayOfWeek = getDayOfWeek(dia);

                if (d.getDay() !== dayOfWeek) return; // Solo procesar días que coincidan

                intervalos.forEach(({ inicio, fin }) => {
                    const start = new Date(d);
                    start.setHours(parseInt(inicio.split(':')[0]), parseInt(inicio.split(':')[1]));
                    const end = new Date(d);
                    end.setHours(parseInt(fin.split(':')[0]), parseInt(fin.split(':')[1]));

                    const isOccupied = isIntervalOccupied(start);

                    // Si el intervalo está ocupado, agrega un evento de ocupado
                    if (isOccupied) {
                        const occupiedEvent = createOccupiedEvent(start, end);
                        events.push(occupiedEvent);
                    } else {
                        // Agregar el evento disponible
                        events.push({
                            title: '',
                            start,
                            end,
                            classNames: ['marketplace-event-disponibilidad'],
                            display: 'block',
                        });
                    }
                });
            });
        }

        return events;
    };

    const getDayOfWeek = (dia) => {
        const days = ['domingo', 'lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado'];
        return days.indexOf(dia.toLowerCase());
    };


    const handleEventClick = (info) => {
        // Verifica si el evento es ocupado
        if (info.event.classNames.includes('marketplace-event-ocupado')) {
            Swal.fire({
                icon: 'error',
                title: 'Cita ocupada',
                text: 'Este horario ya está reservado.',
                confirmButtonText: 'Cerrar',
            });
            return; // No continuar si el evento es ocupado
        }

        const inicio = format(info.event.start, 'HH:mm A');
        const fin = format(info.event.end, 'HH:mm A');
        Swal.fire({
            icon: 'info',
            title: 'Disponibilidad seleccionada',
            text: `Has seleccionado un horario disponible: ${inicio} - ${fin}`,
            confirmButtonText: 'Continuar',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                const selectedDate = {
                    doctorId: doctor._id,
                    doctor: doctor.fullName,
                    servicio: doctor.service,
                    precioServicio: doctor.priceService,
                    fecha: info.event.start,
                };
                localStorage.setItem('selectedDate', JSON.stringify(selectedDate));
                setActiveComponent('agendar_cita');
            }
        });
    };

    return (
        <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            initialView="timeGridWeek"
            events={generateEvents(doctor.calendarioOficina)}
            headerToolbar={{
                left: 'prev,next today',
                center: 'title',
                right: 'timeGridWeek',
            }}
            locale={esLocale}
            selectable={true}
            weekends={true}
            slotLabelFormat={{
                hour: '2-digit',
                minute: '2-digit',
                omitZeroMinute: false,
                meridiem: 'short',
                hour12: true,
            }}
            slotDuration={"00:20:00"}
            slotEventOverlap={false}
            allDaySlot={false}
            eventClick={handleEventClick}
            views={{
                timeGridWeek: {
                    dayCount: 14,
                }
            }}
        />
    );
};

export default DoctorCalendar;
