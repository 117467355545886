import React, { useState, useContext, useEffect } from 'react';
import { FormContext } from './FormContext';
import AgregarInventario from './AgregarInventario';
import Swal from 'sweetalert2';
import { Title } from '@mui/icons-material';

const AgregarBodega = ({ handleClose, handleAddBodega, initialBodega }) => {
    const { formState } = useContext(FormContext);
    const [nombreBodega, setNombreBodega] = useState('');
    const [sedeLigada, setSedeLigada] = useState('');
    const [inventario, setInventario] = useState([]);
    const [fechaCreacion, setFechaCreacion] = useState('');
    const [isModalInsumoOpen, setIsModalInsumoOpen] = useState(false);

    useEffect(() => {
        if (initialBodega) {
            setNombreBodega(initialBodega.nombreBodega || '');
            setSedeLigada(initialBodega.sedeLigada || '');
            setInventario(initialBodega.inventario || []);
            setFechaCreacion(initialBodega.fechaCreacion || '');
        }
    }, [initialBodega]);

    const handleSedeChange = (e) => {
        setSedeLigada(e.target.value);
    };

    const handleAgregarInsumo = (insumo) => {
        setInventario([...inventario, insumo]);
    };

    const handleRemoveInsumo = (index) => {
        const updatedInventario = inventario.filter((_, i) => i !== index);
        setInventario(updatedInventario);
    };

    const handleSave = () => {
        if (!nombreBodega || !sedeLigada || !fechaCreacion || inventario.length === 0) {
            Swal.fire({
                title: 'Todos los campos son obligatorios y debe haber al menos un insumo en el inventario.',

                icon: 'error',
            });
            return; 
        }
        handleAddBodega({
            nombreBodega,
            sedeLigada,
            fechaCreacion,
            inventario
        });
        handleClose();
    };

    return (
        <div className="modal-overlay fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="modal-content bg-white p-6 rounded-lg shadow-lg w-full max-w-lg">
                <h2 className="text-xl font-bold mb-4">{initialBodega ? 'Editar bodega' : 'Agregar bodega'}</h2>
                <div className="mb-4">
                    <label className="block text-gray-700">Fecha de creación</label>
                    <input
                        type="date"
                        value={fechaCreacion}
                        onChange={(e) => setFechaCreacion(e.target.value)}
                        className="w-full px-3 py-2 border rounded"
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700">Nombre de la bodega</label>
                    <input
                        type="text"
                        value={nombreBodega}
                        onChange={(e) => setNombreBodega(e.target.value)}
                        className="w-full px-3 py-2 border rounded"
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700">Sede ligada</label>
                    <select
                        value={sedeLigada}
                        onChange={handleSedeChange}
                        className="w-full px-3 py-2 border rounded"
                        required
                    >
                        <option value="">Seleccione una sede</option>
                        {formState.sedes.map((sede, index) => (
                            <option key={index} value={sede.code}>
                                {sede.name} - {sede.departmentName} - {sede.City || sede.mainCity} - {sede.code}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="mb-4">
                    <h3 className="text-lg font-bold">Inventario de bodega</h3>
                    <div className="border rounded p-2">
                        {inventario.length === 0 ? (
                            <p>No hay insumos agregados</p>
                        ) : (
                            <ul>
                                {inventario.map((item, index) => (
                                    <li key={index} className="flex justify-between items-center border-b py-1">
                                        {item.producto} - {item.cantidad}
                                        <button
                                            className="bg-red-500 text-white font-bold py-1 px-2 rounded hover:bg-red-700"
                                            onClick={() => handleRemoveInsumo(index)}
                                        >
                                            X
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                    <button
                        onClick={() => setIsModalInsumoOpen(true)}
                        className="mt-2 bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white font-bold py-2 px-4 rounded hover:bg-blue-700"
                    >
                        Agregar
                    </button>
                </div>
                <div className="flex justify-between mt-4">
                    <button
                        className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-700"
                        onClick={handleClose}
                    >
                        Cancelar
                    </button>
                    <button
                        className="px-4 py-2 bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white rounded hover:bg-blue-700"
                        onClick={handleSave}
                    >
                        Guardar
                    </button>
                </div>
            </div>

            {/* Modal AgregarInventario */}
            {isModalInsumoOpen && (
                <AgregarInventario
                    handleClose={() => setIsModalInsumoOpen(false)}
                    handleAgregarInventario={(insumo) => {
                        handleAgregarInsumo({
                            producto: insumo.producto,
                            cantidad: insumo.cantidad,
                            ...insumo // Guardamos toda la información en el inventario
                        });
                        setIsModalInsumoOpen(false);
                    }}
                />
            )}
        </div>
    );
};

export default AgregarBodega;
