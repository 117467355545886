import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AddressModal from './AddressModal';
import Swal from 'sweetalert2';


const OfficeModal = ({ isOpen, onClose, onSave }) => {
    const initialFormState = {
        name: '',
        department: '',
        code: '',
        address: '',
        phone: '',
        email: '',
        zone: '',
        attentionLevel: '',
        complexity: ''
    };

    const [formData, setFormData] = useState(initialFormState);
    const [departments, setDepartments] = useState([]);
    const [cities, setCities] = useState([]);
    const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);

    useEffect(() => {
        if (isOpen) {
            // Fetch departments when modal opens
            const fetchDepartments = async () => {
                try {
                    const token = localStorage.getItem('token'); // Assuming token is stored in localStorage

                    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/clinic/departments`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setDepartments(response.data);
                } catch (error) {
                    console.error('Error fetching departments:', error);
                }
            };

            fetchDepartments();
        } else {
            setFormData(initialFormState);
        }
    }, [isOpen]);

    useEffect(() => {
        if (formData.department) {
            // Fetch municipalities when a department is selected
            const fetchCities = async () => {
                if (formData.department) {
                    try {
                        const token = localStorage.getItem('token'); // Assuming token is stored in localStorage

                        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/clinic/cities/${formData.department}`, {
                            headers: {
                                'Authorization': `Bearer ${token}`
                            }
                        });
                        setCities(response.data);
                    } catch (error) {
                        console.error('Error fetching cities:', error);
                    }
                }
            };

            fetchCities();
        } else {
            setCities([]);
        }
    }, [formData.department]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };
    const handleDepartmentChange = (event) => {
        const selectedId = event.target.value;
        const selectedDepartment = departments.find(dept => dept._id === selectedId);
        
        setFormData({
            ...formData,
            department: selectedId, // ID del departamento
            departmentName: selectedDepartment ? selectedDepartment.departamento : '', // Nombre del departamento
        });
    };
    const handleAddressSave = (address) => {
        setFormData({
            ...formData,
            address: address
        });
        setIsAddressModalOpen(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!formData.address) {
            Swal.fire({
                title: 'La dirección es obligatoria',
                icon: 'error',
            });
            return; // Salir de la función para evitar el guardado
        }
        onSave(formData);
        setFormData(initialFormState); // Limpiar el formulario después de guardar
        onClose();
    };

    if (!isOpen) {
        return null;
    }

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center overflow-auto">
            <div className="bg-white p-4 rounded-lg shadow-lg w-full max-w-lg md:max-w-3xl mx-2 md:p-6 overflow-y-auto max-h-screen">
                <h2 className="text-2xl font-bold mb-4">Agregar Sede</h2>
                <form onSubmit={handleSubmit}>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div className="form-group">
                            <label className="block text-gray-700">Nombre de la Sede</label>
                            <input
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                placeholder="Nombre de la Sede"
                                className="w-full px-3 py-2 border rounded"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label className="block text-gray-700">Departamento</label>
                            <select
                                name="department"
                                value={formData.department}
                                onChange={handleDepartmentChange}
                                className="w-full px-3 py-2 border rounded"
                                required
                            >
                                <option value="">Opciones de departamentos</option>
                                {departments.map(department => (
                                    <option key={department._id} value={department._id}>
                                        {department.departamento}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label className="block text-gray-700">Ciudad</label>
                            <select
                                name="City"
                                value={formData.City}
                                onChange={handleChange}
                                className="w-full px-3 py-2 border rounded"
                                required
                            >
                                <option value="">Seleccione Ciudad</option>
                                {cities.length > 0 ? (
                                    cities.map((city, index) => (
                                        <option key={index} value={city}>
                                            {city}
                                        </option>
                                    ))
                                ) : (
                                    <option value="">No hay ciudades disponibles</option>
                                )}
                            </select>
                        </div>
                        <div className="form-group">
                            <label className="block text-gray-700">Código</label>
                            <input
                                type="text"
                                name="code"
                                value={formData.code}
                                onChange={handleChange}
                                placeholder="Código de la sede"
                                className="w-full px-3 py-2 border rounded"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label className="block text-gray-700">Dirección</label>
                            <div className="relative">
                                <input
                                    type="text"
                                    name="address"
                                    value={formData.address}
                                    onChange={handleChange}
                                    placeholder="Dirección"
                                    className="w-full px-3 py-2 border rounded bg-gray-100 cursor-not-allowed"
                                    readOnly
                                />
                                <button
                                    type="button"
                                    onClick={() => setIsAddressModalOpen(true)}
                                    className="absolute right-2 top-2 px-3 py-1 bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white rounded hover:bg-blue-700"
                                >
                                    Agregar
                                </button>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="block text-gray-700">Teléfono</label>
                            <input
                                type="text"
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                                placeholder="Teléfono"
                                className="w-full px-3 py-2 border rounded"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label className="block text-gray-700">Correo electrónico</label>
                            <input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                placeholder="Correo electrónico"
                                className="w-full px-3 py-2 border rounded"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label className="block text-gray-700">Zona</label>
                            <select
                                name="zone"
                                value={formData.zone}
                                onChange={handleChange}
                                className="w-full px-3 py-2 border rounded"
                                required
                            >
                                <option value="">Seleccione zona</option>
                                <option value="rural">Rural</option>
                                <option value="urbana">Urbana</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label className="block text-gray-700">Nivel de atención</label>
                            <select
                                name="attentionLevel"
                                value={formData.attentionLevel}
                                onChange={handleChange}
                                className="w-full px-3 py-2 border rounded"
                                required
                            >
                                <option value="">Seleccione nivel</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label className="block text-gray-700">Complejidad</label>
                            <select
                                name="complexity"
                                value={formData.complexity}
                                onChange={handleChange}
                                className="w-full px-3 py-2 border rounded"
                                required
                            >
                                <option value="">Seleccione complejidad</option>
                                <option value="Baja">Baja</option>
                                <option value="Mediana">Mediana</option>
                                <option value="Alta">Alta</option>
                            </select>
                        </div>
                    </div>
                    <div className="flex justify-between mt-4">
                        <button
                            type="button"
                            onClick={onClose}
                            className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-700"
                        >
                            Cancelar
                        </button>
                        <button
                            type="submit"
                            className="px-4 py-2 bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white rounded hover:bg-blue-700"
                        >
                            Agregar
                        </button>
                    </div>
                </form>
                <AddressModal
                    isOpen={isAddressModalOpen}
                    onClose={() => setIsAddressModalOpen(false)}
                    onSave={handleAddressSave}
                />
            </div>
        </div>
    );
};

export default OfficeModal;
