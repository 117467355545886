import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment'; 

const PersonalInfo = ({ appointmentId }) => {
  const [patientInfo, setPatientInfo] = useState(null);

  
  const formatDate = (dob) => {
    return moment(dob).add(1, 'days').format('DD/MM/YYYY');
  };
  

  
  const calculateAge = (dob) => {
    const birthDate = moment(dob);
    const today = moment();
    return today.diff(birthDate, 'years'); 
  };

  
  const translateGender = (gender) => {
    return gender === 'male' ? 'Masculino' : 'Femenino';
  };

  useEffect(() => {
    const fetchPatientInfo = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/clinic/appointment-info/${appointmentId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setPatientInfo(response.data);
      } catch (error) {
        console.error('Error al obtener la información del paciente:', error);
      }
    };

    fetchPatientInfo();
  }, [appointmentId]);

  if (!patientInfo) {
    return <div className="p-4 bg-white shadow-lg rounded-md">Cargando información...</div>;
  }

  return (
    <div className="p-4 bg-white shadow-lg rounded-lg max-w-4xl mx-auto h-[540px] "> {/* Contenedor con scroll */}
      <h2 className="text-2xl font-semibold mb-6 text-gray-800">Información Personal</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
        <div className="flex flex-col">
          <label className="text-gray-600 font-medium mb-2">Nombre Completo</label>
          <input
            type="text"
            value={`${patientInfo.firstName} ${patientInfo.secondName} ${patientInfo.firstLastName} ${patientInfo.secondLastName}`}
            readOnly
            className="bg-gray-100 border border-gray-300 rounded-md p-3 text-gray-700"
          />
        </div>
        <div className="flex flex-col">
          <label className="text-gray-600 font-medium mb-2">Número de Documento</label>
          <input
            type="text"
            value={patientInfo.documentNumber}
            readOnly
            className="bg-gray-100 border border-gray-300 rounded-md p-3 text-gray-700"
          />
        </div>
        <div className="flex flex-col">
          <label className="text-gray-600 font-medium mb-2">Fecha de Nacimiento</label>
          <input
            type="text"
            value={formatDate(patientInfo.dob)}
            readOnly
            className="bg-gray-100 border border-gray-300 rounded-md p-3 text-gray-700"
          />
        </div>
        <div className="flex flex-col">
          <label className="text-gray-600 font-medium mb-2">Tipo de Identificación</label>
          <input
            type="text"
            value={patientInfo.idType}
            readOnly
            className="bg-gray-100 border border-gray-300 rounded-md p-3 text-gray-700"
          />
        </div>
        <div className="flex flex-col">
          <label className="text-gray-600 font-medium mb-2">Edad</label>
          <input
            type="text"
            value={calculateAge(patientInfo.dob)} 
            readOnly
            className="bg-gray-100 border border-gray-300 rounded-md p-3 text-gray-700"
          />
        </div>
        <div className="flex flex-col">
          <label className="text-gray-600 font-medium mb-2">Sexo Biológico</label>
          <input
            type="text"
            value={translateGender(patientInfo.gender)} 
            readOnly
            className="bg-gray-100 border border-gray-300 rounded-md p-3 text-gray-700"
          />
        </div>
        <div className="flex flex-col">
          <label className="text-gray-600 font-medium mb-2">Número de Celular</label>
          <input
            type="text"
            value={patientInfo.cellNumber}
            readOnly
            className="bg-gray-100 border border-gray-300 rounded-md p-3 text-gray-700"
          />
        </div>
        <div className="col-span-2">
          <label className="text-gray-600 font-medium mb-2">Motivo de la consulta</label>
          <textarea
            value={patientInfo.notes}
            readOnly
            rows="2"
            className="bg-gray-100 border border-gray-300 rounded-md p-3 text-gray-700 w-full"
          />
        </div>
      </div>
    </div>
  );
};

export default PersonalInfo;
