import React, { useState } from 'react';
import Medicamentos from '../Formularios/Medicamentos';
import Incapacidad from '../Formularios/Incapacidad';
import ExamenesLaboratorio from '../Formularios/ExamenesLaboratorio';
import AyudasDiagnosticas from '../Formularios/AyudasDiagnosticas';
import Interconsulta from '../Formularios/Interconsulta';
import ApoyoTerapeutico from '../Formularios/ApoyoTerapeutico';

const PreescribirForm = ({ appointmentId }) => {
  const [activeTab, setActiveTab] = useState('medicamentos'); // Pestaña activa por defecto

  const renderTabContent = () => {
    switch (activeTab) {
      case 'medicamentos':
        return <Medicamentos appointmentId={appointmentId} />;
      case 'incapacidad':
        return <Incapacidad appointmentId={appointmentId} />;
      case 'examenes':
        return <ExamenesLaboratorio appointmentId={appointmentId} />;
      case 'ayudas':
        return <AyudasDiagnosticas appointmentId={appointmentId} />;
      case 'interconsulta':
        return <Interconsulta appointmentId={appointmentId} />;
      case 'apoyo':
        return <ApoyoTerapeutico appointmentId={appointmentId} />;
      default:
        return null;
    }
  };

  return (
    <div className="bg-white p-6 shadow-md rounded-lg">
      {/* Contenedor scrollable con borde inferior */}
      <div className="relative mb-4">

        <div className="overflow-x-auto scrollbar-hide">
          <div className="flex whitespace-nowrap pb-2">
            <button
              className={`px-4 py-2 focus:outline-none ${activeTab === 'medicamentos' ? 'border-b-2 border-blue-600' : ''}`}
              onClick={() => setActiveTab('medicamentos')}
            >
              Medicamentos
            </button>
            <button
              className={`px-4 py-2 focus:outline-none ${activeTab === 'incapacidad' ? 'border-b-2 border-blue-600' : ''}`}
              onClick={() => setActiveTab('incapacidad')}
            >
              Incapacidad
            </button>
            <button
              className={`px-4 py-2 focus:outline-none ${activeTab === 'examenes' ? 'border-b-2 border-blue-600' : ''}`}
              onClick={() => setActiveTab('examenes')}
            >
              Exámenes de Laboratorio
            </button>
            <button
              className={`px-4 py-2 focus:outline-none ${activeTab === 'ayudas' ? 'border-b-2 border-blue-600' : ''}`}
              onClick={() => setActiveTab('ayudas')}
            >
              Ayudas Diagnósticas
            </button>
            <button
              className={`px-4 py-2 focus:outline-none ${activeTab === 'interconsulta' ? 'border-b-2 border-blue-600' : ''}`}
              onClick={() => setActiveTab('interconsulta')}
            >
              Interconsulta
            </button>
            <button
              className={`px-4 py-2 focus:outline-none ${activeTab === 'apoyo' ? 'border-b-2 border-blue-600' : ''}`}
              onClick={() => setActiveTab('apoyo')}
            >
              Apoyo Terapéutico
            </button>
          </div>
        </div>
        <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-gray-300"/>
      </div>

      {/* Contenido de la pestaña activa */}
      <div className="p-4">
        {renderTabContent()}
      </div>
    </div>
  );
};

export default PreescribirForm;
