import React, { useEffect, useState } from 'react';
import axios from 'axios';
import TableDocuments from '../ClinicComponent/TableDocuments';
import Swal from 'sweetalert2';

const DoctorInfo = () => {
    const [doctor, setDoctor] = useState({});
    const [file, setFile] = useState({});

    // Función para obtener la información del doctor
    const fetchDoctor = async () => {
        try {
            const doctorId = localStorage.getItem('doctorId');
            const token = localStorage.getItem('token');

            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/doctor/${doctorId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            setDoctor(response.data);
        } catch (error) {
            console.error('Error fetching doctor:', error);
        }
    };

    useEffect(() => {
        fetchDoctor();
    }, []);

    const documentsExists = [
        { nameToSave: 'foto_perfil', name: 'Foto Profesional', url: doctor?.urlProfessionalPhoto || '', isEdit: true },
        { nameToSave: '', name: 'Documento Identidad', url: doctor?.urlIdDocument || '', isEdit: false },
        { nameToSave: '', name: 'Homologación Estudios', url: doctor?.urlStudyHomologation || '', isEdit: false },
        { nameToSave: '', name: 'Diploma Grado', url: doctor?.urlUndergraduateDiploma || '', isEdit: false },
        { nameToSave: '', name: 'Diploma Especialización', url: doctor?.urlSpecializationDiploma || '', isEdit: false },
        { nameToSave: '', name: 'Tarjeta Profesional', url: doctor?.urlProfessionalCard || '', isEdit: false },
        { nameToSave: '', name: 'Firma Profesional', url: doctor?.urlProfessionalSignature || '', isEdit: false },
        { nameToSave: '', name: 'Certificado Rethus', url: doctor?.urlRethusDocument || '', isEdit: false },
        { nameToSave: '', name: 'Poliza Responsabilidad Civil', url: doctor?.urlCivilLiabilityPolicyDocument || '', isEdit: false },
    ];

    const onHandleSubmit = async () => {
        const token = localStorage.getItem('token');

        const dataDoctorToSend = new FormData();

        dataDoctorToSend.append('foto_perfil', file.foto_perfil);
        dataDoctorToSend.append('clinicInfo', doctor.clinicInfo._id);
        dataDoctorToSend.append('idNumber', doctor.idNumber);

        try {
            const res = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/doctor/updatePictureProfile/${doctor._id}`, dataDoctorToSend, 
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            console.log(res);

            if(res.status === 200){
                Swal.fire({
                    icon: 'success',
                    title: 'Foto actualizada correctamente',
                    text: 'La foto del doctor ha sido actualizada correctamente.',
                }).then(()=> {
                    localStorage.setItem('imageProfile', res.data);
                    window.location.reload();
                })
            }

        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error al actualizar la foto',
                text: 'Error inesperado, por favor intenta de nuevo.',
            })
        }
    }

    return (
        <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg">
            <h1 className="text-3xl font-bold text-gray-800 mb-6 text-center">Información del Doctor</h1>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                    <label className="block text-gray-700 font-semibold mb-2">Nombre Completo</label>
                    <input
                        type="text"
                        value={doctor.fullName || ''}
                        readOnly
                        className="w-full px-4 py-2 border rounded-lg bg-gray-100 text-gray-700"
                    />
                </div>
                <div>
                    <label className="block text-gray-700 font-semibold mb-2">Tipo de ID</label>
                    <input
                        type="text"
                        value={doctor.idType || ''}
                        readOnly
                        className="w-full px-4 py-2 border rounded-lg bg-gray-100 text-gray-700"
                    />
                </div>
                <div>
                    <label className="block text-gray-700 font-semibold mb-2">Número de ID</label>
                    <input
                        type="text"
                        value={doctor.idNumber || ''}
                        readOnly
                        className="w-full px-4 py-2 border rounded-lg bg-gray-100 text-gray-700"
                    />
                </div>
                <div>
                    <label className="block text-gray-700 font-semibold mb-2">Correo</label>
                    <input
                        type="email"
                        value={doctor.email || ''}
                        readOnly
                        className="w-full px-4 py-2 border rounded-lg bg-gray-100 text-gray-700"
                    />
                </div>
                <div>
                    <label className="block text-gray-700 font-semibold mb-2">Número de Celular</label>
                    <input
                        type="text"
                        value={doctor.cellNumber || ''}
                        readOnly
                        className="w-full px-4 py-2 border rounded-lg bg-gray-100 text-gray-700"
                    />
                </div>
                <div>
                    <label className="block text-gray-700 font-semibold mb-2">Número Rethus</label>
                    <input
                        type="text"
                        value={doctor.rethusNumber || ''}
                        readOnly
                        className="w-full px-4 py-2 border rounded-lg bg-gray-100 text-gray-700"
                    />
                </div>
                <div>
                    <label className="block text-gray-700 font-semibold mb-2">Póliza de Responsabilidad Civil</label>
                    <input
                        type="text"
                        value={doctor.civilLiabilityPolicyNumber || ''}
                        readOnly
                        className="w-full px-4 py-2 border rounded-lg bg-gray-100 text-gray-700"
                    />
                </div>
                <div>
                    <label className="block text-gray-700 font-semibold mb-2">Servicio</label>
                    <input
                        type="text"
                        value={doctor.service || ''}
                        readOnly
                        className="w-full px-4 py-2 border rounded-lg bg-gray-100 text-gray-700"
                    />
                </div>
            </div>
            <TableDocuments
                lstDocuments={documentsExists ? documentsExists : []}
                filesToEdit={file}
                setFilesToEdit={setFile}
            />
            {file && file.foto_perfil && (  
                <div className="flex justify-end mt-4">
                    <button
                        type="button"
                        onClick={onHandleSubmit}
                        className="px-4 py-2 bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white font-semibold rounded-md shadow-md hover:bg-blue-700"
                    >
                        Guardar Cambios
                    </button>
                </div>
            )}
        </div>
    );
};

export default DoctorInfo;
