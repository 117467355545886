import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import axios from 'axios';

ChartJS.register(ArcElement, Tooltip, Legend);

// Plugin personalizado para mostrar el total en el centro
const totalCenterTextPlugin = {
    id: 'totalCenterText',
    beforeDraw: (chart) => {
      const { ctx, chartArea: { width, height } } = chart;
      ctx.save();
  
      const total = chart.config.data.datasets[0].data.reduce((a, b) => a + b, 0);
      const fontSize = Math.min(width / 10, height / 10); // Ajustamos el tamaño de fuente con un límite
      ctx.font = `${fontSize}px sans-serif`;
      ctx.textBaseline = 'middle';
      ctx.fillStyle = '#333';
  
      const text = `Total: ${total}`;
      const textX = Math.round((width - ctx.measureText(text).width) / 2);
      const textY = (height + chart.chartArea.top) / 2 + 20;  // Centrar el texto correctamente
  
      ctx.fillText(text, textX, textY);
      ctx.restore();
    },
  };

const GraficoUsuarios = () => {
  const [admins, setAdmins] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [patients, setPatients] = useState([]);

  const fetchAdmins = async () => {
    try {
      const clinicId = localStorage.getItem('clinicId');
      const token = localStorage.getItem('token');

      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/clinic/getAdmins/${clinicId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setAdmins(response.data);
    } catch (error) {
      console.error('Error al obtener administradores:', error);
    }
  };

  const fetchDoctors = async () => {
    try {
      const clinicId = localStorage.getItem('clinicId');
      const token = localStorage.getItem('token');

      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/clinic/getDoctors/${clinicId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setDoctors(response.data);
    } catch (error) {
      console.error('Error al obtener doctores:', error);
    }
  };

  const fetchPatients = async () => {
    try {
      const clinicId = localStorage.getItem('clinicId');
      const token = localStorage.getItem('token');

      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/clinic/getPatients/${clinicId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setPatients(response.data);
    } catch (error) {
      console.error('Error al obtener pacientes:', error);
    }
  };

  useEffect(() => {
    fetchAdmins();
    fetchDoctors();
    fetchPatients();
  }, []);

  const data = {
    labels: ['Administradores', 'Doctores', 'Pacientes'],
    datasets: [
      {
        label: 'Distribución de Usuarios',
        data: [admins.length, doctors.length, patients.length],
        backgroundColor: ['#0011FF', '#4955F7', '#9DA3F5'],
        hoverBackgroundColor: ['#0011FF', '#4955F7', '#9DA3F5'],
        borderColor: '#fff',
        borderWidth: 2,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: true,
        position: 'top',
        labels: {
          color: '#333',
          font: {
            size: 14,
          },
        },
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            return `${tooltipItem.label}: ${tooltipItem.raw} usuarios`;
          },
        },
      },
    },
    maintainAspectRatio: false,
  };

  return (
    <div className="flex flex-col items-center bg-white shadow-lg rounded-lg p-24 max-w-full max-h-full">
      <h2 className="text-2xl font-bold text-gray-800 mb-4">Usuarios del sistema</h2>
      <div className="w-64 h-64 relative">
        <Doughnut data={data} options={options} plugins={[totalCenterTextPlugin]} />
        <div className="mt-4 flex justify-around w-full">
          <div className="text-center -ml-10 p-2">
            <span className="text-blue-800 font-bold">Administradores: </span> {admins.length}
          </div>
          <div className="text-center p-2">
            <span className="text-blue-600 font-bold">Doctores: </span> {doctors.length}
          </div>
          <div className="text-center p-2">
            <span className="text-blue-400 font-bold">Pacientes: </span> {patients.length}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GraficoUsuarios;
