import React, { useEffect, useState } from 'react';
import axios from 'axios';
import TableDocuments from '../ClinicComponent/TableDocuments';
import Swal from 'sweetalert2';

const PatientProfile = ({ patientId }) => {
    const [patient, setPatient] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [file, setFile] = useState([]);

    useEffect(() => {
        const fetchPatientProfile = async () => {
            try {
                const patientId = localStorage.getItem('patientId'); // Obtiene el patientId del localStorage
                const token = localStorage.getItem('token');
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/patient/${patientId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setPatient(response.data);
                setLoading(false);
            } catch (err) {
                setError('Error al obtener los datos del paciente');
                setLoading(false);
            }
        };

        fetchPatientProfile();
    }, [patientId]);

    const documentsExists = [
        { nameToSave: 'foto_perfil', name: 'Foto Perfil', url: patient?.urlPictureProfile || '', isEdit: true },
        { nameToSave: 'cedulaPDF', name: 'Documento Identidad', url: patient?.urlFileDocument || '', isEdit: false },
    ];

    const onHandleSubmit = async () => {
        const token = localStorage.getItem('token');
        const dataPatientToSend = new FormData();
        dataPatientToSend.append('idNumber', patient.idNumber);
        dataPatientToSend.append('clinicInfo', patient.clinicInfo._id);
        dataPatientToSend.append('foto_perfil', file.foto_perfil);

        console.log(file);
        try {
            const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/patient/updatePictureProfile/${patient?._id}`, dataPatientToSend,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                }
            );

            if (response.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: 'Foto actualizada correctamente',
                }).then(() => {
                    localStorage.setItem('imageProfile', response.data);
                    window.location.reload();
                });
            }
        } catch (err) {
            Swal.fire({
                icon: 'error',
                title: 'Error al actualizar la foto',
                text: 'Error inesperado, por favor intenta de nuevo.',
            });
            console.log(err);
        }
    }

    if (loading) return <p className="text-center text-gray-600">Cargando...</p>;
    if (error) return <p className="text-center text-red-600">{error}</p>;

    return (
        <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg">
            <h2 className="text-3xl font-bold text-gray-800 mb-6 text-center">Perfil del Paciente</h2>
            <form className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="form-group">
                    <label className="block text-gray-700 font-semibold mb-2">Nombre de la Clínica:</label>
                    <input
                        type="text"
                        value={patient.clinicInfo.providerName}
                        readOnly
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                    />
                </div>
                <div className="form-group">
                    <label className="block text-gray-700 font-semibold mb-2">Nombre:</label>
                    <input
                        type="text"
                        value={`${patient.firstName} ${patient.secondName || ''} ${patient.firstLastName} ${patient.secondLastName}`}
                        readOnly
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                    />
                </div>
                <div className="form-group">
                    <label className="block text-gray-700 font-semibold mb-2">Fecha de Nacimiento:</label>
                    <input
                        type="text"
                        value={new Date(patient.dob).toLocaleDateString()}
                        readOnly
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                    />
                </div>
                <div className="form-group">
                    <label className="block text-gray-700 font-semibold mb-2">País de Nacimiento:</label>
                    <input
                        type="text"
                        value={patient.birthCountry}
                        readOnly
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                    />
                </div>
                <div className="form-group">
                    <label className="block text-gray-700 font-semibold mb-2">País de Residencia:</label>
                    <input
                        type="text"
                        value={patient.residenceCountry}
                        readOnly
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                    />
                </div>
                <div className="form-group">
                    <label className="block text-gray-700 font-semibold mb-2">Departamento:</label>
                    <input
                        type="text"
                        value={patient.department.departamento}
                        readOnly
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                    />
                </div>
                <div className="form-group">
                    <label className="block text-gray-700 font-semibold mb-2">Ciudad:</label>
                    <input
                        type="text"
                        value={patient.city}
                        readOnly
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                    />
                </div>
                <div className="form-group">
                    <label className="block text-gray-700 font-semibold mb-2">Calle:</label>
                    <input
                        type="text"
                        value={patient.street}
                        readOnly
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                    />
                </div>
                <div className="form-group">
                    <label className="block text-gray-700 font-semibold mb-2">Número Exterior:</label>
                    <input
                        type="text"
                        value={patient.exteriorNumber}
                        readOnly
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                    />
                </div>
                <div className="form-group">
                    <label className="block text-gray-700 font-semibold mb-2">Número Interior:</label>
                    <input
                        type="text"
                        value={patient.interiorNumber}
                        readOnly
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                    />
                </div>
                <div className="form-group">
                    <label className="block text-gray-700 font-semibold mb-2">Colonia:</label>
                    <input
                        type="text"
                        value={patient.neighborhood}
                        readOnly
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                    />
                </div>
                <div className="form-group">
                    <label className="block text-gray-700 font-semibold mb-2">Género:</label>
                    <input
                        type="text"
                        value={patient.gender}
                        readOnly
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                    />
                </div>
                <div className="form-group">
                    <label className="block text-gray-700 font-semibold mb-2">Número de Celular:</label>
                    <input
                        type="text"
                        value={patient.cellNumber}
                        readOnly
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                    />
                </div>
                <div className="form-group">
                    <label className="block text-gray-700 font-semibold mb-2">Email:</label>
                    <input
                        type="text"
                        value={patient.email}
                        readOnly
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                    />
                </div>
                <div className="form-group">
                    <label className="block text-gray-700 font-semibold mb-2">Tipo de Identificación:</label>
                    <input
                        type="text"
                        value={patient.idType}
                        readOnly
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                    />
                </div>
                <div className="form-group">
                    <label className="block text-gray-700 font-semibold mb-2">Número de Identificación:</label>
                    <input
                        type="text"
                        value={patient.idNumber}
                        readOnly
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                    />
                </div>
                {/* Añade más campos según los necesites */}
                <span />
            </form>
            <TableDocuments
                lstDocuments={documentsExists ? documentsExists : []}
                filesToEdit={file}
                setFilesToEdit={setFile}
            />
            {file && file.foto_perfil && (
                <div className="flex justify-end mt-4">
                    <button
                        type="button"
                        onClick={onHandleSubmit}
                        className="px-4 py-2 bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white font-semibold rounded-md shadow-md hover:bg-blue-700"
                    >
                        Guardar Cambios
                    </button>
                </div>
            )}
        </div>
    );
};

export default PatientProfile;
