import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import esLocale from '@fullcalendar/core/locales/es';
import moment from 'moment-timezone';

const CalendarView = () => {
    const navigate = useNavigate();
    const [appointments, setAppointments] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);

    const fetchAppointments = async () => {
        try {
            const doctorId = localStorage.getItem('doctorId'); // Obtiene el patientId del localStorage

            const token = localStorage.getItem('token');
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/clinic/getAppointmentsDoctor/${doctorId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const appointmentsData = response.data.map(appointment => {

                const utcDate = moment.utc(appointment.date);
                return {
                    title: 'Cita de control',
                    start: utcDate.toISOString(),
                    allDay: false,
                    backgroundColor: '#000aff',
                    borderColor: '#000aff',
                    extendedProps: {
                        notes: appointment.notes,
                        startTime: utcDate.format('HH:mm'),
                        patientName: `${appointment.patient.firstName} ${appointment.patient.firstLastName}`
                    }
                };
            });

            setAppointments(appointmentsData);
        } catch (error) {
            console.error('Error fetching appointments:', error);
            Swal.fire('Error', 'No se pudieron cargar las citas del doctor.', 'error');
        }
    };

    useEffect(() => {
        fetchAppointments();
    }, []);

    const handleGoBack = () => {
        navigate(-0); // Vuelve a la página anterior
    };

    const handleEventClick = (info) => {
        setSelectedEvent(info.event);
    };

    const handleCloseModal = () => {
        setSelectedEvent(null);
    };

    return (
        <div className="flex flex-col min-h-screen">
            <div className="flex p-2 items-center justify-between">
                <h1 className="text-lg font-bold text-black">Calendario</h1>
                <button
                    onClick={handleGoBack}
                    className="px-4 py-2 bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white font-semibold rounded-md shadow-md hover:bg-blue-600"
                >
                    Volver
                </button>
            </div>
            <main className="flex-grow flex items-center justify-center p-2 -mt-2">
                <div className="w-full max-w-3xl bg-white shadow-md rounded-lg p-3">
                    <FullCalendar
                        plugins={[dayGridPlugin, interactionPlugin]}
                        initialView="dayGridMonth"
                        events={appointments}
                        locales={[esLocale]}
                        locale="es"
                        timeZone="local"
                        dayCellClassNames={(date) =>
                            date.date.getDate() === new Date().getDate() &&
                            date.date.getMonth() === new Date().getMonth()
                                ? 'current-day'
                                : ''
                        }
                        eventContent={(eventInfo) => {
                            const startTime = moment.utc(eventInfo.event.start).format('HH:mm');
                            return (
                                <div style={{ backgroundColor: eventInfo.event.backgroundColor }}>
                                    <div className="font-semibold text-white text-xs">{eventInfo.event.title}</div>
                                    <div className="text-white text-xs">{startTime}</div>
                                </div>
                            );
                        }}
                        eventClick={handleEventClick}
                    />
                </div>
            </main>
            {selectedEvent && (
                    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50">
                        <div className="bg-white rounded-lg text-center shadow-md p-4 max-w-md w-full">
                            <h2 className="text-lg font-bold">{selectedEvent.title}</h2>
                            <p className="text-sm text-gray-800 font-semibold">Fecha: {moment(selectedEvent.start).format('YYYY-MM-DD')}</p>
                            <p className="text-sm text-gray-800 font-semibold">Hora: {moment.utc(selectedEvent.start).format('HH:mm')}</p>
                            <p className="text-sm text-gray-800 font-semibold">
                                Paciente: {selectedEvent.extendedProps.patientName}
                            </p>
                            <p className="text-sm mt-2">{selectedEvent.extendedProps.notes}</p>
                            <button
                                onClick={handleCloseModal}
                                className="mt-4 px-4 py-2 bg-blue-500 text-white font-semibold rounded-md shadow-md hover:bg-blue-600"
                            >
                                Cerrar
                            </button>
                        </div>
                    </div>
                )}
            <style>
                {`
                .fc-event {
                    background-color: #000aff !important; /* Asegúrate de que el color de fondo se aplique */
                    border: 1px solid #000aff;
                }
                .fc-daygrid-day {
                    visibility: visible;
                }
                .current-day {
                    background-color: #e7e6e6 !important;
                    border: 2px solid #fbc02d;
                }
                `}
            </style>
        </div>
    );
};

export default CalendarView;
