import Swal from "sweetalert2";

export function alertaGeneral({titulo, messageHtml ,isError = false}){
    Swal.fire({
        title: titulo,
        html: messageHtml,
        icon: isError ? 'error' : 'success',
        confirmButtonText: 'Aceptar'
    });
}

/* Esta alerta es para que no deje hacer "nada" y de un mensaje de que se está realizando el proceso */
export function alertaCargandoProceso({ titulo, messageHtml, funcionAsync }) {
    Swal.fire({
        title: titulo,
        html: messageHtml,
        allowOutsideClick: false,
        allowEscapeKey: false,
        // allowEnterKey: false,
        timerProgressBar: true,
        didOpen: () => {
            Swal.showLoading();
            // Inicia la operación del servidor
            funcionAsync(); //La funcion debe manejar el cierre de la alerta con Swal.close()
        }
    });
}