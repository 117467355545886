import React, { useState } from 'react';
import { FaSearch } from 'react-icons/fa';

const AgregarInventario = ({ handleClose, handleAgregarInventario }) => {
    const [fechaCreacion, setFechaCreacion] = useState('');
    const [producto, setProducto] = useState('');
    const [opcionesProducto, setOpcionesProducto] = useState('');
    const [titular, setTitular] = useState('');
    const [registroSanitario, setRegistroSanitario] = useState('');
    const [fechaExpedicion, setFechaExpedicion] = useState('');
    const [fechaVencimiento, setFechaVencimiento] = useState('');
    const [estado, setEstado] = useState('');
    const [expedienteCum, setExpedienteCum] = useState('');
    const [consecutivo, setConsecutivo] = useState('');
    const [cantidadCum, setCantidadCum] = useState('');
    const [descripcionComercial, setDescripcionComercial] = useState('');
    const [estadoCum, setEstadoCum] = useState('');
    const [fechaActivo, setFechaActivo] = useState('');
    const [fechaInactivo, setFechaInactivo] = useState('');
    const [muestraMedica, setMuestraMedica] = useState('');
    const [unidadAtc, setUnidadAtc] = useState('');
    const [atc, setAtc] = useState('');
    const [descripcionAtc, setDescripcionAtc] = useState('');
    const [viaAdministracion, setViaAdministracion] = useState('');
    const [concentracion, setConcentracion] = useState('');
    const [principioActivo, setPrincipioActivo] = useState('');
    const [unidadMedida, setUnidadMedida] = useState('');
    const [cantidad, setCantidad] = useState('');
    const [unidadReferencia, setUnidadReferencia] = useState('');
    const [formaFarmaceutica, setFormaFarmaceutica] = useState('');
    const [nombreRol, setNombreRol] = useState('');
    const [tipoRol, setTipoRol] = useState('');
    const [modalidad, setModalidad] = useState('');
    const [ium, setIum] = useState('');

    const handleAddClick = () => {
        const nuevoInsumo = {
            fechaCreacion, producto, opcionesProducto, titular, registroSanitario, fechaExpedicion,
            fechaVencimiento, estado, expedienteCum, consecutivo, cantidadCum, descripcionComercial,
            estadoCum, fechaActivo, fechaInactivo, muestraMedica, unidadAtc, atc, descripcionAtc,
            viaAdministracion, concentracion, principioActivo, unidadMedida, cantidad, unidadReferencia,
            formaFarmaceutica, nombreRol, tipoRol, modalidad, ium
        };
        handleAgregarInventario(nuevoInsumo);
    };

    return (
        <div className="modal-overlay fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="modal-content bg-white p-6 rounded-lg shadow-lg w-full max-w-5xl">
                <h2 className="text-xl font-bold mb-4">Agregar Inventario</h2>
                <div className="mb-2">
                    <div className="grid grid-cols-2 gap-4 mb-1">
                        <div>
                            <label className="block text-gray-700">Fecha de creación</label>
                            <input
                                type="date"
                                value={fechaCreacion}
                                onChange={(e) => setFechaCreacion(e.target.value)}
                                className="w-full px-3 py-2 border rounded"
                                required
                            />
                        </div>
                        <div className="relative">
                            <label className="block text-gray-700">Opciones</label>
                            <input
                                type="text"
                                value={opcionesProducto}
                                onChange={(e) => setOpcionesProducto(e.target.value)}
                                className="w-full px-3 py-2 border rounded pl-10"
                                required
                            />
                            <FaSearch className="absolute top-1/2 left-3 transform -translate-y-1/2 text-gray-500" />
                        </div>
                    </div>
                    <div className="grid grid-cols-2 md:grid-cols-5 gap-1">
                        <div>
                            <label className="block text-gray-700">Producto</label>
                            <input
                                type="text"
                                value={producto}
                                onChange={(e) => setProducto(e.target.value)}
                                className="w-full px-3 py-2 border rounded"
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700">Titular</label>
                            <input
                                type="text"
                                value={titular}
                                onChange={(e) => setTitular(e.target.value)}
                                className="w-full px-3 py-2 border rounded"
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700">Registro Sanitario</label>
                            <input
                                type="text"
                                value={registroSanitario}
                                onChange={(e) => setRegistroSanitario(e.target.value)}
                                className="w-full px-3 py-2 border rounded"
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700">Fecha de Expedición</label>
                            <input
                                type="date"
                                value={fechaExpedicion}
                                onChange={(e) => setFechaExpedicion(e.target.value)}
                                className="w-full px-3 py-2 border rounded"
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700">Fecha de Vencimiento</label>
                            <input
                                type="date"
                                value={fechaVencimiento}
                                onChange={(e) => setFechaVencimiento(e.target.value)}
                                className="w-full px-3 py-2 border rounded"
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700">Estado Registrado</label>
                            <input
                                type="text"
                                value={estado}
                                onChange={(e) => setEstado(e.target.value)}
                                className="w-full px-3 py-2 border rounded"
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700">Expediente CUM</label>
                            <input
                                type="text"
                                value={expedienteCum}
                                onChange={(e) => setExpedienteCum(e.target.value)}
                                className="w-full px-3 py-2 border rounded"
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700">Consecutivo</label>
                            <input
                                type="text"
                                value={consecutivo}
                                onChange={(e) => setConsecutivo(e.target.value)}
                                className="w-full px-3 py-2 border rounded"
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700">Cantidad CUM</label>
                            <input
                                type="number"
                                value={cantidadCum}
                                onChange={(e) => setCantidadCum(e.target.value)}
                                className="w-full px-3 py-2 border rounded"
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700">Descripción Comercial</label>
                            <input
                                type="text"
                                value={descripcionComercial}
                                onChange={(e) => setDescripcionComercial(e.target.value)}
                                className="w-full px-3 py-2 border rounded"
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700">Estado CUM</label>
                            <select
                                value={estadoCum}
                                onChange={(e) => setEstadoCum(e.target.value)}
                                className="w-full px-3 py-2 border rounded"
                                required
                            >
                                <option value="">Seleccionar</option>
                                <option value="activo">Activo</option>
                                <option value="inactivo">Inactivo</option>
                            </select>
                        </div>
                        <div>
                            <label className="block text-gray-700">Fecha Activo</label>
                            <input
                                type="date"
                                value={fechaActivo}
                                onChange={(e) => setFechaActivo(e.target.value)}
                                className="w-full px-3 py-2 border rounded"
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700">Fecha Inactivo</label>
                            <input
                                type="date"
                                value={fechaInactivo}
                                onChange={(e) => setFechaInactivo(e.target.value)}
                                className="w-full px-3 py-2 border rounded"
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700">Muestra Médica</label>
                            <select
                                value={muestraMedica}
                                onChange={(e) => setMuestraMedica(e.target.value)}
                                className="w-full px-3 py-2 border rounded"
                                required
                            >
                                <option value="">Seleccionar</option>
                                <option value="si">Sí</option>
                                <option value="no">No</option>
                            </select>
                        </div>
                        <div>
                            <label className="block text-gray-700">Unidad ATC</label>
                            <input
                                type="text"
                                value={unidadAtc}
                                onChange={(e) => setUnidadAtc(e.target.value)}
                                className="w-full px-3 py-2 border rounded"
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700">ATC</label>
                            <input
                                type="text"
                                value={atc}
                                onChange={(e) => setAtc(e.target.value)}
                                className="w-full px-3 py-2 border rounded"
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700">Descripción ATC</label>
                            <input
                                type="text"
                                value={descripcionAtc}
                                onChange={(e) => setDescripcionAtc(e.target.value)}
                                className="w-full px-3 py-2 border rounded"
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700">Vía de Administración</label>
                            <select
                                value={viaAdministracion}
                                onChange={(e) => setViaAdministracion(e.target.value)}
                                className="w-full px-3 py-2 border rounded"
                                required
                            >
                                <option value="">Seleccionar</option>
                                <option value="oral">Oral</option>
                                <option value="intravenosa">Intravenosa</option>
                                {/* Agrega más opciones según sea necesario */}
                            </select>
                        </div>
                        <div>
                            <label className="block text-gray-700">Concentración</label>
                            <input
                                type="text"
                                value={concentracion}
                                onChange={(e) => setConcentracion(e.target.value)}
                                className="w-full px-3 py-2 border rounded"
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700">Principio Activo</label>
                            <input
                                type="text"
                                value={principioActivo}
                                onChange={(e) => setPrincipioActivo(e.target.value)}
                                className="w-full px-3 py-2 border rounded"
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700">Unidad de Medida</label>
                            <input
                                type="text"
                                value={unidadMedida}
                                onChange={(e) => setUnidadMedida(e.target.value)}
                                className="w-full px-3 py-2 border rounded"
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700">Cantidad</label>
                            <input
                                type="number"
                                value={cantidad}
                                onChange={(e) => setCantidad(e.target.value)}
                                className="w-full px-3 py-2 border rounded"
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700">Unidad de Referencia</label>
                            <input
                                type="text"
                                value={unidadReferencia}
                                onChange={(e) => setUnidadReferencia(e.target.value)}
                                className="w-full px-3 py-2 border rounded"
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700">Forma Farmacéutica</label>
                            <input
                                type="text"
                                value={formaFarmaceutica}
                                onChange={(e) => setFormaFarmaceutica(e.target.value)}
                                className="w-full px-3 py-2 border rounded"
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700">Nombre del Rol</label>
                            <input
                                type="text"
                                value={nombreRol}
                                onChange={(e) => setNombreRol(e.target.value)}
                                className="w-full px-3 py-2 border rounded"
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700">Tipo de Rol</label>
                            <input
                                type="text"
                                value={tipoRol}
                                onChange={(e) => setTipoRol(e.target.value)}
                                className="w-full px-3 py-2 border rounded"
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700">Modalidad</label>
                            <input
                                type="text"
                                value={modalidad}
                                onChange={(e) => setModalidad(e.target.value)}
                                className="w-full px-3 py-2 border rounded"
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700">IUM</label>
                            <input
                                type="text"
                                value={ium}
                                onChange={(e) => setIum(e.target.value)}
                                className="w-full px-3 py-2 border rounded"
                                required
                            />
                        </div>
                    </div>
                </div>
                <div className="flex justify-end gap-4 mt-4">
                    <button
                        onClick={handleClose}
                        className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
                    >
                        Cancelar
                    </button>
                    <button
                        onClick={handleAddClick}
                        className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white px-4 py-2 rounded hover:bg-blue-600"
                    >
                        Agregar
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AgregarInventario;
