import React, { useState } from 'react';

const CrearHistoriaModal = ({ isOpen, onClose, servicios, onSave }) => {
    const [nombreHC, setNombreHC] = useState('');
    const [servicioLigado, setServicioLigado] = useState('');
    const [historiaClinica, setHistoriaClinica] = useState('');

    const handleSave = () => {
        const newHistoria = {
            nombreHC,
            servicioLigado,
            historiaClinica,
        };
        onSave(newHistoria);
        // Resetear los campos después de guardar
        setNombreHC('');
        setServicioLigado('');
        setHistoriaClinica('');
        onClose();
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded shadow-lg">
                <h2 className="text-xl font-semibold mb-4">Crear Historia Clínica</h2>
                <div className="mb-4">
                    <label className="block text-gray-700">Nombre de la Historia Clínica:</label>
                    <input
                        type="text"
                        required
                        value={nombreHC}
                        onChange={(e) => setNombreHC(e.target.value)}
                        className="w-full px-3 py-2 border rounded"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700">Servicio Ligado:</label>
                    <select
                        value={servicioLigado}
                        required
                        onChange={(e) => setServicioLigado(e.target.value)}
                        className="w-full px-3 py-2 border rounded"
                    >
                        <option value="" required>Seleccione una opcion</option>
                        {servicios.map((servicio) => (
                            
                            <option key={servicio._id} value={servicio.service}>
                                {servicio.service}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700">Historia Clínica:</label>
                    <select
                        value={historiaClinica}
                        required
                        onChange={(e) => setHistoriaClinica(e.target.value)}
                        className="w-full px-3 py-2 border rounded"
                    >
                        <option value="" required>Seleccione una opcion</option>
                        <option value="Opción 1">Opción 1</option>
                        <option value="Opción 2">Opción 2</option>
                        <option value="Opción 3">Opción 3</option>
                    </select>
                </div>
                <div className="flex justify-end">
                    <button
                        onClick={handleSave}
                        className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white px-4 py-2 rounded mr-2"
                    >
                        Guardar
                    </button>
                    <button
                        onClick={() => {
                            // Resetear los campos cuando se cancela
                            setNombreHC('');
                            setServicioLigado('');
                            setHistoriaClinica('');
                            onClose();
                        }}
                        className="bg-gray-500 text-white px-4 py-2 rounded"
                    >
                        Cancelar
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CrearHistoriaModal;
