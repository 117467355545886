import React, { useState, useContext } from 'react';
import { FormContext } from './FormContext';
import ConsentimientoModal from './ConsentimientoModal';
import HistoriaModal from './HistoriaModal';

const Formatos = ({ handleNext, handlePrevious }) => {
    const { formState, updateFormState } = useContext(FormContext);
    const [consentimientos, setConsentimientos] = useState(formState.formatos.consentimientos || []);
    const [historias, setHistorias] = useState(formState.formatos.historias || []);
    const [isConsentimientoModalOpen, setIsConsentimientoModalOpen] = useState(false);
    const [isHistoriaModalOpen, setIsHistoriaModalOpen] = useState(false);

    const handleAddConsentimiento = (consentimiento) => {
        const updatedConsentimientos = [...consentimientos, consentimiento];
        setConsentimientos(updatedConsentimientos);
        setIsConsentimientoModalOpen(false);
    };

    const handleAddHistoria = (historia) => {
        const updatedHistorias = [...historias, historia];
        setHistorias(updatedHistorias);
        setIsHistoriaModalOpen(false);
    };

    const handleRemoveConsentimiento = (index) => {
        const newConsentimientos = [...consentimientos];
        newConsentimientos.splice(index, 1);
        setConsentimientos(newConsentimientos);
    };

    const handleRemoveHistoria = (index) => {
        const newHistorias = [...historias];
        newHistorias.splice(index, 1);
        setHistorias(newHistorias);
    };

    const handleSubmit = (e) => {
    e.preventDefault();

    // Obtén los datos actuales de formatos
    const currentFormatos = formState.formatos || {};

    // Actualiza los formatos con consentimientos e historias
    const updatedFormatos = {
        ...currentFormatos,
        consentimientos: consentimientos,
        historias: historias
    };

    // Actualiza el estado global con el nuevo objeto de formatos
    updateFormState('formatos', updatedFormatos);

    handleNext();
};


    return (
        <div className="max-w-4xl mx-auto p-4 bg-white shadow-md rounded-lg">
            <div className="mb-4">
                <div className="flex justify-between items-center">
                    <h3 className="text-lg font-bold mb-2">Agregar Consentimiento</h3>
                    <button
                        className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white font-bold py-2 px-4 rounded hover:bg-blue-700"
                        onClick={() => setIsConsentimientoModalOpen(true)}
                    >
                        Agregar
                    </button>
                </div>
                {consentimientos.length > 0 && (
                    <div className="mt-4">
                        {consentimientos.map((consentimiento, index) => (
                            <div key={index} className="border p-2 rounded mb-2 flex justify-between items-center">
                                <div>
                                    <div><strong>Nombre:</strong> {consentimiento.nombreConsentimiento}</div>
                                    <div><strong>Servicio ligado:</strong> {consentimiento.servicioLigado}</div>
                                    <div><strong>Consentimiento:</strong> {consentimiento.consentimiento}</div>
                                </div>
                                <button
                                    className="ml-4 bg-red-500 text-white font-bold py-2 px-4 rounded hover:bg-red-700"
                                    onClick={() => handleRemoveConsentimiento(index)}
                                >
                                    X
                                </button>
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <hr className="my-4" />
            <div className="mb-4">
                <div className="flex justify-between items-center">
                    <h3 className="text-lg font-bold mb-2">Agregar Historia Clínica</h3>
                    <button
                        className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white font-bold py-2 px-4 rounded hover:bg-blue-700"
                        onClick={() => setIsHistoriaModalOpen(true)}
                    >
                        Agregar
                    </button>
                </div>
                {historias.length > 0 && (
                    <div className="mt-4">
                        {historias.map((historia, index) => (
                            <div key={index} className="border p-2 rounded mb-2 flex justify-between items-center">
                                <div>
                                    <div><strong>Nombre Historia Clinica:</strong> {historia.nombreHC}</div>
                                    <div><strong>Servicio ligado:</strong> {historia.servicioLigado}</div>
                                    <div><strong>Historia clínica:</strong> {historia.historiaClinica}</div>
                                </div>
                                <button
                                    className="ml-4 bg-red-500 text-white font-bold py-2 px-4 rounded hover:bg-red-700"
                                    onClick={() => handleRemoveHistoria(index)}
                                >
                                    X
                                </button>
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <div className="flex justify-between mt-4">
                <button
                    className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-700"
                    onClick={handlePrevious}
                >
                    Anterior
                </button>
                <button
                    className="px-4 py-2 bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white rounded hover:bg-blue-700"
                    onClick={handleSubmit}
                >
                    Siguiente
                </button>
            </div>
            <ConsentimientoModal
                isOpen={isConsentimientoModalOpen}
                onClose={() => setIsConsentimientoModalOpen(false)}
                onSave={handleAddConsentimiento}
            />
            <HistoriaModal
                isOpen={isHistoriaModalOpen}
                onClose={() => setIsHistoriaModalOpen(false)}
                onSave={handleAddHistoria}
            />
        </div>
    );
};

export default Formatos;
