import React, { createContext, useState, useEffect } from 'react';

const FormContext = createContext();

const FormProvider = ({ children }) => {
    const [formState, setFormState] = useState({
        infoGeneral: {},
        logoClinica: null,
        documentosHabilitacion: [],
        sedes: [],
        capacidad: [],
        servicios: [],
        formatos: {
            consentimientos: [], 
            historias: []
        },
        insumos: { 
            inventario: [], 
            consumos: [] 
        } 
    });

    const updateFormState = (key, value) => {
        setFormState((prevState) => ({
            ...prevState,
            [key]: value
        }));
    };

    // useEffect para mostrar en consola todos los arrays del estado
    useEffect(() => {
        console.log('Info General:', formState.infoGeneral);
        //El signo de pregunta es para evitar errores si formState.image es null
        console.log('Logo Clinica:', formState.logoClinica?.get('logo_clinica')); // Solo para verificar que se haya subido la imagen
        console.log('Documentos de Habilitación:', formState.documentosHabilitacion);
        console.log('Sedes:', formState.sedes);
        console.log('Capacidad:', formState.capacidad);
        console.log('Servicios:', formState.servicios);
        console.log('Formatos:', formState.formatos);
        console.log('Insumos:', formState.insumos);
        
    }, [formState]);

    return (
        <FormContext.Provider value={{ formState, updateFormState }}>
            {children}
        </FormContext.Provider>
    );
};

export { FormContext, FormProvider };
