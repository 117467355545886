import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import { getDataUserById, getRecursosClinic } from './LogsPetition';
import { alertaGeneral } from '../../utils/alertas';

const CLinicLogs = () => {
    //Variable para recargar los logs
    const [reload, setReload] = useState(false);
    //Variables para el manejo de los datos
    const [clinic, setClinic] = useState([]);
    const [patients, setPatients] = useState([]);
    const [doctors, setDoctors] = useState([]);
    const [admins, setAdmins] = useState([]);
    const [appointments, setAppointments] = useState([]);

    //Lista de objetos para las pestañas y encabezados
    const [tabs, setTabs] = useState([
        { id: 'clinic', title: 'Clínica' },
        { id: 'patients', title: 'Pacientes' },
        { id: 'doctors', title: 'Doctores' },
        { id: 'admins', title: 'Administradores' },
        { id: 'appointments', title: 'Citas' },
    ]);
    //Variable para saber cual tab/pestaña está activa
    const [activeTab, setActiveTab] = useState(tabs[0].id);

    //Lista de objetos para los encabezados de las tablas
    const [header, setHeader] = useState([
        { clinic: ['Fecha', 'Acción', 'Detalles', 'Responsable', 'Ver'] },
        { patients: ['Fecha', 'Acción', 'Paciente', 'Responsable', 'Detalles', 'Ver'] },
        { doctors: ['Fecha', 'Acción', 'Doctor', 'Responsable', 'Detalles', 'Ver'] },
        { admins: ['Fecha', 'Acción', 'Administrador', 'Responsable', 'Detalles', 'Ver'] },
        { appointments: ['Fecha', 'Acción', 'Responsable', 'Descripción'] },
    ]);


    //Petición a la base de datos para obtener los logs de la clínica
    useEffect(() => {
        const token = localStorage.getItem('token');
        const clinicId = localStorage.getItem('clinicId');
        try {
            axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/clinic/logs/${clinicId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then((response) => {
                    console.log(response.data);
                    if (response.data) {
                        setClinic(response.data.clinics);
                        setPatients(response.data.patients);
                        setDoctors(response.data.doctors);
                        setAdmins(response.data.clinicAdmins);
                        setAppointments(response.data.appointments);
                    }
                })
        } catch (error) {
            console.error(error);
            alertaGeneral({ titulo: 'Error', messageHtml: 'No se pudo obtener los logs de la clínica', isError: true });
        }
    }, [reload]);

    //Para cuando se haga click en ver, se muestre un sweetalert con la información dependiendo de la acción
    //Si es modificar deberá traer solo los campos modificados y ya
    //Si es crear deberá traer todos los campos
    //Si es eliminar deberá traer todos los campos igualmente
    const onHandleView = async ({ id = null, collection = '', accion = '', detalles = null }) => {
        const title = changeTitle(collection, accion);

        let props = null;
        if (accion.toLowerCase() != 'modificar') {
            const data = await getDataUserById(id);
            if (!data) {
                return Swal.fire({
                    title: 'Error',
                    text: `No se pudo obtener la información del ${collection.toLowerCase()}`,
                    icon: 'error',
                    confirmButtonColor: '#007BFF',
                });
            }
            props = getAllPropsAndOrginazeInformation(data);
        } else {
            detalles = getAllPropsAndOrginazeInformation(detalles);
        }

        Swal.fire({
            title: title,
            html: `<div style="text-align: left;">${props ? props.all : detalles.all}</div>`,
            confirmButtonColor: '#007BFF',
        })
    }

    const onHandleViewClinic = async ({ accion = '', recurso = [], detalles = null }) => {
        let props = null;
        recurso = getNombreRecursosClinica(recurso);
        const title = changeTitle(recurso[0], accion);
        //recurso[0] es el nombre del recurso y recurso[1] es el id del recurso
        //Preguntamos si la accion no es modificar para mostrar entonces toda la info
        if (accion.toLowerCase() != 'modificar') {
            const data = await getRecursosClinic(recurso[0], recurso[1]);
            if (!data) {
                return Swal.fire({
                    title: 'Error',
                    text: `No se pudo obtener la información de ${recurso[0].toLowerCase()}`,
                    icon: 'error',
                    confirmButtonColor: '#007BFF',
                });
            }
            props = getAllPropsAndOrginazeInformation(data);
        } else {
            detalles = getAllPropsAndOrginazeInformation(detalles);
        }

        Swal.fire({
            title: title,
            html: `<div style="text-align: left;">${props ? props.all : detalles.all}</div>`,
            confirmButtonColor: '#007BFF',
        })
    }

    const getLevelColor = (accion) => {
        accion = accion.toUpperCase();
        switch (accion) {
            case 'CREAR':
                return 'text-blue-600 bg-blue-100';
            case 'AGENDAR':
                return 'text-blue-600 bg-blue-100';
            case 'MODIFICAR':
                return 'text-yellow-600 bg-yellow-100';
            case 'ELIMINAR':
                return 'text-red-600 bg-red-100';
            case 'CANCELAR':
                return 'text-red-600 bg-red-100';
        }
    }

    return (
        <div className="p-3">
            <h1 className="text-3xl font-bold mb-6 text-center text-gray-800">Logs Registrados</h1>
            <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded-lg mb-4"
                onClick={() => setReload(!reload)}
            >
                <i className="fa-solid fa-arrows-rotate text-lg"/>
                <span className="text-lg"> Refrescar </span>
            </button>
            <div className="flex flex-col ">
                <div className="flex">
                    <div className="border-b border-gray-200 mb-4">
                        {tabs.map((tab) => (
                            <button
                                key={tab.id}
                                className={`py-2 px-4 text-sm font-medium rounded-t-lg focus:outline-none ${activeTab === tab.id
                                    ? 'bg-white text-blue-500 border-l border-t border-r border-gray-300'
                                    : 'text-gray-500 hover:text-gray-700 bg-gray-100'
                                    }`}
                                onClick={() => setActiveTab(tab.id)}
                                aria-selected={activeTab === tab.id}
                                role="tab"
                            >
                                {tab.title}
                            </button>
                        ))}
                    </div>
                </div>
                <div className="min-w-full bg-white shadow-md rounded-lg overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                {header.map(title => (
                                    title[activeTab]?.map((th, index) => (
                                        <th key={index} className="px-6 py-3 text-center text-sm font-medium text-gray-500 uppercase tracking-wider">{th}</th>
                                    ))
                                ))}
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200 text-wrap">
                            {activeTab === 'clinic' && clinic && clinic.map((info, index) => (
                                <tr key={index}>
                                    <td className="px-4 py-3 text-center whitespace-wrap text-sm text-gray-500">{info.createdAt}</td>
                                    <td className="px-4 py-3 whitespace-wrap">
                                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getLevelColor(info.accion)}`}>{info.accion?.toUpperCase()}</span>
                                    </td>
                                    <td className="px-4 py-3 whitespace-wrap text-sm text-gray-700">{info.descripcion}</td>
                                    <td className="px-4 py-3 whitespace-wrap text-sm text-gray-700">{info.userResponsable?.username}</td>
                                    <td className="px-4 py-2">
                                        <div className="flex flex-col md:flex-row items-center justify-center space-x-0 md:space-x-2 space-y-2 md:space-y-0">
                                            <button className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 hover:bg-blue-700 text-white font-bold py-1 px-2 w-full md:w-auto rounded"
                                                onClick={() => onHandleViewClinic({ accion: info.accion, recurso: info, detalles: info.detalles })}
                                            >
                                                Ver
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            {activeTab === 'patients' && patients && patients.map((patient, index) => (
                                <tr key={index}>
                                    <td className="px-4 py-3 text-center whitespace-wrap text-sm text-gray-500">{patient.createdAt}</td>
                                    <td className="px-4 py-3 whitespace-wrap">
                                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getLevelColor(patient.accion)}`}>{patient.accion?.toUpperCase()}</span>
                                    </td>
                                    <td className="px-4 py-3 whitespace-wrap text-sm text-gray-700">{patient.dataUser?.username}</td>
                                    <td className="px-4 py-3 whitespace-wrap text-sm text-gray-700">{patient.userResponsable?.username}</td>
                                    <td className="px-4 py-3 whitespace-wrap text-sm text-gray-700">{patient.descripcion}</td>
                                    <td className="px-4 py-2">
                                        <div className="flex flex-col md:flex-row items-center justify-center space-x-0 md:space-x-2 space-y-2 md:space-y-0">
                                            <button className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 hover:bg-blue-700 text-white font-bold py-1 px-2 w-full md:w-auto rounded"
                                                onClick={() => onHandleView({ collection: 'PACIENTE', id: patient.dataUser?._id, accion: patient.accion, detalles: patient.detalles })}>
                                                Ver
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            {activeTab === 'doctors' && doctors && doctors.map((doctor, index) => (
                                <tr key={index}>
                                    <td className="px-4 py-3 text-center whitespace-wrap text-sm text-gray-500">{doctor.createdAt}</td>
                                    <td className="px-4 py-3 whitespace-wrap">
                                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getLevelColor(doctor.accion)}`}>{doctor.accion?.toUpperCase()}</span>
                                    </td>
                                    <td className="px-6 py-4 whitespace-wrap text-sm text-gray-700">{doctor.dataUser?.username}</td>
                                    <td className="px-6 py-4 whitespace-wrap text-sm text-gray-700">{doctor.userResponsable?.username}</td>
                                    <td className="px-6 py-4 whitespace-wrap text-sm text-gray-700">{doctor.descripcion}</td>
                                    <td className="px-4 py-2">
                                        <div className="flex flex-col md:flex-row items-center justify-center space-x-0 md:space-x-2 space-y-2 md:space-y-0">
                                            <button className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 hover:bg-blue-700 text-white font-bold py-1 px-2 w-full md:w-auto rounded"
                                                onClick={() => onHandleView({ collection: 'DOCTOR', id: doctor.dataUser?._id, accion: doctor.accion, detalles: doctor.detalles })}>
                                                Ver
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            {activeTab === 'admins' && admins && admins.map((admin, index) => (
                                <tr key={index}>
                                    <td className="px-4 py-3 text-center whitespace-wrap text-sm text-gray-500">{admin.createdAt}</td>
                                    <td className="px-4 py-3 whitespace-wrap">
                                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getLevelColor(admin.accion)}`}>{admin.accion?.toUpperCase()}</span>
                                    </td>
                                    <td className="px-6 py-4 whitespace-wrap text-sm text-gray-700">{admin.dataUser?.username}</td>
                                    <td className="px-6 py-4 whitespace-wrap text-sm text-gray-700">{admin.userResponsable?.username}</td>
                                    <td className="px-6 py-4 whitespace-wrap text-sm text-gray-700">{admin.descripcion}</td>
                                    <td className="px-4 py-2">
                                        <div className="flex flex-col md:flex-row items-center justify-center space-x-0 md:space-x-2 space-y-2 md:space-y-0">
                                            <button className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 hover:bg-blue-700 text-white font-bold py-1 px-2 w-full md:w-auto rounded"
                                                onClick={() => onHandleView({ collection: 'ADMIN', id: admin.dataUser?._id, accion: admin.accion, detalles: admin.detalles })}>
                                                Ver
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            {activeTab === 'appointments' && appointments && appointments.map((appointment, index) => (
                                <tr key={index}>
                                    <td className="px-4 py-3 text-center whitespace-wrap text-sm text-gray-500">{appointment.createdAt}</td>
                                    <td className="px-4 py-3 whitespace-wrap">
                                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getLevelColor(appointment.accion)}`}>{appointment.accion?.toUpperCase()}</span>
                                    </td>
                                    <td className="px-6 py-4 whitespace-wrap text-sm text-gray-700">{appointment.userResponsable?.username}</td>
                                    <td className="px-6 py-4 whitespace-wrap text-sm text-gray-700">{appointment.descripcion}</td>

                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )

    function changeTitle(collection, accion) {
        collection = collection.toUpperCase();
        accion = accion.toUpperCase();
        switch (accion) {
            case 'CREAR':
                return `${collection} CREADO`;
            case 'MODIFICAR':
                return `CAMPO(s) DE ${collection} MODIFICADO(s)`;
            case 'ELIMINAR':
                return `${collection} ELIMINADO`;
            default:
                return accion;
        }
    }

    function getAllPropsAndOrginazeInformation(data) {
        let props = Object.keys(data);
        // console.log(props);
        let props_attributes = '';
        let index = 0;
        for (let i of props) {
            if (Array.isArray(data[i])) {
                props_attributes += `<b>${i.replace(/_/g, ' ')}:</b><br>`;
                data[i].forEach(item => {
                    props_attributes += '<div style="margin-left: 20px;">';
                    props_attributes += getAllPropsAndOrginazeInformation(item).all;
                    props_attributes += '</div>';
                });
            } else {
                // console.log(i);
                props_attributes += `<b>${i.replace(/_/g, ' ')}:</b> ${i.toLowerCase().includes('foto') ?
                    `<div style='display: flex; justify-content: center; align-items: center;'>
                    <img width="100px" height="100px" src=${data[i]} />
                </div>`
                    : '&nbsp;' + data[i]}`;
            }

            if (index < props.length - 1) {
                props_attributes += '<br><br>';
            }
            index++;
        }
        return {
            all: props_attributes,
        };
    }

    function getNombreRecursosClinica(clinic) {
        if (clinic.dataSede) {
            return ["Sede", clinic.dataSede._id];
        }
        else if (clinic.dataServicio) {
            return ["Servicio", clinic.dataServicio._id];
        }
        else if (clinic.dataCapacidad) {
            return ["Capacidad", clinic.dataCapacidad._id];;
        }
        else if (clinic.dataFormato) {
            return ["Formato", clinic.dataFormato._id];
        }
    }
};

export default CLinicLogs;


{/* <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{log.timestamp}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getLevelColor(log.level)}`}>
                                            {log.level}
                                        </span>
                                    </td>*/}

// const getLevelColor = (level) => {
//     switch (level) {
//       case 'INFO': return 'text-blue-600 bg-blue-100';
//       case 'WARNING': return 'text-yellow-600 bg-yellow-100';
//       case 'ERROR': return 'text-red-600 bg-red-100';
//       case 'DEBUG': return 'text-green-600 bg-green-100';
//       default: return 'text-gray-600 bg-gray-100';
//     }
//   };


/* {header.map( title => (
    title[activeTab]?.map((th, index) => (
        <th key={index} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{th}</th>
    ))
))} 

    Este fragmento de código se utiliza para renderizar dinámicamente los encabezados (<th>) de una tabla HTML 
    basándose en el valor de activeTab. El array header contiene diferentes conjuntos de encabezados para diferentes pestañas
     (patients, doctors, etc.), y el código selecciona y muestra los encabezados correspondientes a la pestaña activa.

*/

