import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import OfficeModal from '../AdminComponent/OfficeModal';
import ServiceModal from './ServiceModal';
import AddCapacidadModal from '../AdminComponent/CapacityModal';
import CrearConsentimientoModal from './CrearConsentimientoModal';
import CrearHistoriaModal from './CrearHistoriaModal';
import TableDocuments from './TableDocuments';

const ClinicProfile = ({ clinicId }) => {
    const [clinicData, setClinicData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [activeTab, setActiveTab] = useState('perfil');
    const [editIndex, setEditIndex] = useState(null);
    const [editableSede, setEditableSede] = useState(null);
    const [editableCapacidad, setEditableCapacidad] = useState(null);
    const [editableFormato, setEditableFormato] = useState(null);
    const [editableServicio, setEditableServicio] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isConsentimientoModalOpen, setConsentimientoModalOpen] = useState(false);
    const [isHistoriaModalOpen, setHistoriaModalOpen] = useState(false);
    const [modalTipoFormato, setModalTipoFormato] = useState('');

    const handleOpenConsentimientoModal = (tipoFormato) => {
        setConsentimientoModalOpen(true);
        setModalTipoFormato(tipoFormato);
    }
    const handleCloseConsentimientoModal = () => {
        setModalTipoFormato('');
        setConsentimientoModalOpen(false);
    }

    const handleOpenHistoriaModal = (tipoFormato) => {
        setHistoriaModalOpen(true);
        setModalTipoFormato(tipoFormato);
    }
    const handleCloseHistoriaModal = () => {
        setHistoriaModalOpen(false);
        setModalTipoFormato('');
    }

    const fetchClinicProfile = async () => {
        try {
            const token = localStorage.getItem('token');
            const clinicId = localStorage.getItem('clinicId');
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/clinic/${clinicId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setClinicData(response.data);
            setLoading(false);
        } catch (err) {
            setError('Error al obtener los datos de la clínica');
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchClinicProfile();
    }, [clinicId]);

    if (loading) return <p className="text-center text-gray-600">Cargando...</p>;
    if (error) return <p className="text-center text-red-600">{error}</p>;

    const saveSedeChanges = async (updatedSede) => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.put(
                `${process.env.REACT_APP_BACKEND_URL}/api/clinic/updateSede/${updatedSede._id}`,
                updatedSede,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 200) {
                const updatedClinicData = {
                    ...clinicData,
                    sedes: clinicData.sedes.map((sede) =>
                        sede._id === updatedSede._id ? response.data.updatedSede : sede
                    ),
                };
                setClinicData(updatedClinicData);
                setEditIndex(null);
                setEditableSede(null);
                Swal.fire({
                    title: '¡Sede Actualizada con éxito!',
                    icon: 'success',
                    customClass: {
                        title: 'swal2-title-custom',
                        htmlContainer: 'swal2-html-container-custom'
                    }
                });
            } else {
                console.error('Error al actualizar la sede:', response.data.message);
            }
        } catch (error) {
            console.error('Error al actualizar la sede:', error);
        }
    };
    const saveFormatoChanges = async (updatedFormato) => {
        try {
            const token = localStorage.getItem('token');
            const clinicInfo = localStorage.getItem('clinicId'); // Obtener clinicInfo desde localStorage

            const dataToSend = {
                ...updatedFormato,
                clinicInfo // Añadir el clinicInfo a los datos que se van a enviar
            };

            const response = await axios.put(
                `${process.env.REACT_APP_BACKEND_URL}/api/clinic/updateFormato/${updatedFormato._id}`,
                dataToSend,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (response.status === 200) {
                fetchClinicProfile();
                setEditIndex(null);
                setEditableFormato({});
            }
            Swal.fire({
                title: '¡Formato Actualizado con éxito!',
                icon: 'success',
                customClass: {
                    title: 'swal2-title-custom',
                    htmlContainer: 'swal2-html-container-custom'
                }
            });

        } catch (error) {
            console.error('Error al actualizar formato:', error);
        }
    };




    const saveCapacidadChanges = async (updatedCapacidad) => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.put(
                `${process.env.REACT_APP_BACKEND_URL}/api/clinic/updateCapacidad/${updatedCapacidad._id}`,
                updatedCapacidad,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 200) {
                const updatedClinicData = {
                    ...clinicData,
                    capacidades: clinicData.capacidades.map((capacidad) =>
                        capacidad._id === updatedCapacidad._id ? response.data.updatedCapacidad : capacidad
                    ),
                };
                setClinicData(updatedClinicData);
                setEditableCapacidad(null);
                Swal.fire({
                    title: '¡Capacidad Actualizada con éxito!',
                    icon: 'success',
                    customClass: {
                        title: 'swal2-title-custom',
                        htmlContainer: 'swal2-html-container-custom'
                    }
                });
            } else {
                console.error('Error al actualizar la capacidad:', response.data.message);
            }
        } catch (error) {
            console.error('Error al actualizar la capacidad:', error);
        }
    };
    const saveServicioChanges = async (updatedServicios) => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.put(
                `${process.env.REACT_APP_BACKEND_URL}/api/clinic/updateServicios/${updatedServicios._id}`,
                updatedServicios,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 200) {
                const updatedClinicData = {
                    ...clinicData,
                    servicios: clinicData.servicios.map((servicios) =>
                        servicios._id === updatedServicios._id ? response.data.updatedServicios : servicios
                    ),
                };
                setClinicData(updatedClinicData);
                setEditIndex(null);
                fetchClinicProfile();
                Swal.fire({
                    title: '¡Servicio Actualizado con éxito!',
                    icon: 'success',
                    customClass: {
                        title: 'swal2-title-custom',
                        htmlContainer: 'swal2-html-container-custom'
                    }
                });
            } else {
                console.error('Error al actualizar la capacidad:', response.data.message);
            }
        } catch (error) {
            console.error('Error al actualizar la capacidad:', error);
        }
    };

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };


    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleSaveSede = async (formData) => {
        try {
            const token = localStorage.getItem('token');
            const clinicInfo = localStorage.getItem('clinicId'); // Obtener clinicInfo desde localStorage

            const dataToSend = {
                ...formData,
                clinicInfo // Añadir el clinicInfo a los datos que se van a enviar
            };

            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/clinic/createSede`, dataToSend, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            fetchClinicProfile();
            console.log('Sede creada:', response.data);
            setIsModalOpen(false);

            Swal.fire({
                title: '¡Sede Creada con éxito!',
                icon: 'success',
                customClass: {
                    title: 'swal2-title-custom',
                    htmlContainer: 'swal2-html-container-custom'
                }
            });
        } catch (error) {
            console.error('Error al crear la sede:', error);
        }
    };
    const handleSaveFormato = async (formData) => {
        console.log('modalTipoFormato:', modalTipoFormato); // Verifica el valor de modalTipoFormato

        try {
            const token = localStorage.getItem('token');
            const clinicInfo = localStorage.getItem('clinicId'); // Obtener clinicInfo desde localStorage

            const dataToSend = {
                ...formData,
                clinicInfo
            };

            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/clinic/createFormato/${modalTipoFormato}`, dataToSend, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setEditableFormato('');
            fetchClinicProfile();
            console.log(`${modalTipoFormato} creado:`, response.data);
            setIsModalOpen(false);

            Swal.fire({
                title: `${modalTipoFormato.charAt(0).toUpperCase() + modalTipoFormato.slice(1)} creado con éxito!`,
                icon: 'success',
                customClass: {
                    title: 'swal2-title-custom',
                    htmlContainer: 'swal2-html-container-custom'
                }
            });
        } catch (error) {
            console.error(`Error al crear ${modalTipoFormato}:`, error);
        }
    };




    const handleSaveCapacidad = async (formData) => {
        try {
            const token = localStorage.getItem('token');
            const clinicInfo = localStorage.getItem('clinicId'); // Obtener clinicInfo desde localStorage

            const dataToSend = {
                ...formData,
                clinicInfo // Añadir el clinicInfo a los datos que se van a enviar
            };

            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/clinic/createCapacidad`, dataToSend, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            fetchClinicProfile();
            console.log('Capacidad creada:', response.data);
            setIsModalOpen(false);

            Swal.fire({
                title: '¡Capacidad Creada con éxito!',
                icon: 'success',
                customClass: {
                    title: 'swal2-title-custom',
                    htmlContainer: 'swal2-html-container-custom'
                }
            });
        } catch (error) {
            console.error('Error al crear la capacidad:', error);
        }
    };

    const handleBack = () => {
        setEditIndex(null);
        setEditableSede(null);
        setEditableCapacidad(null);
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditableSede({ ...editableSede, [name]: value });
    };

    const handleCapacidadInputChange = (e) => {
        const { name, value } = e.target;
        setEditableCapacidad({ ...editableCapacidad, [name]: value });
    };

    const documentsExists = [
        { nameToSave: 'foto_perfil', name: 'Logo Clinica', url: clinicData?.clinic.urlLogoClinica || '', isEdit: false }
    ]

    const renderTabContent = () => {
        switch (activeTab) {
            case 'perfil':
                return (
                    <div>
                        <form className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <div className="col-span-full">
                                <h3 className="text-2xl font-bold mb-4">Información general</h3>
                            </div>
                            <div className="form-group ">
                                <label className="block text-gray-700 font-semibold mb-2">Nombre del Proveedor:</label>
                                <input
                                    type="text"
                                    value={clinicData.clinic.providerName}
                                    readOnly
                                    className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                                />
                            </div>
                            <div className="form-group">
                                <label className="block text-gray-700 font-semibold mb-2">Código del Proveedor:</label>
                                <input
                                    type="text"
                                    value={clinicData.clinic.providerCode}
                                    readOnly
                                    className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                                />
                            </div>
                            <div className="form-group">
                                <label className="block text-gray-700 font-semibold mb-2">Identificación:</label>
                                <input
                                    type="text"
                                    value={clinicData.clinic.identification}
                                    readOnly
                                    className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                                />
                            </div>
                            <div className="form-group">
                                <label className="block text-gray-700 font-semibold mb-2">Clase del Proveedor:</label>
                                <input
                                    type="text"
                                    value={clinicData.clinic.providerClass}
                                    readOnly
                                    className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                                />
                            </div>
                            <div className="form-group">
                                <label className="block text-gray-700 font-semibold mb-2">Código de Sede Principal:</label>
                                <input
                                    type="text"
                                    value={clinicData.clinic.mainHeadquartersCode}
                                    readOnly
                                    className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                                />
                            </div>
                            <div className="form-group">
                                <label className="block text-gray-700 font-semibold mb-2">Email:</label>
                                <input
                                    type="text"
                                    value={clinicData.clinic.email}
                                    readOnly
                                    className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                                />
                            </div>
                            <div className="form-group">
                                <label className="block text-gray-700 font-semibold mb-2">Naturaleza Jurídica:</label>
                                <input
                                    type="text"
                                    value={clinicData.clinic.legalNature}
                                    readOnly
                                    className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                                />
                            </div>
                            <div className="form-group">
                                <label className="block text-gray-700 font-semibold mb-2">Ciudad Principal:</label>
                                <input
                                    type="text"
                                    value={clinicData.clinic.mainCity}
                                    readOnly
                                    className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                                />
                            </div>
                            <div className="form-group">
                                <label className="block text-gray-700 font-semibold mb-2">Departamento Principal:</label>
                                <input
                                    type="text"
                                    value={clinicData.clinic.mainDepartment.departamento}
                                    readOnly
                                    className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                                />
                            </div>
                            <div className="form-group">
                                <label className="block text-gray-700 font-semibold mb-2">Teléfono:</label>
                                <input
                                    type="text"
                                    value={clinicData.clinic.phone}
                                    readOnly
                                    className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                                />
                            </div>
                            <div className="form-group">
                                <label className="block text-gray-700 font-semibold mb-2">Empresa de Estado Social:</label>
                                <input
                                    type="text"
                                    value={clinicData.clinic.stateSocialCompany}
                                    readOnly
                                    className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                                />
                            </div>
                            <div className="form-group">
                                <label className="block text-gray-700 font-semibold mb-2">Carácter Territorial:</label>
                                <input
                                    type="text"
                                    value={clinicData.clinic.territorialCharacter}
                                    readOnly
                                    className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                                />
                            </div>
                            {/* Agregar otros campos del perfil según sea necesario */}
                            <span />
                        </form>
                        <TableDocuments
                            lstDocuments={documentsExists}
                        />
                    </div>
                );
            case 'sedes':
                return (
                    <div>
                        <h3 className="text-2xl font-bold mb-4">Sedes</h3>
                        <div className='flex justify-end -mt-14'>
                            <button
                                onClick={handleOpenModal}
                                className="px-4 py-2 bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 m-2 text-white rounded hover:bg-blue-900">
                                Crear Sede
                            </button>

                            <OfficeModal
                                isOpen={isModalOpen}
                                onClose={handleCloseModal}
                                onSave={handleSaveSede}
                            />
                        </div>
                        {clinicData.sedes.map((sede, index) => (
                            <div key={sede._id} className="mb-4 p-4 bg-gray-100 rounded-lg shadow">

                                {editIndex === index ? (
                                    <div>
                                        <label><strong>Nombre:</strong></label>
                                        <input
                                            type="text"
                                            name="name"
                                            value={editableSede.name}
                                            onChange={handleInputChange}
                                            className="border rounded px-2 py-1 mb-2 w-full"
                                        />
                                        <label><strong>Dirección:</strong></label>
                                        <input
                                            type="text"
                                            name="address"
                                            value={editableSede.address}
                                            onChange={handleInputChange}
                                            className="border rounded px-2 py-1 mb-2 w-full"
                                        />
                                        <label><strong>Telefono:</strong></label>
                                        <input
                                            type="text"
                                            name="phone"
                                            value={editableSede.phone}
                                            onChange={handleInputChange}
                                            className="border rounded px-2 py-1 mb-2 w-full"
                                        />
                                        <label><strong>Correo:</strong></label>
                                        <input
                                            type="text"
                                            name="email"
                                            value={editableSede.email}
                                            onChange={handleInputChange}
                                            className="border rounded px-2 py-1 mb-2 w-full"
                                        />
                                        <label><strong>Codigo:</strong></label>
                                        <input
                                            type="text"
                                            name="code"
                                            value={editableSede.code}
                                            onChange={handleInputChange}
                                            className="border rounded px-2 py-1 mb-2 w-full"
                                        />
                                        <label><strong>Principal</strong></label>
                                        <input
                                            type="text"
                                            name="isMain"
                                            value={editableSede.isMain ? 'Sí' : 'No'}
                                            readOnly
                                            onChange={handleInputChange}
                                            className="border rounded px-2 py-1 mb-2 w-full"
                                        />
                                        <div className="flex justify-start">
                                            <button
                                                onClick={() => saveSedeChanges(editableSede)}
                                                className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white px-4 py-2 rounded mt-2"
                                            >
                                                Guardar
                                            </button>
                                        </div>
                                        <div className="flex justify-end -mt-14">
                                            <button
                                                onClick={handleBack}
                                                className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white px-4 py-2 rounded mt-2"
                                            >
                                                Volver
                                            </button>
                                        </div>

                                    </div>

                                ) : (

                                    <div className="flex justify-between items-start">
                                        <div className="flex-1">
                                            <p><strong>Nombre:</strong> {sede.name}</p>
                                            <p><strong>Dirección:</strong> {sede.address}</p>
                                            <p><strong>Telefono:</strong> {sede.phone}</p>
                                            <p><strong>Email:</strong> {sede.email}</p>
                                            <p><strong>Codigo:</strong> {sede.code}</p>
                                            <p><strong>Principal:</strong> {sede.isMain ? 'Sí' : 'No'}</p>
                                        </div>
                                        <div className='mt-28'>
                                            <button
                                                onClick={() => {
                                                    setEditIndex(index);
                                                    setEditableSede(sede);
                                                }}
                                                className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white px-3 py-1 rounded "
                                            >
                                                Editar
                                            </button>
                                        </div>
                                    </div>


                                )}
                            </div>
                        ))}
                    </div>
                );

            case 'capacidades':
                return (
                    <div>
                        <h3 className="text-2xl font-bold mb-4">Capacidades</h3>
                        <div className='flex justify-end -mt-14'>
                            <button
                                onClick={handleOpenModal}
                                className="px-4 py-2 bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 m-2 text-white rounded hover:bg-blue-900">
                                Crear Capacidad
                            </button>
                            <AddCapacidadModal
                                isOpen={isModalOpen}
                                onClose={handleCloseModal}
                                onSave={handleSaveCapacidad}
                                sedes={clinicData.sedes}
                            />
                        </div>
                        {clinicData.capacidades.map((capacidad) => (
                            <div key={capacidad._id} className="mb-4 p-4 bg-gray-100 rounded-lg shadow">
                                {editableCapacidad?._id === capacidad._id ? (
                                    <div>

                                        <label><strong>Concepto:</strong></label>
                                        <input
                                            type="text"
                                            name="concept"
                                            readOnly
                                            value={editableCapacidad.concept}
                                            onChange={handleCapacidadInputChange}
                                            className="border rounded px-2 py-1 mb-2 w-full bg-gray-200"
                                        />
                                        <label><strong>Grupo:</strong></label>
                                        <input
                                            type="text"
                                            name="group"
                                            readOnly
                                            value={editableCapacidad.group}
                                            onChange={handleCapacidadInputChange}
                                            className="border rounded px-2 py-1 mb-2 w-full bg-gray-200"
                                        />
                                        <label><strong>Cantidad:</strong></label>
                                        <input
                                            type="number"
                                            name="quantity"
                                            value={editableCapacidad.quantity}
                                            onChange={handleCapacidadInputChange}
                                            className="border rounded px-2 py-1 mb-2 w-full"
                                        />
                                        <label><strong>Codigo Sede:</strong></label>
                                        <input
                                            type="text"
                                            value={capacidad.sedeCode} // No editable
                                            readOnly
                                            className="border rounded px-2 py-1 mb-2 w-full bg-gray-200"
                                        />
                                        <label><strong>Nombre Sede:</strong></label>
                                        <input
                                            type="text"
                                            value={capacidad.sedeName} // No editable
                                            readOnly
                                            className="border rounded px-2 py-1 mb-2 w-full bg-gray-200"
                                        />
                                        <div className="flex justify-start">
                                            <button
                                                onClick={() => saveCapacidadChanges(editableCapacidad)}
                                                className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white px-4 py-2 rounded mt-2"
                                            >
                                                Guardar
                                            </button>
                                        </div>
                                        <div className="flex justify-end -mt-14">
                                            <button
                                                onClick={() => setEditableCapacidad(null)}
                                                className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white px-4 py-2 rounded mt-2"
                                            >
                                                Volver
                                            </button>
                                        </div>
                                    </div>
                                ) : (
                                    <div className='flex justify-between items-start'>
                                        <div className="flex-1">
                                            <p><strong>Concepto:</strong> {capacidad.concept}</p>
                                            <p><strong>Grupo:</strong> {capacidad.group}</p>
                                            <p><strong>Cantidad:</strong> {capacidad.quantity}</p>
                                            <p><strong>Codigo Sede:</strong> {capacidad.sedeCode}</p>
                                            <p><strong>Nombre Sede:</strong> {capacidad.sedeName}</p>
                                        </div>
                                        <div className='mt-20'>
                                            <button
                                                onClick={() => setEditableCapacidad(capacidad)}
                                                className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white px-3 py-1 rounded mt-2"
                                            >
                                                Editar
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                );

            case 'servicios':
                return (
                    <div>
                        <h3 className="text-2xl font-bold mb-4">Servicios</h3>
                        <div className='flex justify-end -mt-14'>
                            <button
                                onClick={handleOpenModal}
                                className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white px-4 py-2 rounded mb-4"
                            >
                                Crear Servicio
                            </button>
                            <ServiceModal
                                isOpen={isModalOpen}
                                onClose={handleCloseModal}
                                render={fetchClinicProfile}
                                sedes={clinicData.sedes}
                            />
                        </div>
                        {clinicData.servicios.map((servicio, index) => (
                            <div key={servicio._id} className="mb-4 p-4 bg-gray-100 rounded-lg shadow">
                                {editIndex === index ? (
                                    <div>
                                        <p><strong>Servicio:</strong> {servicio.service}</p>
                                        <p><strong>Grupo:</strong> {servicio.group}</p>
                                        <p><strong>Codigo Sede:</strong> {servicio.sedeCode}</p>
                                        <p><strong>Nombre Sede:</strong> {servicio.sedeName}</p>
                                        <p><strong>Modalidad:</strong> {servicio.modality}</p>
                                        <div className="flex items-center">
                                            <label className="block text-gray-700 mr-2"><strong>Valor consulta:</strong></label>
                                            <input
                                                type="number"
                                                value={editableServicio.consultationValue}
                                                onChange={(e) => {
                                                    const newValue = parseFloat(e.target.value) || 0;
                                                    setEditableServicio({
                                                        ...editableServicio,
                                                        consultationValue: newValue,
                                                        netValue: newValue + parseFloat(editableServicio.copayValue) || 0
                                                    });
                                                }}
                                                className="px-3 py-2 border rounded"
                                            />
                                        </div>
                                        <div className="flex items-center">
                                            <label className="block text-gray-700 mr-2"><strong>Valor cuota moderadora:</strong></label>
                                            <input
                                                type="number"
                                                value={editableServicio.copayValue}
                                                onChange={(e) => {
                                                    const newValue = parseFloat(e.target.value) || 0;
                                                    setEditableServicio({
                                                        ...editableServicio,
                                                        copayValue: newValue,
                                                        netValue: newValue + parseFloat(editableServicio.consultationValue) || 0
                                                    });
                                                }}
                                                className="px-3 py-2 border rounded"
                                            />
                                        </div>
                                        <div className="flex items-center">
                                            <label className="block text-gray-700 mr-2"><strong>Valor neto:</strong></label>
                                            <input
                                                type="number"
                                                value={editableServicio.netValue}
                                                readOnly
                                                className="px-3 py-2 border rounded bg-gray-100"
                                            />
                                        </div>
                                        <div className="flex justify-end mt-2">
                                            <button
                                                onClick={() => saveServicioChanges({
                                                    ...editableServicio,
                                                    consultationValue: parseFloat(editableServicio.consultationValue),
                                                    copayValue: parseFloat(editableServicio.copayValue),
                                                    netValue: parseFloat(editableServicio.netValue)
                                                })}
                                                className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white px-4 py-2 rounded mr-2"
                                            >
                                                Guardar
                                            </button>
                                            <button
                                                onClick={() => setEditIndex(null)}
                                                className="bg-gray-500 text-white px-4 py-2 rounded"
                                            >
                                                Cancelar
                                            </button>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <div className='flex justify-between items-start'>
                                            <div className="flex-1">
                                                <p><strong>Servicio:</strong> {servicio.service}</p>
                                                <p><strong>Grupo:</strong> {servicio.group}</p>
                                                <p><strong>Valor consulta:</strong> ${servicio.consultationValue} COP</p>
                                                <p><strong>Valor cuota moderadora:</strong> ${servicio.copayValue} COP</p>
                                                <p><strong>Valor neto:</strong> ${servicio.netValue} COP</p>
                                                <p><strong>Codigo Sede:</strong> {servicio.sedeCode}</p>
                                                <p><strong>Nombre Sede:</strong> {servicio.sedeName}</p>
                                                <p><strong>Modalidad:</strong> {servicio.modality}</p>
                                            </div>
                                            <div className='mt-36'>
                                                <button
                                                    onClick={() => {
                                                        setEditIndex(index);
                                                        setEditableServicio(servicio);
                                                    }}
                                                    className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white px-3 py-1 rounded mt-2"
                                                >
                                                    Editar
                                                </button>
                                            </div>
                                        </div>
                                        <TableDocuments
                                            lstDocuments={[{ nameToSave: 'sede_clinica_documento_habilitacion', name: 'Documento Habilitación', url: servicio.urlHabilitationDocument || '', isEdit: false }]}
                                        />
                                    </div>

                                )}
                            </div>
                        ))}
                    </div>
                );
            case 'formatos':
                return (
                    <div>
                        <h3 className="text-2xl font-bold mb-4">Formatos</h3>

                        <div className="flex justify-end space-x-4 mb-4">
                            <button
                                onClick={() => handleOpenConsentimientoModal('consentimiento')}
                                className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white px-4 py-2 rounded"
                            >
                                Crear Consentimiento
                            </button>
                            <button
                                onClick={() => handleOpenHistoriaModal('historia')}
                                className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white px-4 py-2 rounded"
                            >
                                Crear Historia Clínica
                            </button>
                        </div>

                        <CrearConsentimientoModal
                            isOpen={isConsentimientoModalOpen}
                            onClose={() => {
                                handleCloseConsentimientoModal();
                                setEditableFormato({}); // Reiniciar valores del formato editable
                            }}
                            servicios={clinicData.servicios}
                            onSave={handleSaveFormato}
                        />

                        <CrearHistoriaModal
                            isOpen={isHistoriaModalOpen}
                            onClose={() => {
                                handleCloseHistoriaModal();
                                setEditableFormato({}); // Reiniciar valores del formato editable
                            }}
                            servicios={clinicData.servicios}
                            onSave={handleSaveFormato}
                        />

                        <div className="grid grid-cols-2 gap-4">
                            <div>
                                <h4 className="text-xl font-semibold mb-2">Consentimientos</h4>
                                {clinicData.formatos?.consentimientos?.map((consentimiento, index) => (
                                    <div key={consentimiento._id} className="mb-4 p-4 bg-gray-100 rounded-lg shadow">
                                        {editIndex === `consentimiento-${index}` ? (
                                            <div>
                                                <label className="block text-gray-700 font-semibold mb-1">Nombre del Consentimiento</label>
                                                <input
                                                    type="text"
                                                    value={editableFormato.nombreConsentimiento || ''}
                                                    onChange={(e) => setEditableFormato({ ...editableFormato, nombreConsentimiento: e.target.value })}
                                                    className="px-3 py-2 border rounded mb-2"
                                                    placeholder="Nombre del Consentimiento"
                                                />
                                                <label className="block text-gray-700 font-semibold mb-1">Servicio Ligado</label>
                                                <select
                                                    value={editableFormato.servicioLigado || ''}
                                                    onChange={(e) => setEditableFormato({ ...editableFormato, servicioLigado: e.target.value })}
                                                    className="px-3 py-2 border rounded mb-2"
                                                >
                                                    {clinicData.servicios.map((servicio) => (
                                                        <option key={servicio._id} value={servicio.service}>
                                                            {servicio.service}
                                                        </option>
                                                    ))}
                                                </select>
                                                <label className="block text-gray-700 font-semibold mb-1">Consentimiento</label>
                                                <input
                                                    type="text"
                                                    value={editableFormato.consentimiento || ''}
                                                    onChange={(e) => setEditableFormato({ ...editableFormato, consentimiento: e.target.value })}
                                                    className="px-3 py-2 border rounded mb-2"
                                                    placeholder="Consentimiento"
                                                />
                                                <div className="flex justify-end mt-2">
                                                    <button
                                                        onClick={() => saveFormatoChanges(editableFormato)} // Guardar cambios
                                                        className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white px-4 py-2 rounded mr-2"
                                                    >
                                                        Guardar
                                                    </button>
                                                    <button
                                                        onClick={() => {
                                                            setEditIndex(null); // Cancelar edición
                                                            setEditableFormato({}); // Reiniciar valores del formato editable
                                                        }}
                                                        className="bg-gray-500 text-white px-4 py-2 rounded"
                                                    >
                                                        Cancelar
                                                    </button>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className='flex justify-between items-start'>
                                                <div className="flex-1">
                                                    <p><strong>Nombre:</strong> {consentimiento.nombreConsentimiento}</p>
                                                    <p><strong>Servicio ligado:</strong> {consentimiento.servicioLigado}</p>
                                                    <p><strong>Consentimiento:</strong> {consentimiento.consentimiento}</p>
                                                </div>
                                                <div className='mt-18'>
                                                    <button
                                                        onClick={() => {
                                                            setEditIndex(`consentimiento-${index}`);
                                                            setEditableFormato(consentimiento); // Iniciar edición
                                                        }}
                                                        className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white px-3 py-1 rounded mt-8"
                                                    >
                                                        Editar
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                            <div>
                                <h4 className="text-xl font-semibold mb-2">Historias</h4>
                                {clinicData.formatos?.historias?.map((historia, index) => (
                                    <div key={historia._id} className="mb-4 p-4 bg-gray-100 rounded-lg shadow">
                                        {editIndex === `historia-${index}` ? (
                                            <div>
                                                <label className="block text-gray-700 font-semibold mb-1">Nombre de la Historia Clínica</label>
                                                <input
                                                    type="text"
                                                    value={editableFormato.nombreHC || ''}
                                                    onChange={(e) => setEditableFormato({ ...editableFormato, nombreHC: e.target.value })}
                                                    className="px-3 py-2 border rounded mb-2"
                                                    placeholder="Nombre de la Historia Clínica"
                                                />
                                                <label className="block text-gray-700 font-semibold mb-1">Servicio Ligado</label>
                                                <select
                                                    value={editableFormato.servicioLigado || ''}
                                                    onChange={(e) => setEditableFormato({ ...editableFormato, servicioLigado: e.target.value })}
                                                    className="px-3 py-2 border rounded mb-2"
                                                >
                                                    {clinicData.servicios.map((servicio) => (
                                                        <option key={servicio._id} value={servicio.service}>
                                                            {servicio.service}
                                                        </option>
                                                    ))}
                                                </select>
                                                <label className="block text-gray-700 font-semibold mb-1">Historia Clínica</label>
                                                <input
                                                    type="text"
                                                    value={editableFormato.historiaClinica || ''}
                                                    onChange={(e) => setEditableFormato({ ...editableFormato, historiaClinica: e.target.value })}
                                                    className="px-3 py-2 border rounded mb-2"
                                                    placeholder="Historia Clínica"
                                                />
                                                <div className="flex justify-end mt-2">
                                                    <button
                                                        onClick={() => saveFormatoChanges(editableFormato)} // Guardar cambios
                                                        className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white px-4 py-2 rounded mr-2"
                                                    >
                                                        Guardar
                                                    </button>
                                                    <button
                                                        onClick={() => {
                                                            setEditIndex(null); // Cancelar edición
                                                            setEditableFormato({}); // Reiniciar valores del formato editable
                                                        }}
                                                        className="bg-gray-500 text-white px-4 py-2 rounded"
                                                    >
                                                        Cancelar
                                                    </button>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className='flex justify-between items-start'>
                                                <div className="flex-1">
                                                    <p><strong>Nombre:</strong> {historia.nombreHC}</p>
                                                    <p><strong>Servicio ligado:</strong> {historia.servicioLigado}</p>
                                                    <p><strong>Historia clínica:</strong> {historia.historiaClinica}</p>
                                                </div>
                                                <div className='mt-18'>
                                                    <button
                                                        onClick={() => {
                                                            setEditIndex(`historia-${index}`);
                                                            setEditableFormato(historia); // Iniciar edición
                                                        }}
                                                        className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white px-3 py-1 rounded mt-8"
                                                    >
                                                        Editar
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                );


            case 'insumos':
                return (
                    <div>
                        <h3 className="text-2xl font-bold mb-4">Insumos</h3>
                        {clinicData.insumos.inventario.map((insumo, index) => (
                            <div key={index} className="mb-4 p-4 bg-gray-100 rounded-lg shadow">
                                <p><strong>Producto:</strong> {insumo.producto}</p>
                                <p><strong>Cantidad:</strong> {insumo.cantidad}</p>
                                {/* Agregar otros campos de insumos según sea necesario */}
                            </div>
                        ))}
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg">
            <h2 className="text-3xl font-bold text-gray-800 mb-6 text-center">Perfil de la Clínica</h2>
            <div className="mb-6">
                <button
                    onClick={() => setActiveTab('perfil')}
                    className={`mr-4 ${activeTab === 'perfil' ? 'text-blue-500' : 'text-gray-500'} focus:outline-none`}
                >
                    Perfil
                </button>
                <button
                    onClick={() => setActiveTab('sedes')}
                    className={`mr-4 ${activeTab === 'sedes' ? 'text-blue-500' : 'text-gray-500'} focus:outline-none`}
                >
                    Sedes
                </button>
                <button
                    onClick={() => setActiveTab('capacidades')}
                    className={`mr-4 ${activeTab === 'capacidades' ? 'text-blue-500' : 'text-gray-500'} focus:outline-none`}
                >
                    Capacidades
                </button>
                <button
                    onClick={() => setActiveTab('servicios')}
                    className={`mr-4 ${activeTab === 'servicios' ? 'text-blue-500' : 'text-gray-500'} focus:outline-none`}
                >
                    Servicios
                </button>
                <button
                    onClick={() => setActiveTab('formatos')}
                    className={`mr-4 ${activeTab === 'formatos' ? 'text-blue-500' : 'text-gray-500'} focus:outline-none`}
                >
                    Formatos
                </button>
                <button
                    onClick={() => setActiveTab('insumos')}
                    className={`mr-4 ${activeTab === 'insumos' ? 'text-blue-500' : 'text-gray-500'} focus:outline-none`}
                >
                    Insumos
                </button>
            </div>
            <div>{renderTabContent()}</div>
        </div>
    );
};

export default ClinicProfile;
