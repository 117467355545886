import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';
import image1 from '../../images/Logo_Mozart_color.png'; // Logo
import backgroundImage from '../../images/background2.jpg'; // Nueva imagen de fondo

Modal.setAppElement('#root'); // Para accesibilidad del modal

const Login = ({ callback }) => {
    const [showPassword, setShowPassword] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [newPassword, setNewPassword] = useState('');

    const navigate = useNavigate();

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/auth/login`, {
                username,
                password,
            });
            if (response.data) {
                // Configuración de Local Storage
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('role', response.data.role);
                localStorage.setItem('clinicId', response.data.userId);
                response.data.imageProfile && localStorage.setItem('imageProfile', response.data.imageProfile);

                localStorage.setItem('clinicName', response.data.clinicName);
                localStorage.setItem('clinicEmail', response.data.clinicEmail);
                localStorage.setItem('patientName', response.data.patientName);
                localStorage.setItem('patientEmail', response.data.patientEmail);
                localStorage.setItem('patientId', response.data.patientId);
                localStorage.setItem('doctorName', response.data.doctorName);
                localStorage.setItem('doctorEmail', response.data.doctorEmail);
                localStorage.setItem('doctorId', response.data.doctorId);
                localStorage.setItem('adminName', response.data.adminName);
                localStorage.setItem('adminEmail', response.data.adminEmail);
                localStorage.setItem('adminId', response.data.adminId);

                callback(response.data.username, response.data.role);
                switch (response.data.role) {
                    case 'admin':
                        navigate('/admin');
                        break;
                    case 'clinicAdmin':
                        navigate('/clinic');
                        break;
                    case 'clinic':
                        navigate('/clinic');
                        break;
                    case 'doctor':
                        navigate('/doctor');
                        break;
                    case 'patient':
                        navigate('/patient');
                        break;
                    default:
                        setError('Rol de usuario desconocido');
                        break;
                }
            } else {
                setError('Usuario o contraseña incorrectos');
            }
        } catch (error) {
            if (error.response && error.response.status === 403) {
                Swal.fire({
                    title: 'Cuenta desactivada',
                    text: 'Tu cuenta ha sido desactivada. Contacta con el administrador.',
                    icon: 'warning',
                    customClass: {
                        title: 'swal2-title-custom',
                        htmlContainer: 'swal2-html-container-custom'
                    }
                });
            } else {
                Swal.fire({
                    title: 'Error al iniciar sesión',
                    text: 'Por favor, verifica tus credenciales.',
                    icon: 'error',
                    customClass: {
                        title: 'swal2-title-custom',
                        htmlContainer: 'swal2-html-container-custom'
                    }
                });
            }
        }
    };

    const handlePasswordResetRequest = async () => {
        try {
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/auth/reset-password-request`, { email });
            Swal.fire({
                title: 'Código enviado',
                text: 'Te hemos enviado un código de verificación a tu correo electrónico.',
                icon: 'success',
            });
        } catch (error) {
            Swal.fire({
                title: 'Error',
                text: 'Hubo un problema al enviar el código. Por favor, intenta de nuevo.',
                icon: 'error',
            });
        }
    };

    const handlePasswordReset = async () => {
        try {
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/auth/reset-password`, {
                email,
                verificationCode,
                newPassword,
            });
            Swal.fire({
                title: 'Contraseña cambiada',
                text: 'Tu contraseña ha sido cambiada exitosamente.',
                icon: 'success',
            });
            setIsModalOpen(false);
        } catch (error) {
            Swal.fire({
                title: 'Error',
                text: 'Hubo un problema al cambiar la contraseña. Verifica el código e intenta de nuevo.',
                icon: 'error',
            });
        }
    };

    const goToTerms = () => {
        navigate('/terminos-y-condiciones');
    };

    return (
        <div
          className="flex items-center justify-center min-h-screen bg-cover bg-center"
          style={{ backgroundImage: `url(${backgroundImage})` }}
        >
          <div className="w-full max-w-md bg-white bg-opacity-80 rounded-xl shadow-lg p-8 md:p-10 backdrop-filter backdrop-blur-lg">
            <img src={image1} alt="Logo" className="w-32 mx-auto mb-6" />
            <h3 className="text-2xl font-semibold text-center mb-4 text-gray-800">Inicia sesión en tu cuenta</h3>
            <p className="text-center mb-4 text-gray-600">Ingresa tu usuario y contraseña para iniciar</p>
    
            {error && (
              <div className="bg-red-100 text-red-700 p-4 rounded mb-4">
                <p>{error}</p>
                <button onClick={() => setError(null)} className="text-red-500 underline">Cerrar</button>
              </div>
            )}
    
            <form onSubmit={handleSubmit}>
              <div className="mb-4 relative">
                <label htmlFor="username" className="block text-gray-700 font-semibold">Usuario</label>
                <div className="flex items-center bg-gray-100 border mt-2 border-gray-300 rounded-lg">
                  <FontAwesomeIcon icon={faUser} className="text-gray-500 ml-3" />
                  <input
                    type="text"
                    id="username"
                    name="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value.toLowerCase())}
                    required
                    className="w-full px-4 py-2 bg-transparent focus:outline-none"
                  />
                </div>
              </div>
              <div className="mb-6 relative">
                <label htmlFor="password" className="block text-gray-700 font-semibold">Contraseña</label>
                <div className="flex items-center bg-gray-100 border mt-2 border-gray-300 rounded-lg">
                  <FontAwesomeIcon icon={faLock} className="text-gray-500 ml-3" />
                  <input
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    className="w-full px-4 py-2 bg-transparent focus:outline-none"
                  />
                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    className="text-gray-500 mr-3"
                  >
                    {showPassword ? '👁️' : '👁️‍🗨️'}
                  </button>
                </div>
              </div>
    
              <div className="flex justify-between mb-4">
                <button
                  type="button"
                  onClick={() => setIsModalOpen(true)}
                  className="text-blue-500 underline text-xs"
                >
                  ¿Olvidaste tu contraseña?
                </button>
                <button
                  type="button"
                  onClick={goToTerms}
                  className="text-blue-500 underline text-xs"
                >
                  Términos y Condiciones
                </button>
              </div>
    
              <button
                type="submit"
                className="w-full bg-gradient-to-r from-blue-500 to-blue-700 text-white font-bold py-3 px-4 rounded-lg hover:from-blue-600 hover:to-blue-800 transition duration-300"
              >
                Iniciar sesión
              </button>
            </form>
          </div>
    
          {/* Modal para recuperación de contraseña */}
          <Modal
            isOpen={isModalOpen}
            onRequestClose={() => setIsModalOpen(false)}
            className="w-full max-w-md mx-auto my-4 bg-white p-8 rounded-lg shadow-lg"
            overlayClassName="fixed inset-0 bg-gray-500 bg-opacity-75"
          >
            <h2 className="text-2xl font-semibold mb-4">Recuperación de Contraseña</h2>
            <div className="mb-4">
              <label htmlFor="email" className="block text-gray-700 font-semibold">Correo Electrónico</label>
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="w-full px-4 py-2 bg-gray-100 mt-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-300"
              />
            </div>
            <button
              onClick={handlePasswordResetRequest}
              className="w-full bg-blue-500 text-white font-bold py-3 px-4 rounded-lg hover:bg-blue-700 transition duration-300 mb-4"
            >
              Solicitar Código
            </button>
            <div className="mb-4">
              <label htmlFor="verificationCode" className="block text-gray-700 font-semibold">Código de Verificación</label>
              <input
                type="text"
                id="verificationCode"
                name="verificationCode"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                required
                className="w-full px-4 py-2 bg-gray-100 mt-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-300"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="newPassword" className="block text-gray-700 font-semibold">Nueva Contraseña</label>
              <input
                type="password"
                id="newPassword"
                name="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
                className="w-full px-4 py-2 bg-gray-100 mt-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-300"
              />
            </div>
            <button
              onClick={handlePasswordReset}
              className="w-full bg-green-500 text-white font-bold py-3 px-4 rounded-lg hover:bg-green-700 transition duration-300"
            >
              Cambiar Contraseña
            </button>
            <button
              onClick={() => setIsModalOpen(false)}
              className="w-full bg-blue-500 text-white font-bold py-3 mt-8 px-4 rounded-lg hover:bg-blue-700 transition duration-300"
            >
              Cerrar
            </button>
          </Modal>
        </div>
      );
    };
    
    export default Login;
    