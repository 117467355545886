import React, { useState, useContext } from 'react';
import InfoGeneral from './InfoGeneral';
import Sedes from './Sedes';
import Capacidad from './Capacidad';
import Servicios from './Servicios';
import Formatos from './Formatos';
import Insumos from './Insumos'; // Importar el nuevo componente
import { FormContext } from './FormContext';
import useWindowSize from './useWindowSize'; // Importa el hook

const CreateClinic = ({ handleCancel, fetchClinics }) => {
    const [view, setView] = useState('infoGeneral');
    const { formState, updateFormState } = useContext(FormContext);
    const size = useWindowSize(); // Usa el hook para obtener el tamaño de la ventana

    const handleNext = () => {
        if (view === 'infoGeneral') {
            setView('sedes');
        } else if (view === 'sedes') {
            setView('capacidad');
        } else if (view === 'capacidad') {
            setView('servicios');
        } else if (view === 'servicios') {
            setView('formatos');
        } else if (view === 'formatos') {
            setView('insumos'); 
        }
    };

    const handlePrevious = () => {
        if (view === 'sedes') {
            setView('infoGeneral');
        } else if (view === 'capacidad') {
            setView('sedes');
        } else if (view === 'servicios') {
            setView('capacidad');
        } else if (view === 'formatos') {
            setView('servicios');
        } else if (view === 'insumos') {
            setView('formatos'); // Transición de regreso desde Insumos
        }
    };

    return (
        <div className="container mx-auto p-4">
            <div className="flex flex-col md:flex-row">
                {size.width >= 768 && ( // Muestra el menú solo si el ancho de la ventana es mayor o igual a 768px
                    <div className="w-full md:w-1/4 p-4 bg-gray-100 border-r">
                        <ul className="space-y-4">
                            <li
                                className={`cursor-pointer p-2 rounded ${view === 'infoGeneral' ? 'bg-blue-600 text-white' : 'bg-gray-100'}`}
                                onClick={() => setView('infoGeneral')}
                            >
                                Información general
                            </li>
                            <li
                                className={`cursor-pointer p-2 rounded ${view === 'sedes' ? 'bg-blue-600 text-white' : 'bg-gray-100'}`}
                                onClick={() => setView('sedes')}
                            >
                                Sedes
                            </li>
                            <li
                                className={`cursor-pointer p-2 rounded ${view === 'capacidad' ? 'bg-blue-600 text-white' : 'bg-gray-100'}`}
                                onClick={() => setView('capacidad')}
                            >
                                Capacidad
                            </li>
                            <li
                                className={`cursor-pointer p-2 rounded ${view === 'servicios' ? 'bg-blue-600 text-white' : 'bg-gray-100'}`}
                                onClick={() => setView('servicios')}
                            >
                                Servicios
                            </li>
                            <li
                                className={`cursor-pointer p-2 rounded ${view === 'formatos' ? 'bg-blue-600 text-white' : 'bg-gray-100'}`}
                                onClick={() => setView('formatos')}
                            >
                                Formatos
                            </li>
                            <li
                                className={`cursor-pointer p-2 rounded ${view === 'insumos' ? 'bg-blue-600 text-white' : 'bg-gray-100'}`}
                                onClick={() => setView('insumos') }
                            >
                                Insumos
                            </li>
                            <li
                                className="cursor-pointer p-2 rounded bg-blue-600 text-white"
                                onClick={handleCancel} // Acción de cancelar
                            >
                                Cancelar
                            </li>
                        </ul>
                    </div>
                )}
                <div className="w-full md:w-3/4 p-4">
                    {view === 'infoGeneral' && <InfoGeneral handleNext={handleNext} />}
                    {view === 'sedes' && <Sedes handleNext={handleNext} handlePrevious={handlePrevious} />}
                    {view === 'capacidad' && <Capacidad handleNext={handleNext} handlePrevious={handlePrevious} />}
                    {view === 'servicios' && <Servicios handleNext={handleNext} handlePrevious={handlePrevious} />}
                    {view === 'formatos' && <Formatos handleNext={handleNext} handlePrevious={handlePrevious} />}
                    {view === 'insumos' && <Insumos handlePrevious={handlePrevious} handleCancel={handleCancel} fetchClinics={fetchClinics} />} {/* Añadir Insumos */}
                </div>
            </div>
        </div>
    );
};

export default CreateClinic;
