import axios from 'axios';
import Swal from 'sweetalert2';
import React, { useEffect, useState } from 'react';


const NotasConsulta = ({ appointmentId }) => {
  const [appointmentInfo, setAppointmentInfo] = useState(null);

  const [notas, setNotas] = useState(() => {
    const savedNotas = localStorage.getItem('notasConsulta');
    return savedNotas ? JSON.parse(savedNotas) : [];
  });

  const [titulo, setTitulo] = useState('');
  const [descripcion, setDescripcion] = useState('');

  const [editIndex, setEditIndex] = useState(null);

  useEffect(() => {
    localStorage.setItem('notasConsulta', JSON.stringify(notas));
  }, [notas]);

  const handleGuardarNota = () => {
    if (editIndex !== null) {
      const updatedNotas = [...notas];
      updatedNotas[editIndex] = { titulo, descripcion };
      setNotas(updatedNotas);
      setEditIndex(null);
    } else {
      setNotas([...notas, { titulo, descripcion }]);
    }

    setTitulo('');
    setDescripcion('');
  };

  const handleEliminarNota = (index) => {
    const updatedNotas = notas.filter((_, i) => i !== index);
    setNotas(updatedNotas);
  };

  const handleEditarNota = (index) => {
    setTitulo(notas[index].titulo);
    setDescripcion(notas[index].descripcion);
    setEditIndex(index);
  };
  const fetchAppointmentInfo = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/clinic/appointment-info/${appointmentId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      setAppointmentInfo(response.data);
    } catch (error) {
      console.error('Error al obtener la información de la cita:', error);
    }
  };

  useEffect(() => {
    fetchAppointmentInfo();
  }, [appointmentId]);
  // Nueva función para enviar las notas al backend
  const handleSubmit = async (e) => {
    e.preventDefault();

    
    const doctorId = localStorage.getItem('doctorId');  // Supongamos que también se guarda
    const token = localStorage.getItem('token');

    if (!doctorId || !appointmentId || !token) {
      Swal.fire('Error', 'Falta información de autenticación', 'error');
      return;
    }

    try {
      // Hacer el POST hacia el backend
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/clinic/notas-consulta`, {
        appointment: appointmentId,
        patientId: appointmentInfo.patientId,
        doctorId: doctorId,
        notas: notas,  
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Mostrar alerta de éxito
      Swal.fire('Éxito', 'Las notas se enviaron correctamente', 'success');

      // Limpiar las notas después de enviar
      setNotas([]);
      setTitulo('');
      setDescripcion('');
      localStorage.removeItem('notasConsulta');
    } catch (error) {
      // Mostrar alerta de error
      Swal.fire('Error', 'Hubo un problema al enviar las notas', 'error');
      console.error('Error al enviar las notas:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="h-[530px] overflow-y-auto p-4">
      <h2 className="text-xl font-bold mb-4">Notas de Consulta</h2>

      {/* Formulario para agregar o editar una nota */}
      <div className="mb-6 border-b pb-4">
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">Título de la Nota:</label>
          <input
            type="text"
            value={titulo}
            onChange={(e) => setTitulo(e.target.value)}
            className="w-full border border-gray-300 rounded-md shadow-sm"
            placeholder="Título"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">Descripción de la Nota:</label>
          <textarea
            value={descripcion}
            onChange={(e) => setDescripcion(e.target.value)}
            className="w-full border border-gray-300 rounded-md shadow-sm"
            placeholder="Escribe la descripción"
          />
        </div>

        <button
          type="button"
          onClick={handleGuardarNota}
          className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white px-4 py-2 rounded-md hover:bg-blue-700 mb-4"
        >
          {editIndex !== null ? 'Actualizar Nota' : 'Guardar Nota'}
        </button>
      </div>

      {/* Lista de notas guardadas */}
      <h3 className="text-lg font-semibold mb-2">Notas Guardadas:</h3>
      <ul>
        {notas.map((nota, index) => (
          <li key={index} className="mb-4 p-4 border border-gray-200 rounded-md shadow-sm">
            <div className="flex justify-between items-center mb-2">
              <h4 className="font-semibold text-gray-800">{nota.titulo}</h4>
              <div>
                <button
                  type="button"
                  onClick={() => handleEditarNota(index)}
                  className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white px-2 py-1 rounded-md mr-2 hover:bg-blue-700"
                >
                  Editar
                </button>
                <button
                  type="button"
                  onClick={() => handleEliminarNota(index)}
                  className="bg-red-600 text-white px-2 py-1 rounded-md hover:bg-red-600"
                >
                  X
                </button>
              </div>
            </div>
            <p className="text-gray-700">{nota.descripcion}</p>
          </li>
        ))}
      </ul>

      {/* Botón para enviar todas las notas */}
      <button
        type="submit"
        className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white px-4 py-2 rounded-md hover:bg-blue-700 mt-4"
      >
        Enviar
      </button>
    </form>
  );
};

export default NotasConsulta;
