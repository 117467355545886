import React, { useState, useContext } from 'react';
import { FormContext } from './FormContext';

const ConsentimientoModal = ({ isOpen, onClose, onSave }) => {
    const { formState } = useContext(FormContext);
    const [newConsentimiento, setNewConsentimiento] = useState({
        nombreConsentimiento: '',
        servicioLigado: '',
        consentimiento: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewConsentimiento({
            ...newConsentimiento,
            [name]: value
        });
    };

    const handleSave = () => {
        onSave(newConsentimiento);
        setNewConsentimiento({
            nombreConsentimiento: '',
            servicioLigado: '',
            consentimiento: ''
        });
    };

    if (!isOpen) {
        return null;
    }

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg">
                <h2 className="text-2xl font-bold mb-4">Agregar Consentimiento</h2>
                <div className="grid grid-cols-1 gap-4">
                    <div className="form-group">
                        <label className="block text-gray-700">Nombre del consentimiento</label>
                        <input
                            type="text"
                            name="nombreConsentimiento"
                            value={newConsentimiento.nombreConsentimiento}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border rounded"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label className="block text-gray-700">Servicio ligado</label>
                        <select
                            name="servicioLigado"
                            value={newConsentimiento.servicioLigado}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border rounded"
                            required
                        >
                            <option value="">Seleccione servicio</option>
                            {formState.servicios.map((service, index) => (
                                <option key={index} value={service.service}>{service.service}</option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group">
                        <label className="block text-gray-700">Consentimiento</label>
                        <textarea
                            name="consentimiento"
                            value={newConsentimiento.consentimiento}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border rounded"
                            required
                        ></textarea>
                    </div>
                </div>
                <div className="flex justify-between mt-4">
                    <button
                        className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-700"
                        onClick={onClose}
                    >
                        Cancelar
                    </button>
                    <button
                        className="px-4 py-2 bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white rounded hover:bg-blue-700"
                        onClick={handleSave}
                    >
                        Agregar
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ConsentimientoModal;
