import { format } from "@formkit/tempo";
import { useEffect, useState } from "react";
import { alertaGeneral } from "../../utils/alertas";
import axios from "axios";

const AgendarCita = () => {
    const preInfo = JSON.parse(localStorage.getItem('selectedDate')) || {};

    const [dataCita, setDataCita] = useState({
        doctorId: preInfo.doctorId || '',
        doctor: preInfo.doctor || '',
        servicio: preInfo.servicio || '',
        precioServicio: preInfo.precioServicio || '',
        fecha: preInfo.fecha || '',
        tipoPagador: '',
        tipoConvenio: '',
        motivoConsulta: '',
    });

    useEffect(() => {
        // Quitar del localStorage el item selectedDate al desmontar el componente
        return () => localStorage.removeItem('selectedDate');
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setDataCita({ ...dataCita, [name]: value });
    };

    const onHandleSubmit = (e) => {
        e.preventDefault();
        console.log(dataCita);
        const token = localStorage.getItem('token');
        const patientId = localStorage.getItem('patientId');
        try {
            axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/api/clinic/scheduleAppointment`,
                {
                    patientId: patientId,
                    date: dataCita.fecha,
                    notes: dataCita.motivoConsulta,
                    doctorId: dataCita.doctorId, // Enviando el ID del doctor
                    service: dataCita.servicio.replace(/ /g, '_'), // Enviando el servicio seleccionado
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            ).then((response) => {
                if (response.status === 200) {
                    alertaGeneral({ titulo: 'Cita Agendada', messageHtml: 'La cita ha sido agendada correctamente' });
                    window.location.reload();
                }
            }).catch((error) => {
                alertaGeneral({titulo:'Error', messageHtml:'Ocurrió un error al agendar la cita', isError: true});
            });

        } catch (error) {
            alertaGeneral({titulo:'Error', messageHtml:'Ocurrió un error al agendar la cita', isError: true});
        }

    }

    return (
        <div className="p-6 max-w-md mx-auto bg-white shadow-md rounded-lg">
            <h2 className="text-2xl font-bold mb-4">Agendar Cita</h2>
            <form className="space-y-4" onSubmit={(ev) => onHandleSubmit(ev)}>
                {/* Servicio */}
                <div>
                    <label className="block text-gray-700">Servicio</label>
                    <input
                        type="text"
                        name="servicio"
                        value={dataCita.servicio}
                        readOnly
                        disabled
                        className="capitalize mt-1 w-full p-2 border border-gray-300 rounded-md bg-gray-100 text-gray-700"
                    />
                </div>

                {/* Precio del Servicio */}
                <div>
                    <label className="block text-gray-700">Precio del Servicio</label>
                    <input
                        type="text"
                        name="precioServicio"
                        value={dataCita.precioServicio}
                        readOnly
                        disabled
                        className="mt-1 w-full p-2 border border-gray-300 rounded-md bg-gray-100 text-gray-700"
                    />
                </div>

                {/* Nombre Doctor */}
                <div>
                    <label className="block text-gray-700">Nombre Doctor</label>
                    <input
                        type="text"
                        name="doctor"
                        value={dataCita.doctor}
                        readOnly
                        disabled
                        className="title mt-1 w-full p-2 border border-gray-300 rounded-md bg-gray-100 text-gray-700"
                    />
                </div>

                {/* Fecha Cita */}
                <div>
                    <label className="block text-gray-700">Fecha Cita</label>
                    <input
                        type="datetime"
                        name="fecha"
                        readOnly
                        disabled
                        defaultValue={format(dataCita.fecha, 'YYYY-MM-DD HH:mm a')}
                        className="mt-1 mt-1 w-full p-2 border border-gray-300 rounded-md bg-gray-100 text-gray-700"
                    />
                </div>

                {/* Tipo Pagador */}
                <div>
                    <label className="block text-gray-700">Tipo de Pagador</label>
                    <select
                        name="tipoPagador"
                        value={dataCita.tipoPagador}
                        onChange={handleChange}
                        className="mt-1 w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                        <option value="">Selecciona una opción</option>
                        <option value="particular">Particular</option>
                        <option value="convenio">Convenio</option>
                    </select>
                </div>

                {/* Tipo Convenio (solo aparece si Tipo Pagador es 'convenio') */}
                {dataCita.tipoPagador === 'convenio' && (
                    <div>
                        <label className="block text-gray-700">Tipo de Convenio</label>
                        <select
                            name="tipoConvenio"
                            value={dataCita.tipoConvenio}
                            onChange={handleChange}
                            className="mt-1 w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                            <option value="">Selecciona un tipo</option>
                            <option value="cotizante">Cotizante</option>
                            <option value="beneficiario">Beneficiario</option>
                            <option value="adicional">Adicional</option>
                        </select>
                    </div>
                )}

                {/* Motivo de Consulta */}
                <div>
                    <label className="block text-gray-700">Motivo de Consulta</label>
                    <textarea
                        name="motivoConsulta"
                        value={dataCita.motivoConsulta}
                        onChange={handleChange}
                        rows="3"
                        className="mt-1 w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>

                {/* Botón de Enviar */}
                <button
                    type="submit"
                    className="w-full p-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none"
                >
                    Agendar Cita
                </button>
            </form>
        </div>
    );
};

export default AgendarCita;
