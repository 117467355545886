import React, { useContext, useState, useEffect } from 'react';
import { FormContext } from './FormContext';
import ServiceModal from './ServiceModal';

const Servicios = ({ handleNext, handlePrevious }) => {
    const { formState, updateFormState } = useContext(FormContext);
    const [selectedSede, setSelectedSede] = useState('');
    const [documentosHabilitacion, setDocumentosHabilitacion] = useState(formState.documentosHabilitacion || []);
    const [services, setServices] = useState(formState.servicios || []);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        if (formState.servicios) {
            setServices(formState.servicios);
        } 
    }, [formState.servicios]);

    const handleSedeChange = (e) => {
        const selectedSede = formState.sedes.find(sede => sede.code === e.target.value);
        setSelectedSede(selectedSede);
    };

    const handleAddService = (service) => {
        const updatedServices = [...services, { ...service, sedeCode: selectedSede.code, sedeName: selectedSede.name }];
        setDocumentosHabilitacion([...documentosHabilitacion, service.habilitationDocument]);
        setServices(updatedServices);
        setIsModalOpen(false);
        setSelectedSede(''); // Limpiar la selección de la sede después de agregar el servicio
    };

    const handleRemoveService = (index) => {
        const newServices = [...services];
        const newDocumentosHabilitacion = [...documentosHabilitacion];
        newServices.splice(index, 1);
        newDocumentosHabilitacion.splice(index, 1);
        setServices(newServices);
        setDocumentosHabilitacion(newDocumentosHabilitacion);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        updateFormState('documentosHabilitacion', documentosHabilitacion);
        updateFormState('servicios', services);
        handleNext();
    };

    return (
        <div className="max-w-4xl mx-auto p-4 bg-white shadow-md rounded-lg">
            <h2 className="text-xl font-bold mb-4">Servicios</h2>
            <div className="mb-4">
                <label className="block text-gray-700">Seleccionar Sede</label>
                <select
                    value={selectedSede.code || ''}
                    onChange={handleSedeChange}
                    className="w-full px-3 py-2 border rounded"
                >
                    <option value="">Seleccione una sede</option>
                    {formState.sedes.map((sede, index) => (
                        <option key={index} value={sede.code}>
                            {sede.name} - {sede.departmentName} - {sede.City || sede.mainCity} - {sede.code}
                        </option>
                    ))}
                </select>
            </div>
            {selectedSede && (
                <div className="mb-4">
                    <h3 className="text-lg font-bold mb-2">Nombre de la sede seleccionada</h3>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                        <div><strong>Nombre:</strong> {selectedSede.name}</div>
                        <div><strong>Departamento:</strong> {selectedSede.departmentName}</div>
                        <div><strong>Ciudad:</strong> {selectedSede.City || selectedSede.mainCity}</div>
                        <div><strong>Código:</strong> {selectedSede.code}</div>
                    </div>
                    <button
                        className="mt-2 bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white font-bold py-2 px-4 rounded hover:bg-blue-700"
                        onClick={() => setIsModalOpen(true)}
                    >
                        Agregar Servicio
                    </button>
                </div>
            )}
            {services.length > 0 && (
                <div className="mt-4">
                    <h3 className="text-lg font-bold mb-2">Servicios Agregados</h3>
                    {services.map((service, index) => (
                        <div key={index} className="border p-2 rounded mb-2 flex justify-between items-center">
                            <div>
                                <div><strong>Sede:</strong> {service.sedeName}</div>
                                <div><strong>Grupo:</strong> {service.group}</div>
                                <div><strong>Servicio:</strong> {service.service}</div>
                                <div><strong>Modalidad:</strong> {service.modality}</div>
                                <div><strong>Telemedicina:</strong> {service.telemedicine || 'N/A'}</div>
                                <div><strong>Complejidad:</strong> {service.complexity}</div>
                                <div><strong>Número de habilitación:</strong> {service.habilitationNumber}</div>
                                <div><strong>Valor de la consulta:</strong> {service.consultationValue}</div>
                                <div><strong>Valor de la cuota moderadora:</strong> {service.copayValue}</div>
                                <div><strong>Valor neto a pagar:</strong> {service.netValue}</div>
                            </div>
                            <button
                                className="ml-4 bg-red-500 text-white font-bold py-2 px-4 rounded hover:bg-red-700"
                                onClick={() => handleRemoveService(index)}
                            >
                                X
                            </button>
                        </div>
                    ))}
                </div>
            )}
            <div className="flex justify-between mt-4">
                <button
                    className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-700"
                    onClick={handlePrevious}
                >
                    Anterior
                </button>
                <button
                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
                    onClick={handleSubmit}
                >
                    Siguiente
                </button>
            </div>
            <ServiceModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onSave={handleAddService}
            />
        </div>
    );
};

export default Servicios;
