import React, { useContext, useState, useEffect } from 'react';
import { FormContext } from './FormContext';

const Capacidad = ({ handleNext, handlePrevious }) => {
    const { formState, updateFormState } = useContext(FormContext);
    const [selectedSede, setSelectedSede] = useState('');
    const [capacidad, setCapacidad] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newCapacity, setNewCapacity] = useState({
        group: '',
        concept: '',
        quantity: ''
    });

    useEffect(() => {
        if (formState.capacidad.length > 0) {
            setCapacidad(formState.capacidad);
        }
    }, [formState.capacidad]);

    const handleSedeChange = (e) => {
        const selectedSede = formState.sedes.find(sede => sede.code === e.target.value);
        setSelectedSede(selectedSede);
    };

    const handleCapacityChange = (e) => {
        const { name, value } = e.target;
        setNewCapacity({
            ...newCapacity,
            [name]: value
        });
    };

    const handleAddCapacity = () => {
        const updatedCapacity = [...capacidad, { ...newCapacity, sedeCode: selectedSede.code, sedeName: selectedSede.name }];
        setCapacidad(updatedCapacity);
        setNewCapacity({ group: '', concept: '', quantity: '' });
        setSelectedSede(''); // Resetear la sede seleccionada
        setIsModalOpen(false);
    };

    const handleRemoveCapacity = (index) => {
        const newCapacidad = [...capacidad];
        newCapacidad.splice(index, 1);
        setCapacidad(newCapacidad);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        updateFormState('capacidad', capacidad);
        handleNext();
    };

    const groupOptions = [
        { value: 'ambulancias', label: 'Ambulancias' },
        { value: 'camas', label: 'Camas' },
        { value: 'camillas', label: 'Camillas' },
        { value: 'consultorios', label: 'Consultorios' },
        { value: 'salas', label: 'Salas' },
        { value: 'sillas', label: 'Sillas' },
        { value: 'unidad_movil', label: 'Unidad Móvil' },
    ];

    const conceptOptions = {
        ambulancias: [
            'Básica', 'Especializada'
        ],
        camas: [
            'Pediátrica', 'Adultos', 'Obstetricia', 'Cuidado intermedio neonatal', 'Cuidado intensivo neonatal',
            'Cuidado intermedio pediátrico', 'Cuidado intensivo pediátrico', 'Cuidado intermedio adulto',
            'Cuidado intensivo adulto', 'Unidad de quemados adulto', 'Psiquiatría', 'Farmacodependencia',
            'Unidad de quemados pediátrico', 'Cuidado agudo mental', 'Cuidado intermedio mental', 'Institución paciente crónico',
            'Transplante de progenitores hematopoyeticos', 'Cuidado básico neonatal', 'Salud mental', 'TPR',
            'Salud mental adulto', 'Salud mental pediátrico', 'Paciente crónico con ventilador',
            'Paciente crónico sin ventilador', 'SPA adultos', 'SPA pediátricas', 'SPA básico adultos',
            'SPA básico pediátricos', 'Incubadora intermedia neonatal', 'Intermedia pediátrica',
            'Intermedia adultos', 'Incubadora intensiva neonatal', 'Intensiva pediátrica', 'Intensiva adultos',
            'Incubadora básico neonatal', 'Cuna intensiva neonatal', 'Cuna intensiva pediátrica',
            'Intensiva quemado adulto', 'Cuna básico neonatal', 'Intensiva quemado pediátrica', 'Atención del parto'
        ],
        camillas: [
            'Observación pediátrica', 'Salud mental', 'SPA', 'Otras patologías',
            'Observación adultos hombres', 'Observación adultos mujeres'
        ],
        consultorios: ['Urgencias', 'Consulta externa'],
        salas: ['Quirófano', 'Partos', 'Procedimientos', 'Sala de cirugía', 'Sala de radioterapia'],
        sillas: [
            'Sillas de quimioterapia', 'Sillas de hemodiálisis', 'Salud mental', 'SPA',
            'Otras patologías', 'Ambiente de transición urgencias'
        ],
        unidad_movil: ['Unidad móvil'],
    };

    return (
        <div className="max-w-4xl mx-auto p-4 bg-white shadow-md rounded-lg">
            <h2 className="text-xl font-bold mb-4">Capacidad</h2>
            <div className="mb-4">
                <label className="block text-gray-700">Seleccionar Sede</label>
                <select
                    value={selectedSede.code || ''}
                    onChange={handleSedeChange}
                    className="w-full px-3 py-2 border rounded"
                >
                    <option value="">Seleccione una sede</option>
                    {formState.sedes.map((sede, index) => (
                        <option key={index} value={sede.code}>
                            {sede.name} - {sede.departmentName} - {sede.City || sede.mainCity}
                            - {sede.code}
                        </option>
                    ))}
                </select>
            </div>
            {selectedSede && (
                <div className="mb-4">
                    <h3 className="text-lg font-bold mb-2">Nombre de la sede seleccionada</h3>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                        <div><strong>Nombre:</strong> {selectedSede.name}</div>
                        <div><strong>Departamento:</strong> {selectedSede.departmentName}</div>
                        <div><strong>Ciudad:</strong> {selectedSede.City || selectedSede.mainCity}</div>
                        <div><strong>Código:</strong> {selectedSede.code}</div>
                    </div>
                    <button
                        className="mt-2 bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white font-bold py-2 px-4 rounded hover:bg-blue-700"
                        onClick={() => setIsModalOpen(true)}
                    >
                        Agregar Capacidad
                    </button>
                </div>
            )}
            {capacidad.length > 0 && (
                <div className="mt-4">
                    <h3 className="text-lg font-bold mb-2">Capacidades Agregadas</h3>
                    {capacidad.map((cap, index) => (
                        <div key={index} className="border p-2 rounded mb-2 flex justify-between items-center">
                            <div>
                                <div><strong>Sede:</strong> {cap.sedeName}</div>
                                <div><strong>Grupo:</strong> {cap.group}</div>
                                <div><strong>Concepto:</strong> {cap.concept}</div>
                                <div><strong>Cantidad:</strong> {cap.quantity}</div>
                            </div>
                            <button
                                className="ml-4 bg-red-500 text-white font-bold py-2 px-4 rounded hover:bg-red-700"
                                onClick={() => handleRemoveCapacity(index)}
                            >
                                X
                            </button>
                        </div>
                    ))}
                </div>
            )}
            <div className="flex justify-between mt-4">
                <button
                    className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-700"
                    onClick={handlePrevious}
                >
                    Anterior
                </button>
                <button
                    className="px-4 py-2 bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white rounded hover:bg-blue-700"
                    onClick={handleSubmit}
                >
                    Siguiente
                </button>
            </div>
            {isModalOpen && (
                <div className="modal-overlay fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="modal-content bg-white p-6 rounded-lg shadow-lg w-full max-w-lg">
                        <h2 className="text-2xl font-bold mb-4">Agregar Capacidad</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div className="form-group">
                                <label className="block text-gray-700">Grupo</label>
                                <select
                                    name="group"
                                    value={newCapacity.group}
                                    onChange={handleCapacityChange}
                                    className="w-full px-3 py-2 border rounded"
                                    required
                                >
                                    <option value="">Seleccione Grupo</option>
                                    {groupOptions.map(group => (
                                        <option key={group.value} value={group.value}>{group.label}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group">
                                <label className="block text-gray-700">Concepto</label>
                                <select
                                    name="concept"
                                    value={newCapacity.concept}
                                    onChange={handleCapacityChange}
                                    className="w-full px-3 py-2 border rounded"
                                    required
                                >
                                    <option value="">Seleccione Concepto</option>
                                    {conceptOptions[newCapacity.group]?.map((concept, index) => (
                                        <option key={index} value={concept}>{concept}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group">
                                <label className="block text-gray-700">Cantidad</label>
                                <input
                                    type="number"
                                    name="quantity"
                                    value={newCapacity.quantity}
                                    onChange={handleCapacityChange}
                                    placeholder="Cantidad"
                                    className="w-full px-3 py-2 border rounded"
                                    required
                                />
                            </div>
                        </div>
                        <div className="flex justify-between mt-4">
                            <button
                                className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-700"
                                onClick={() => setIsModalOpen(false)}
                            >
                                Cancelar
                            </button>
                            <button
                                className="px-4 py-2 bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white rounded hover:bg-blue-700"
                                onClick={handleAddCapacity}
                            >
                                Agregar
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Capacidad;
