import React, { useState } from 'react';

const AddressModal = ({ isOpen, onClose, onSave }) => {
    const [addressData, setAddressData] = useState({
        via: '',
        numero: '',
        letra: '',
        bis: false,
        complemento: '',
        cardinalidad: '',
        otroNumero: '',
        otroLetra: '',
        otroComplemento: '',
        otroCardinalidad: ''
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setAddressData({
            ...addressData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const handleSave = () => {
        const addressString = `${addressData.via} ${addressData.numero}${addressData.letra} ${addressData.bis ? 'Bis' : ''} ${addressData.complemento} ${addressData.cardinalidad} ${addressData.otroNumero}${addressData.otroLetra} ${addressData.otroComplemento} ${addressData.otroCardinalidad}`;
        onSave(addressString);
        onClose();
    };

    if (!isOpen) {
        return null;
    }

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
            <div className="bg-white p-4 rounded shadow-lg w-full max-w-lg">
                <h2 className="text-2xl font-bold mb-4">Agregar Dirección</h2>
                <div className="grid grid-cols-2 gap-4">
                    <div className="col-span-2">
                        <label className="block text-gray-700">Vía</label>
                        <select
                            name="via"
                            value={addressData.via}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border rounded"
                            required
                        >
                            <option value="">Seleccione Vía</option>
                            <option value="calle">Calle</option>
                            <option value="carrera">Carrera</option>
                            <option value="avenida">Avenida</option>
                        </select>
                    </div>
                    <div>
                        <label className="block text-gray-700">Número</label>
                        <input
                            type="text"
                            name="numero"
                            value={addressData.numero}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border rounded"
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700">Letra</label>
                        <input
                            type="text"
                            name="letra"
                            value={addressData.letra}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border rounded"
                        />
                    </div>
                    <div className="col-span-2 flex items-center">
                        <label className="block text-gray-700">Bis</label>
                        <input
                            type="checkbox"
                            name="bis"
                            checked={addressData.bis}
                            onChange={handleChange}
                            className="ml-2"
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700">Complemento</label>
                        <input
                            type="text"
                            name="complemento"
                            value={addressData.complemento}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border rounded"
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700">Cardinalidad</label>
                        <input
                            type="text"
                            name="cardinalidad"
                            value={addressData.cardinalidad}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border rounded"
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700">Número</label>
                        <input
                            type="text"
                            name="otroNumero"
                            value={addressData.otroNumero}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border rounded"
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700">Letra</label>
                        <input
                            type="text"
                            name="otroLetra"
                            value={addressData.otroLetra}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border rounded"
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700">Complemento</label>
                        <input
                            type="text"
                            name="otroComplemento"
                            value={addressData.otroComplemento}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border rounded"
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700">Cardinalidad</label>
                        <input
                            type="text"
                            name="otroCardinalidad"
                            value={addressData.otroCardinalidad}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border rounded"
                        />
                    </div>
                </div>
                <div className="flex justify-end space-x-4 mt-4">
                    <button
                        onClick={handleSave}
                        className="px-4 py-2 bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white rounded hover:bg-blue-700"
                    >
                        Agregar
                    </button>
                    <button
                        onClick={onClose}
                        className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700"
                    >
                        Cancelar
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AddressModal;
