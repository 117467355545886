import React, { useState, useContext, useEffect } from 'react';
import { FormContext } from './FormContext';
import AgregarBodega from './AgregarBodega';
import AgregarConsumo from './AgregarConsumo'; // Asegúrate de tener este componente creado
import Swal from 'sweetalert2';

const Insumos = ({ handlePrevious, handleCancel, fetchClinics }) => { // Asegúrate de recibir handlePrevious como prop
    const { formState, updateFormState } = useContext(FormContext);
    const [bodegas, setBodegas] = useState(formState.insumos.inventario || []);
    const [consumos, setConsumos] = useState(formState.insumos.consumos || []);
    const [activeTab, setActiveTab] = useState('inventarioBodega');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingIndex, setEditingIndex] = useState(null);
    const [isConsumoModalOpen, setIsConsumoModalOpen] = useState(false);

    const handleAddBodega = (bodega) => {
        const updatedBodegas = editingIndex !== null
            ? bodegas.map((item, index) => index === editingIndex ? bodega : item)
            : [...bodegas, bodega];
        setBodegas(updatedBodegas);
        setEditingIndex(null);
        setIsModalOpen(false);
    };

    const handleRemoveBodega = (index) => {
        const newBodegas = bodegas.filter((_, i) => i !== index);
        setBodegas(newBodegas);
    };

    const handleEditBodega = (index) => {
        setEditingIndex(index);
        setIsModalOpen(true);
    };

    const handleAddConsumo = (consumo) => {
        setConsumos([...consumos, consumo]);
        setIsConsumoModalOpen(false);
    };

    const handleFinalSubmit = async (e) => {
        e.preventDefault();

        // Consolidar insumos en formState
        const currentInsumos = formState.insumos || {};
        const updatedInsumos = {
            ...currentInsumos,
            inventario: bodegas,
            consumos: consumos
        };
        updateFormState('insumos', updatedInsumos);

        // Preparar los datos para enviar al backend
        const dataToSend = new FormData(); //Debe ser formdata ya que se envia una imagen
        console.log(formState.documentosHabilitacion)
        formState.documentosHabilitacion?.forEach((doc) => {
            dataToSend.append('sede_clinica_documento_habilitacion', doc);
        });
        //El formState.image se envia desde infoGeneral.js
        //Hacemos un .get('logo_clinica') para obtener solo el archivo de la imagen
        dataToSend.append('logo_clinica', formState.logoClinica.get('logo_clinica'));
        dataToSend.append('infoGeneral', JSON.stringify(formState.infoGeneral));
        dataToSend.append('sedes', JSON.stringify(formState.sedes));
        dataToSend.append('capacidad', JSON.stringify(formState.capacidad));
        dataToSend.append('servicios', JSON.stringify(formState.servicios));
        dataToSend.append('formatos', JSON.stringify(formState.formatos));
        dataToSend.append('insumos', JSON.stringify(updatedInsumos));
        //Listo para enviar al backend

        try {
            // Realizar la solicitud HTTP al backend para guardar los datos
            const token = localStorage.getItem('token'); // Assuming token is stored in localStorage
  
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/admin/createclinicas`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                body: dataToSend
            });
            
            if (response.ok) {
                const result = await response.json();
                console.log('Datos guardados correctamente:', result);
                Swal.fire({
                    title: '¡Clínica creada con éxito!',
                    icon: 'success', // Cambiado de 'error' a 'success'
                    customClass: {
                        title: 'swal2-title-custom',
                        htmlContainer: 'swal2-html-container-custom'
                    }
                }).then(() => { // Corregido aquí, ahora es una función
                    handleCancel();
                    fetchClinics();
                });
            }
           
        } catch (error) {
            console.error('Error en la solicitud al backend:', error);
        }
    };

    return (
        <div className="max-w-4xl mx-auto p-4 bg-white shadow-md rounded-lg">
            <div className="mb-4 flex justify-between">
                <button
                    className={`px-4 py-2 rounded-t-lg ${activeTab === 'inventarioBodega' ? 'bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white' : 'bg-gray-200 text-gray-600'}`}
                    onClick={() => setActiveTab('inventarioBodega')}
                >
                    Inventario Bodega
                </button>
               {/* <button
                    className={`px-4 py-2 rounded-t-lg ${activeTab === 'agregarConsumo' ? 'bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white' : 'bg-gray-200 text-gray-600'}`}
                    onClick={() => setActiveTab('agregarConsumo')}
                >
                    Agregar Consumo
                </button>*/}
            </div>

            <div className="bg-white p-4 rounded-lg">
                {activeTab === 'inventarioBodega' && (
                    <div>
                        <h3 className="text-lg font-bold mb-4">Inventario Bodega</h3>
                        <button
                            className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white font-bold py-2 px-4 rounded hover:bg-blue-700"
                            onClick={() => {
                                setEditingIndex(null);
                                setIsModalOpen(true);
                            }}
                        >
                            Agregar Bodega
                        </button>
                        {bodegas.length > 0 && (
                            <div className="mt-4">
                                {bodegas.map((bodega, index) => (
                                    <div key={index} className="border p-2 rounded mb-2 flex items-center justify-between">
                                        <div className="flex-1">
                                            <strong>Nombre de la bodega:</strong> {bodega.nombreBodega}
                                        </div>
                                        <div className="flex-1">
                                            <strong>Insumos:</strong> {bodega.inventario.length}
                                        </div>
                                        <div className="flex space-x-2">
                                            <button
                                                className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white font-bold py-1 px-2 rounded hover:bg-blue-700"
                                                onClick={() => handleEditBodega(index)}
                                            >
                                                Editar
                                            </button>
                                            <button
                                                className="bg-red-500 text-white font-bold py-1 px-2 rounded hover:bg-red-700"
                                                onClick={() => handleRemoveBodega(index)}
                                            >
                                                X
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                )}
                {activeTab === 'agregarConsumo' && (
                    <div>
                        <h3 className="text-lg font-bold mb-4">Agregar Consumo</h3>
                        <button
                            className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white font-bold py-2 px-4 rounded hover:bg-blue-700"
                            onClick={() => setIsConsumoModalOpen(true)}
                        >
                            Agregar Consumo
                        </button>
                    </div>
                )}
            </div>

            <div className="flex justify-between mt-4">
                <button
                    className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-700"
                    onClick={handlePrevious}
                >
                    Anterior
                </button>
                <button
                    className="px-4 py-2 bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white rounded hover:bg-blue-700"
                    onClick={handleFinalSubmit}
                >
                    Crear
                </button>
            </div>

            {isModalOpen && (
                <AgregarBodega
                    handleClose={() => setIsModalOpen(false)}
                    handleAddBodega={handleAddBodega}
                    initialBodega={editingIndex !== null ? bodegas[editingIndex] : null}
                />
            )}

            {isConsumoModalOpen && (
                <AgregarConsumo
                    handleClose={() => setIsConsumoModalOpen(false)}
                    handleAddConsumo={handleAddConsumo}
                />
            )}
        </div>
    );
};

export default Insumos;
