import React, { useState, useEffect } from 'react';

const serviceOptions = {
    'Internación': [
        '105 - Cuidado intermedio neonatal',
        '106 - Cuidado intermedio pediátrico',
        '107 - Cuidado intermedio adultos',
        '108 - Cuidado intensivo neonatal',
        '109 - Cuidado intensivo pediátrico',
        '110 - Cuidado intensivo adultos',
        '120 - Cuidado básico neonatal',
        '129 - Hospitalización adultos',
        '130 - Hospitalización pediátrica',
        '131 - Hospitalización en salud mental',
        '132 - Hospitalización parcial',
        '133 - Hospitalización paciente crónico con ventilador',
        '134 - Hospitalización paciente crónico sin ventilador',
        '135 - Hospitalización en consumo de sustancias psicoactivas',
        '138 - Cuidado básico del consumo de sustancias psicoactivas'
    ],
    'Quirúrgicos': [
        '201 - Cirugía de cabeza y cuello',
        '202 - Cirugía cardiovascular',
        '203 - Cirugía general',
        '204 - Cirugía ginecológica',
        '205 - Cirugía maxilofacial',
        '207 - Cirugía ortopédica',
        '208 - Cirugía oftalmológica',
        '209 - Cirugía otorrinolaringología',
        '210 - Cirugía oncológica',
        '211 - Cirugía oral',
        '212 - Cirugía pediátrica',
        '213 - Cirugía plástica y estética',
        '214 - Cirugía vascular y angiológica',
        '215 - Cirugía urológica',
        '217 - Otras cirugías',
        '218 - Cirugía endovascular neurológica',
        '227 - Cirugía oncológica pediátrica',
        '231 - Cirugía de la mano',
        '232 - Cirugía de mama y tumores tejidos blandos',
        '233 - Cirugía dermatológica',
        '234 - Cirugía de torax',
        '235 - Cirugía gastrointestinal',
        '237 - Cirugía plástica oncológica',
        '245 - Neurocirugía'
    ],
    'Consulta externa': [
        '301 - Anestesia',
        '302 - Cardiología',
        '303 - Cirugía cardiovascular',
        '304 - Cirugía genérica',
        '306 - Cirugía pediátrica',
        '308 - Dermatología',
        '309 - Dolor y cuidados paliativos',
        '310 - Endocrinología',
        '311 - Endodoncia',
        '312 - Enfermería',
        '313 - Estomatología',
        '316 - Gastroenterología',
        '317 - Genética',
        '318 - Geriatría',
        '320 - Ginecobstetricia',
        '321 - Hematología',
        '323 - Infectología',
        '324 - Inmunología',
        '325 - Medicina familiar',
        '326 - Medicina física y del deporte',
        '327 - Medicina física y de rehabilitación',
        '328 - Medicina general',
        '329 - Medicina interna',
        '330 - Nefrología',
        '331 - Neumología',
        '332 - Neurología',
        '333 - Nutrición y dietética',
        '334 - Odontología general',
        '335 - Oftalmología',
        '336 - Oncología clínica',
        '337 - Optometría',
        '338 - Ortodoncia',
        '339 - Ortopedia y/o traumatología',
        '340 - Otorrinolaringología',
        '342 - Pediatría',
        '343 - Periodoncia',
        '344 - Psicología',
        '345 - Psiquiatría',
        '346 - Rehabilitación oncología',
        '347 - Rehabilitación oral',
        '348 - Reumatología',
        '354 - Toxicología',
        '355 - Urología',
        '356 - Otras consultas de especialidad'
    ],
    'Apoyo diagnóstico y complementación terapéutica': [
        '706 - Laboratorio clínico',
        '709 - Quimioterapia',
        '711 - Radioterapia',
        '712 - Toma de muestras de laboratorio clínico',
        '714 - Servicio farmacéutico',
        '715 - Medicina nuclear',
        '717 - Laboratorio citologías cervico - uterinas',
        '728 - Terapia ocupacional',
        '729 - Terapia respiratoria',
        '731 - Laboratorio de histotecnología',
        '733 - Hemodiálisis',
        '734 - Diálisis peritoneal',
        '739 - Fisioterapia',
        '740 - Fonoaudiología y/o terapia del lenguaje',
        '742 - Diagnóstico vascular',
        '743 - Hemodinamia e intervencionismo',
        '744 - Imágenes diagnósticas - Ionizantes',
        '745 - Imágenes diagnósticas - No ionizantes',
        '746 - Gestión pre-transfusional',
        '747 - Patología',
        '748 - Radiología odontológica',
        '749 - Toma de muestras de cuello uterino y ginecológicas'
    ],
    'Atención inmediata': [
        '1101 - Atención del parto',
        '1102 - Urgencias',
        '1103 - Transporte asistencial básico',
        '1104 - Transporte asistencial medicalizado',
        '1105 - Atención prehospitalaria'
    ]
};

const ServiceModal = ({ isOpen, onClose, onSave }) => {
    const [newService, setNewService] = useState({
        group: '',
        service: '',
        modality: '',
        telemedicine: '',
        complexity: '',
        habilitationNumber: '',
        habilitationDocument: '',
        habilitationDocumentName: '',
        consultationValue: '',
        copayValue: '',
        netValue: ''
    });
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewService({
            ...newService,
            [name]: value
        });
    };
    useEffect(() => {
        const consultationValue = parseFloat(newService.consultationValue) || 0;
        const copayValue = parseFloat(newService.copayValue) || 0;
        setNewService({
            ...newService,
            netValue: consultationValue + copayValue
        });
    }, [newService.consultationValue, newService.copayValue]);
    const validateForm = () => {
        let formErrors = {};

        // Validar campos requeridos
        if (!newService.group) formErrors.group = "Este campo es requerido.";
        if (!newService.service) formErrors.service = "Este campo es requerido.";
        if (!newService.modality) formErrors.modality = "Este campo es requerido.";
        if (!newService.complexity) formErrors.complexity = "Este campo es requerido.";
        if (!newService.habilitationNumber) formErrors.habilitationNumber = "Este campo es requerido.";
        if (!newService.consultationValue) formErrors.consultationValue = "Este campo es requerido.";
        if (!newService.copayValue) formErrors.copayValue = "Este campo es requerido.";
        if (!newService.netValue) formErrors.netValue = "Este campo es requerido.";

        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleSave = () => {
        if (validateForm()) {
            onSave(newService);
            setNewService({
                group: '',
                service: '',
                modality: '',
                telemedicine: '',
                complexity: '',
                habilitationNumber: '',
                habilitationDocument: '',
                consultationValue: '',
                copayValue: '',
                netValue: ''
            });
            setErrors({});
        } else {
            console.log("Formulario inválido, complete los campos requeridos.");
        }
    };

    if (!isOpen) {
        return null;
    }

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center overflow-auto">
            <div className="bg-white p-4 rounded-lg shadow-lg w-full max-w-lg md:max-w-2xl mx-2 md:p-6 overflow-y-auto max-h-screen">
                <h2 className="text-2xl font-bold mb-4">Agregar Servicio</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="form-group">
                        <label className="block text-gray-700">Grupo</label>
                        <select
                            name="group"
                            value={newService.group}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border rounded"
                            required
                        >
                            <option value="">Seleccione Grupo</option>
                            {Object.keys(serviceOptions).map((group, index) => (
                                <option key={index} value={group}>{group}</option>
                            ))}
                        </select>
                        {errors.group && <p className="text-red-500 text-sm">{errors.group}</p>}
                    </div>
                    <div className="form-group">
                        <label className="block text-gray-700">Servicio</label>
                        <select
                            name="service"
                            value={newService.service}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border rounded"
                            required
                        >
                            <option value="">Seleccione Servicio</option>
                            {newService.group && serviceOptions[newService.group].map((service, index) => (
                                <option key={index} value={service}>{service}</option>
                            ))}
                        </select>
                        {errors.service && <p className="text-red-500 text-sm">{errors.service}</p>}
                    </div>
                    <div className="form-group">
                        <label className="block text-gray-700">Modalidad</label>
                        <select
                            name="modality"
                            value={newService.modality}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border rounded"
                            required
                        >
                            <option value="">Seleccione Modalidad</option>
                            <option value="Intramural">Intramural</option>
                            <option value="Extramural">Extramural</option>
                            <option value="Telemedicina">Telemedicina</option>
                        </select>
                        {errors.modality && <p className="text-red-500 text-sm">{errors.modality}</p>}
                    </div>
                    {newService.modality === "Telemedicina" && (
                        <div className="form-group">
                            <label className="block text-gray-700">Telemedicina</label>
                            <select
                                name="telemedicine"
                                value={newService.telemedicine}
                                onChange={handleChange}
                                className="w-full px-3 py-2 border rounded"
                                required
                            >
                                <option value="">Seleccione Telemedicina</option>
                                <option value="Prestador de referencia">Prestador de referencia</option>
                                <option value="Prestador remisor">Prestador remisor</option>
                            </select>
                            {errors.telemedicine && <p className="text-red-500 text-sm">{errors.telemedicine}</p>}
                        </div>
                    )}
                    <div className="form-group">
                        <label className="block text-gray-700">Complejidad</label>
                        <select
                            name="complexity"
                            value={newService.complexity}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border rounded"
                            required
                        >
                            <option value="">Seleccione Complejidad</option>
                            <option value="Baja">Baja</option>
                            <option value="Media">Media</option>
                            <option value="Alta">Alta</option>
                        </select>
                        {errors.complexity && <p className="text-red-500 text-sm">{errors.complexity}</p>}
                    </div>
                    <div className="form-group">
                        <label className="block text-gray-700">Número de habilitación</label>
                        <input
                            type="text"
                            name="habilitationNumber"
                            value={newService.habilitationNumber}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border rounded"
                            required
                        />
                        {errors.habilitationNumber && <p className="text-red-500 text-sm">{errors.habilitationNumber}</p>}
                    </div> 
                    <div className="form-group">
                        <label className="block text-gray-700">Documento habilitación</label>
                        <input
                            type="file"
                            name="habilitationDocument"
                            onChange={(e) => { newService.habilitationDocumentName = e.target.files[0].name; setNewService({ ...newService, habilitationDocument: e.target.files[0] }) }}
                            className="w-full px-3 py-2 border rounded"
                        />

                    </div>
                    <div className="form-group">
                        <label className="block text-gray-700">Valor de la consulta</label>
                        <input
                            type="number"
                            name="consultationValue"
                            value={newService.consultationValue}
                            onChange={handleChange}
                            placeholder="Ej. 50.000"
                            className={`w-full px-3 py-2 border rounded ${errors.consultationValue && "border-red-500"}`}
                            required
                        />
                    </div>
                    {errors.consultationValue && <p className="text-red-500 text-sm">{errors.consultationValue}</p>}

                    <div className="form-group">
                        <label className="block text-gray-700">Valor de la cuota moderadora</label>
                        <input
                            type="number"
                            name="copayValue"
                            value={newService.copayValue}
                            onChange={handleChange}
                            placeholder="Ej. 50.000"
                            className={`w-full px-3 py-2 border rounded ${errors.copayValue && "border-red-500"}`}
                            required
                        />
                        {errors.netValue && <p className="text-red-500 text-sm">{errors.netValue}</p>}

                    </div>
                    <div className="form-group">
                        <label className="block text-gray-700">Valor neto a pagar</label>
                        <input
                            type="number"
                            name="netValue"
                            value={newService.netValue}
                            readOnly
                            className="w-full px-3 py-2 border rounded bg-gray-100"
                        />
                        {errors.netValue && <p className="text-red-500 text-sm">{errors.netValue}</p>}
                    </div>
                </div>
                <div className="flex justify-between mt-4">
                    <button
                        className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-700"
                        onClick={onClose}
                    >
                        Cancelar
                    </button>
                    <button
                        className="px-4 py-2 bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white rounded hover:bg-blue-700"
                        onClick={handleSave}
                    >
                        Agregar
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ServiceModal;
