import React, { useState, useEffect } from 'react';
import PatientDoctorDocumentsTable from '../DoctorComponent/PatientDoctorDocumentsTable';

const PatientAssignedDocuments = () => {
    const [patientId, setPatientId] = useState(localStorage.getItem('patientId'));

    return (
        <div className="flex justify-center items-center m-0">
            <div className="w-full max-w-screen-lg">
                <PatientDoctorDocumentsTable patientId={patientId} isDownload={true} />
            </div>
        </div>
    )
};

export default PatientAssignedDocuments;