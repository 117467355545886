import { useState } from "react";
import DoctorCalendar from "./ModalDoctorCalendar";

const DoctorCard = ({ doctor, setActiveComponent }) => {
    const [showModalCalendar, setShowModalCalendar] = useState(false);

    return (
        <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-4 last:mb-0">
            <div className="p-4 flex flex-col sm:flex-row items-start h-full">
                <div className="w-full sm:w-auto flex items-center mb-4 sm:mb-0">
                    <div className="w-20 h-20 rounded-full bg-gray-200 mr-4 flex-shrink-0 overflow-hidden">
                        <img
                            src={doctor.urlProfessionalPhoto}
                            alt={`Foto de ${doctor.fullName}`}
                            className="w-full h-full rounded-full object-cover"
                        />
                    </div>
                    <div className="flex-grow sm:hidden">
                        <h2 className="text-xl font-bold text-gray-800">{doctor.fullName}</h2>
                        <p className="capitalize text-gray-600 text-wrap">{doctor.service}</p>
                    </div>
                </div>
                <div className="flex-grow hidden sm:block">
                    <h2 className="text-xl font-bold text-gray-800">{doctor.fullName}</h2>
                    <p className="capitalize text-gray-600 text-wrap">{doctor.service}</p>
                    <p className="text-gray-900 font-semibold mt-2">Precio: ${doctor.priceService}</p>
                </div>
                <div className="w-full sm:w-auto flex justify-between items-center mt-4 sm:mt-0">
                    <p className="text-gray-900 font-semibold sm:hidden">Precio: ${doctor.priceService}</p>
                    <button
                        className="bg-blue-500 text-white font-bold py-2 px-4 rounded-full hover:bg-blue-700 transition duration-300 ease-in-out flex items-center"
                        onClick={() => setShowModalCalendar(!showModalCalendar)}
                    >
                        <i className="fas fa-calendar-days mr-2" /> Horarios
                    </button>
                </div>
            </div>
            {showModalCalendar && (
                <div className="p-3">
                 {/* <div className="fixed bg-black bg-opacity-90 flex inset-0 justify-center items-center z-5">
                     <div className="absolute top-4 left-4 right-4 flex justify-between items-center text-white">
                         
                         <h2 className="text-xl"><b>Horario Doctor:</b> {doctor.fullName}</h2>
                         
                         <button className="bg-red-500 rounded-full px-4 py-1<<<" onClick={() => setShowModalCalendar(false)}>
                             <i className="fas fa-times" />
                         </button>
                     </div> */}

                    {/* <div className="bg-white p-3 w-2/4"> */}
                        <DoctorCalendar doctor={doctor} show={showModalCalendar} setShow={setShowModalCalendar} setActiveComponent={setActiveComponent} />
                    {/* </div> */}
                </div>
            )}
        </div>
    )
}

export default DoctorCard;