import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import { alertaCargandoProceso } from '../../utils/alertas';

const TableDocuments = ({ lstDocuments = [], filesToEdit = {}, setFilesToEdit = () => { } }) => {
    /* Estructura de lstDocuments: 
    nameToSave: nombre del input para guardar el archivo
    isEdit: si el archivo se puede editar
    lstDocuments = [
        { nameToSave: 'nombre_input1', name: 'Documento 1, url: 'http://url1.com', isEdit: true },
        { nameToSave: 'nombre_input2', name: 'Documento 2, url: 'http://url2.com', isEdit: true },
        { nameToSave: 'nombre_input3', name: 'Documento 3, url: 'http://url3.com', isEdit: true },
    ] */

    const [isUrlExists, setIsUrlExists] = useState(false);

    useEffect(() => {
        /*Este useEffect es para cuando se le entregue la funcion setFilesToEdit, se limpie de nuevo
        pues si le entregan esta funcion de nuevo, quiere decir que se renderizo nuevamente este componente*/
        return () => {
            setFilesToEdit([]);
        }
    }, [setFilesToEdit])

    useEffect(() => {
        if (lstDocuments == null || lstDocuments?.length == 0) return;
        const isSomeUrl = lstDocuments.some(file => file.url); //Verificamos si hay al menos un archivo con url
        setIsUrlExists(isSomeUrl);
    }, [lstDocuments])

    const [isUpdateFile, setIsUpdateFile] = useState({});

    const onHandleClickView = (url) => {
        window.open(url, '_blank');
    }


    const onHandleEdit = (ev) => {
        /*
            La variable isUpdateFile es para saber si se ha seleccionado un archivo para actualizar
            y posteriormente mostrar el mensaje de 'Archivo a actualizar' y un icono de check
            La variable filesToEdit es para almacenar el archivo seleccionado
        */
        if (ev.target.files.length === 0) {
            console.log("No llegaron archivos");
            setIsUpdateFile({ ...isUpdateFile, [ev.target.name]: false });
            setFilesToEdit({ ...filesToEdit, [ev.target.name]: null });
            return;
        }
        setIsUpdateFile({ ...isUpdateFile, [ev.target.name]: true });
        setFilesToEdit({ ...filesToEdit, [ev.target.name]: ev.target.files[0] });
    }

    return (
        <div className="mt-4 overflow-hidden justify-center items-center text-center">
            <h1 className="text-center mb-4"> <b> {isUrlExists ? 'DOCUMENTOS ALMACENADOS' : 'NO HAY DOCUMENTOS PARA MOSTRAR'} </b> </h1>
            {isUrlExists &&
                <div className="overflow-x-auto max-w-full">
                    <div className="inline-block min-w-full">
                        <table className="min-w-full divide-y border border-gray-300 divide-gray-300 border-collapse rounden-lg">
                            <thead className="bg-gray-200 text-center">
                                <tr>
                                    <th scope="col" className="px-6 py-3 text-sm font-medium text-gray-900 uppercase tracking-wider">
                                        Nombre del Documento
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-sm font-medium text-gray-900 uppercase tracking-wider">
                                        Acciones
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-300">
                                {
                                    lstDocuments.filter(f => f.url != '').map((file, index) => (

                                        <tr key={index} className="divide-x divide-darkgray-200">
                                            <td className="px-4 py-2">
                                                <p className="text-sm text-wrap text-center font-medium text-gray-500">{file.name}</p>
                                                {isUpdateFile[file.nameToSave] && (
                                                    <div className="flex text-center justify-center items-center">
                                                        <p className="text-xs text-gray-400">Archivo a actualizar</p>
                                                        <i className="fas fa-check text-green-500"></i>
                                                    </div>
                                                )}
                                            </td>
                                            <td className="px-4 py-2">
                                                <div className="flex flex-col md:flex-row items-center justify-center space-x-0 md:space-x-2 space-y-2 md:space-y-0">
                                                    <button className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 hover:bg-blue-700 text-white font-bold py-1 px-2 w-full md:w-auto rounded" onClick={() => onHandleClickView(file?.url)} > Ver </button>
                                                    <button className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-1 px-2 w-full md:w-auto rounded" onClick={() => onHandleClickDownload(file.url, file.name)}> Descargar </button>
                                                    {file.isEdit && (
                                                        <label className="bg-yellow-600 hover:bg-yellow-700 text-center text-white font-bold py-1 px-2 w-full md:w-auto rounded">
                                                            Editar
                                                            <input name={file.nameToSave} type="file" accept=".png, .jpg, .jpeg, .pdf" hidden onChange={(ev) => onHandleEdit(ev)} />
                                                        </label>
                                                    )}
                                                </div>
                                            </td>
                                        </tr>

                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            }
        </div>
    )
}

export const onHandleClickDownload = async (fileUrl, fileName) => {
    const token = localStorage.getItem('token');
    fileName = fileName.replace(/ /g, '_'); //Si llega con espacios, loj quitamoj
    alertaCargandoProceso({
        titulo: 'Descargando archivo',
        messageHtml: 'Espere un momento por favor...',
        funcionAsync: async () => {
            try {
                const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/files/send-files`,
                    { url: fileUrl },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                        responseType: 'arraybuffer'
                    }
                );

                const url = window.URL.createObjectURL(new Blob([res.data], { type: res.headers['content-type'] }));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();

                Swal.close();
                Swal.fire({
                    icon: 'success',
                    title: 'Descarga completada',
                    text: 'El archivo se ha descargado correctamente.',
                });
            } catch (error) {
                console.error('Error al descargar el archivo:', error);
                Swal.close();
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Hubo un problema al descargar el archivo. Por favor, inténtalo de nuevo.',
                });
            }
        }
    });
}


export default TableDocuments;