import React, { useState, useContext } from 'react';
import { FormContext } from './FormContext';

const HistoriaModal = ({ isOpen, onClose, onSave }) => {
    const { formState } = useContext(FormContext);
    const [newHistoria, setNewHistoria] = useState({
        nombreHC: '',
        servicioLigado: '',
        historiaClinica: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewHistoria({
            ...newHistoria,
            [name]: value
        });
    };

    const handleSave = () => {
        onSave(newHistoria);
        setNewHistoria({
            nombreHC: '',
            servicioLigado: '',
            historiaClinica: ''
        });
    };

    if (!isOpen) {
        return null;
    }

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-2xl">
                <h2 className="text-2xl font-bold mb-4">Agregar Historia Clínica</h2>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div className="form-group">
                        <label className="block text-gray-700">Nombre de la H.C</label>
                        <input
                            type="text"
                            name="nombreHC"
                            value={newHistoria.nombreHC}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border rounded"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label className="block text-gray-700">Servicio ligado</label>
                        <select
                            name="servicioLigado"
                            value={newHistoria.servicioLigado}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border rounded"
                            required
                        >
                            <option value="">Seleccione servicio</option>
                            {formState.servicios.map((service, index) => (
                                <option key={index} value={service.service}>{service.service}</option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group">
                        <label className="block text-gray-700">Historia clínica</label>
                        <select
                            name="historiaClinica"
                            value={newHistoria.historiaClinica}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border rounded"
                            required
                        >
                            <option value="">Seleccione H.C de Jotform</option>
                            <option value="HC1">HC1</option>
                            <option value="HC2">HC2</option>
                        </select>
                    </div>
                </div>
                <div className="flex justify-between mt-4">
                    <button
                        className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-700"
                        onClick={onClose}
                    >
                        Cancelar
                    </button>
                    <button
                        className="px-4 py-2 bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white rounded hover:bg-blue-700"
                        onClick={handleSave}
                    >
                        Agregar
                    </button>
                </div>
            </div>
        </div>
    );
};

export default HistoriaModal;
