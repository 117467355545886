import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import AddressModal from './AddressModal';
import { FormContext } from './FormContext';

const InfoGeneral = ({ handleNext, handlePrevious }) => {
    const { formState, updateFormState } = useContext(FormContext);
    const [formData, setFormData] = useState(formState.infoGeneral || {
        providerName: '',
        providerCode: '',
        identificationType: 'CC',
        identification: '',
        providerClass: '',
        legalNature: '',
        stateSocialCompany: '',
        attentionLevel: '',
        territorialCharacter: '',
        mainDepartment: '',
        mainMunicipality: '',
        mainHeadquartersCode: '',
        phone: '',
        email: '',
        address: '',
        country: ''
    });
    
    const [imageLogoClinica, setImageLogoClinica] = useState(null); //Variable para guardar la imagen
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [departments, setDepartments] = useState([]);
    const [cities, setCities] = useState([]);
    useEffect(() => {
        // Fetch departments from the backend
        const fetchDepartments = async () => {
            try {
                const token = localStorage.getItem('token'); // Assuming token is stored in localStorage
    
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/clinic/departments`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setDepartments(response.data);
            } catch (error) {
                console.error('Error fetching departments:', error);
            }
        };
    
        fetchDepartments();
    }, []);
    
    useEffect(() => {
        // Fetch cities when department changes
        const fetchCities = async () => {
            if (formData.mainDepartment) {
                try {
                    const token = localStorage.getItem('token'); // Assuming token is stored in localStorage

                    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/clinic/cities/${formData.mainDepartment}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setCities(response.data);
                } catch (error) {
                    console.error('Error fetching cities:', error);
                }
            }
        };
    
        fetchCities();
    }, [formData.mainDepartment]);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleAddressSave = (address) => {
        setFormData({
            ...formData,
            address: address
        });
    }; 

    const onSubmit = (e) => {
        e.preventDefault();
        const image_LogoClinica = new FormData(); //Creamos formData para guardar la imagen
        image_LogoClinica.append('logo_clinica', imageLogoClinica);//Guardamos la imagen en un formData llamado 'logo_clinica'
        updateFormState('logoClinica', image_LogoClinica);//Enviamos la imagen de una vez al formContext
        updateFormState('infoGeneral', formData);
        handleNext();
    };

    return (
        <div className="max-w-4xl mx-auto p-4 bg-white shadow-md rounded-lg">
            <form onSubmit={onSubmit}>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="form-group">
                        <label className="block text-gray-700">Nombre del prestador</label>
                        <input
                            type="text"
                            name="providerName"
                            value={formData.providerName}
                            onChange={handleChange}
                            placeholder="Nombre del prestador"
                            className="w-full px-3 py-2 border rounded"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label className="block text-gray-700">Código del prestador</label>
                        <input
                            type="text"
                            name="providerCode"
                            value={formData.providerCode}
                            onChange={handleChange}
                            placeholder="Código del prestador"
                            className="w-full px-3 py-2 border rounded"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label className="block text-gray-700">Tipo de Identificación</label>
                        <select
                            name="identificationType"
                            value={formData.identificationType}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border rounded"
                            required
                        >
                            <option value="CC">CC</option>
                            <option value="NIT">NIT</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label className="block text-gray-700">Identificación</label>
                        <input
                            type="text"
                            name="identification"
                            value={formData.identification}
                            onChange={handleChange}
                            placeholder="Identificación"
                            className="w-full px-3 py-2 border rounded"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label className="block text-gray-700">Clase de prestador</label>
                        <select
                            name="providerClass"
                            value={formData.providerClass}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border rounded"
                            required
                        >
                            <option value="">Seleccione Clase</option>
                            <option value="Instituciones prestadoras de servicios de salud - IPS">Instituciones prestadoras de servicios de salud - IPS</option>
                            <option value="Profesional independiente">Profesional independiente</option>
                            <option value="Transporte especial de pacientes">Transporte especial de pacientes</option>
                            <option value="Objeto social diferente a la prestación de servicios de salud">Objeto social diferente a la prestación de servicios de salud</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label className="block text-gray-700">Naturaleza jurídica</label>
                        <select
                            name="legalNature"
                            value={formData.legalNature}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border rounded"
                            required
                        >
                            <option value="">Seleccione Naturaleza</option>
                            <option value="Privada">Privada</option>
                            <option value="Mixta">Mixta</option>
                            <option value="Pública">Pública</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label className="block text-gray-700">Empresa social del estado</label>
                        <div className="flex items-center">
                            <label className="mr-4">
                                <input
                                    type="radio"
                                    name="stateSocialCompany"
                                    value="yes"
                                    checked={formData.stateSocialCompany === 'yes'}
                                    onChange={handleChange}
                                    className="mr-2"
                                />
                                Sí
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="stateSocialCompany"
                                    value="no"
                                    checked={formData.stateSocialCompany === 'no'}
                                    onChange={handleChange}
                                    className="mr-2"
                                />
                                No
                            </label>
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="block text-gray-700">Nivel de atención</label>
                        <select
                            name="attentionLevel"
                            value={formData.attentionLevel}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border rounded"
                            required
                        >
                            <option value="">Seleccione Nivel</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label className="block text-gray-700">Carácter territorial</label>
                        <select
                            name="territorialCharacter"
                            value={formData.territorialCharacter}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border rounded"
                            required
                        >
                            <option value="">Seleccione Carácter</option>
                            <option value="Nacional">Nacional</option>
                            <option value="Departamental">Departamental</option>
                            <option value="Distrital">Distrital</option>
                            <option value="Municipal">Municipal</option>
                            <option value="Indígena">Indígena</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label className="block text-gray-700">País sede principal</label>
                        <select
                            name="country"
                            value={formData.country}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border rounded"
                            required
                        >
                            <option value="Colombia">Colombia</option>
                            {/* Agrega otras opciones si es necesario */}
                        </select>
                    </div>
                    <div className="form-group">
                <label className="block text-gray-700">Departamento sede principal</label>
                <select
                    name="mainDepartment"
                    value={formData.mainDepartment}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border rounded"
                    required
                >
                    <option value="">Seleccione Departamento</option>
                    {departments.map(department => (
                        <option key={department._id} value={department._id}>
                            {department.departamento}
                        </option>
                    ))}
                </select>
            </div>

            <div className="form-group">
                <label className="block text-gray-700">Ciudad sede principal</label>
                <select
                    name="mainCity"
                    value={formData.mainCity}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border rounded"
                    required
                >
                    <option value="">Seleccione Ciudad</option>
                    {cities.length > 0 ? (
                        cities.map((city, index) => (
                            <option key={index} value={city}>
                                {city}
                            </option>
                        ))
                    ) : (
                        <option value="">No hay ciudades disponibles</option>
                    )}
                </select>
            </div>
                    <div className="form-group">
                        <label className="block text-gray-700">Código sede principal</label>
                        <input
                            type="text"
                            name="mainHeadquartersCode"
                            value={formData.mainHeadquartersCode}
                            onChange={handleChange}
                            placeholder="Código sede principal"
                            className="w-full px-3 py-2 border rounded"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label className="block text-gray-700">Teléfono</label>
                        <input
                            type="text"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            placeholder="Teléfono"
                            className="w-full px-3 py-2 border rounded"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label className="block text-gray-700">Correo electrónico</label>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder="Correo electrónico"
                            className="w-full px-3 py-2 border rounded"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label className="block text-gray-700">Dirección</label>
                        <div className="relative">
                            <input
                                type="text"
                                name="address"
                                value={formData.address}
                                onChange={handleChange}
                                placeholder="Dirección"
                                className="w-full px-3 py-2 border rounded bg-gray-100 cursor-not-allowed"
                                readOnly
                            />
                            <button
                                type="button"
                                onClick={() => setIsModalOpen(true)}
                                className="absolute right-2 top-2 px-3 py-1 bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white rounded hover:bg-blue-700"
                            >
                                Agregar
                            </button>
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="block text-gray-700">Imagen adjunta</label>
                        <input
                            type="file"
                            name="logo_clinica"
                            accept="image/jpg, image/jpeg, image/png"
                            onChange={(e) => setImageLogoClinica(e.target.files[0])}
                            required
                            className="block w-full text-gray-700"
                        />
                    </div>
                </div>
                <div className="flex justify-between mt-4">
                    <button
                        type="submit"
                        className="px-4 py-2 bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white rounded hover:bg-blue-700"
                    >
                        Siguiente
                    </button>
                </div>
            </form>
            <AddressModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onSave={handleAddressSave}
            />
        </div>
    );
};

export default InfoGeneral;
