import React, { useEffect, useState } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import { FaDownload, FaFileExcel } from 'react-icons/fa'; // Importando íconos
import logo from '../../images/Logo_Mozart_color.png';

const Reportes = () => {
    const [patients, setPatients] = useState([]);

    // Función para obtener los pacientes
    const fetchPatients = async () => {
        try {
            const clinicId = localStorage.getItem('clinicId');
            const token = localStorage.getItem('token');

            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/clinic/getPatients/${clinicId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            setPatients(response.data);
        } catch (error) {
            console.error('Error fetching patients:', error);
        }
    };

    useEffect(() => {
        fetchPatients();
    }, []);

    // Función para descargar el Excel
    const handleDownloadExcel = async () => {
        await fetchPatients();

        // Encabezado del Excel con el logo (Insertar como imagen en la celda)
        const sheet = XLSX.utils.aoa_to_sheet([
            [''], // Celda vacía
            ['Pacientes del sistema'], // Título del reporte
            ['Fecha de descarga:', new Date().toLocaleDateString(), 'Hora de descarga:', new Date().toLocaleTimeString()], // Fecha y hora actual
            [''], // Espacio vacío
            [
                'Nombre', 'Segundo Nombre', 'Primer Apellido', 'Segundo Apellido', 'Fecha de Nacimiento',
                'País de Nacimiento', 'País de Residencia', 'Ciudad', 'Teléfono', 'Correo', 'Género',
                'Número de ID', 'Tipo de ID', 'Seguro Médico', 'Nombre del Seguro', 'Teléfono del Cuidador'
            ], // Encabezado de columnas
        ]);

        // Agregar los datos de los pacientes
        patients.forEach((patient) => {
            XLSX.utils.sheet_add_aoa(sheet, [[
                patient.firstName,
                patient.secondName || '',
                patient.firstLastName,
                patient.secondLastName || '',
                patient.dob ? new Date(patient.dob).toLocaleDateString() : '',
                patient.birthCountry || '',
                patient.residenceCountry || '',
                patient.city || '',
                patient.cellNumber || '',
                patient.email || '',
                patient.gender || '',
                patient.idNumber || '',
                patient.idType || '',
                patient.hasMedicalInsurance || '',
                patient.insuranceName || '',
                patient.carerIdNumber || ''
            ]], { origin: -1 });
        });

        // Ajustar el ancho de las columnas
        const wscols = [
            { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 15 },
            { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 15 }, { wch: 25 },
            { wch: 10 }, { wch: 20 }, { wch: 15 }, { wch: 15 }, { wch: 25 }, { wch: 25 },
        ];
        sheet['!cols'] = wscols;

        // Centrar y estilizar el encabezado
        const range = XLSX.utils.decode_range(sheet['!ref']);
        for (let C = range.s.c; C <= range.e.c; C++) {
            const headerCell = sheet[XLSX.utils.encode_cell({ r: 4, c: C })]; // Encabezado en la fila 5
            if (headerCell) {
                headerCell.s = {
                    alignment: { horizontal: 'center', vertical: 'center' },
                    fill: { fgColor: { rgb: 'D9EAD3' } },
                    font: { bold: true },
                };
            }
        }

        // Crear el libro y la hoja
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, sheet, 'Pacientes');

        // Descargar el archivo Excel
        XLSX.writeFile(wb, `Pacientes_${new Date().toLocaleDateString()}.xlsx`);
    };

    return (
        <div className="reportes-container flex flex-col items-center p-8">
            <h1 className="text-3xl font-bold mb-8 mt-20">Reportes</h1>
            <div className="grid grid-cols-3 gap-8 ">
                <button 
                    onClick={handleDownloadExcel} 
                    className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 hover:bg-blue-700 text-white py-4 px-6 rounded-lg shadow-lg flex flex-col items-center justify-center space-y-2 transition duration-200"
                >
                    <FaFileExcel className="text-2xl" />
                    <span>Pacientes del sistema</span>
                </button>
                <button className="bg-gray-400 hover:bg-gray-500 text-white py-4 px-6 rounded-lg shadow-lg flex flex-col items-center justify-center space-y-2 transition duration-200">
                    <FaDownload className="text-2xl" />
                    <span>Reporte 2</span>
                </button>
                <button className="bg-gray-400 hover:bg-gray-500 text-white py-4 px-6 rounded-lg shadow-lg flex flex-col items-center justify-center space-y-2 transition duration-200">
                    <FaDownload className="text-2xl" />
                    <span>Reporte 3</span>
                </button>
                <button className="bg-gray-400 hover:bg-gray-500 text-white py-4 px-6 rounded-lg shadow-lg flex flex-col items-center justify-center space-y-2 transition duration-200">
                    <FaDownload className="text-2xl" />
                    <span>Reporte 4</span>
                </button>
                <button className="bg-gray-400 hover:bg-gray-500 text-white py-4 px-6 rounded-lg shadow-lg flex flex-col items-center justify-center space-y-2 transition duration-200">
                    <FaDownload className="text-2xl" />
                    <span>Reporte 5</span>
                </button>
                <button className="bg-gray-400 hover:bg-gray-500 text-white py-4 px-6 rounded-lg shadow-lg flex flex-col items-center justify-center space-y-2 transition duration-200">
                    <FaDownload className="text-2xl" />
                    <span>Reporte 6</span>
                </button>
            </div>
        </div>
    );
};

export default Reportes;
