import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Modal from 'react-modal';
import logo from '../../images/Logo_Mozart_color.png';
import Swal from 'sweetalert2'
import PatientComponent from './Patient'; // Asegúrate de importar el componente correcto
import ClinicProfile from './ClinicProfile'; // Asegúrate de importar el componente correcto
import AdminComponent from './Admin'; // Asegúrate de importar el componente correcto
import DoctorComponent from './Doctor'; // Asegúrate de importar el componente correcto
import Grafic from './Grafics'; // Asegúrate de importar el componente correcto
import Report from './Report'
import '../../App.css';
import '../../styles/Clinic.css';
import CLinicLogs from './ClinicLogs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTachometerAlt, faLock, faSignOutAlt, faFileAlt, faUsersCog, faUserMd, faProcedures, faUserCircle } from '@fortawesome/free-solid-svg-icons';

const Header = ({ setActiveComponent }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [clinicName, setClinicName] = useState('');
  const [logoClinic, setLogoClinic] = useState('');
  const [adminName, setAdminName] = useState('');
  const [role, setRole] = useState('');
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [emailAdmin, setEmailAdmin] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [activeView, setActiveView] = useState('dashboard');


  const navigate = useNavigate();

  useEffect(() => {
    const storedClinicName = localStorage.getItem('clinicName');
    const storedAdminName = localStorage.getItem('adminName');
    const storedEmail = localStorage.getItem('clinicEmail');
    const storedEmailAdmin = localStorage.getItem('adminEmail');
    const storedLogoClinic = localStorage.getItem('imageProfile');
    const userRole = localStorage.getItem('role');

    if (storedClinicName) {
      setClinicName(storedClinicName);
    }
    if (storedAdminName) {
      setAdminName(storedAdminName);
    }
    if (storedLogoClinic) {
      setLogoClinic(storedLogoClinic);
    }
    if (userRole) {
      setRole(userRole);
    }
    if (storedEmail) {
      setEmail(storedEmail);
    }
    if (storedEmailAdmin) {
      setEmailAdmin(storedEmailAdmin);
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('clinicName');
    localStorage.removeItem('clinicEmail');
    localStorage.removeItem('clinicId');
    localStorage.removeItem('imageProfile');
    localStorage.removeItem('patientName');
    localStorage.removeItem('patientEmail');
    localStorage.removeItem('patientId');
    localStorage.removeItem('doctorName');
    localStorage.removeItem('doctorEmail');
    localStorage.removeItem('doctorId');
    localStorage.removeItem('adminName');
    localStorage.removeItem('adminEmail');
    localStorage.removeItem('adminId');
    navigate('/');
  };
 
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const openChangePasswordModal = () => {
    setIsChangePasswordModalOpen(true);
  };

  const closeChangePasswordModal = () => {
    setIsChangePasswordModalOpen(false);
  };

  const handleChangePassword = async () => {
    try {
      const username = role === 'clinic' ? email : emailAdmin;
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/clinic/changePassword`, {
        username: username,
        currentPassword: currentPassword,
        newPassword: newPassword
      }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (response.status === 200) {
        Swal.fire({
          title: 'Contraseña cambiada con exito!.',
          icon: 'success',
          customClass: {
            title: 'swal2-title-custom',
            htmlContainer: 'swal2-html-container-custom'
          }
        });

        closeChangePasswordModal();
      } else {
        Swal.fire({
          title: 'Contraseña actual incorrecta.',
          icon: 'error',
          customClass: {
            title: 'swal2-title-custom',
            htmlContainer: 'swal2-html-container-custom'
          }
        });
      }

    } catch (error) {
      Swal.fire({
        title: 'Contraseña actual incorrecta.',
        icon: 'error',
        customClass: {
          title: 'swal2-title-custom',
          htmlContainer: 'swal2-html-container-custom'
        }
      });
    }
  };

  const renderView = () => {
    switch (activeView) {
      case 'dashboard':
        return <Grafic />;
      case 'patient':
        return <PatientComponent />;
      case 'perfil':
        return <ClinicProfile />;
      case 'admin':
        return <AdminComponent />;
      case 'doctor':
        return <DoctorComponent />;
      case 'report':
        return <Report />;
      case 'logs':
        return <CLinicLogs />;
    }
  };

  return (
    <div>

<header className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-black p-4 flex flex-col md:flex-row justify-between items-center shadow-md">
        <div className="container-header-navbar-clinic flex items-center cursor-pointer">
          <img src={logo} onClick={() => setActiveView('dashboard')} alt="Logo" className="h-14 mr-2 shadow-lg border-2 border-gray-800 bg-white" />
          {role === 'clinic' && clinicName && (
            <h1 className="text-lg text-white font-bold ml-2">{`Bienvenido/a ${clinicName}`}</h1>
          )}
          {role === 'clinicAdmin' && adminName && (
            <h1 className="text-lg text-white font-bold ml-4">{`Bienvenido/a ${adminName}`}</h1>
          )}

        </div> 

        {/* Menú desplegable para pantallas grandes */}
        <nav className="hidden md:flex flex-col md:flex-row md:items-center space-x-4 relative">
          {logoClinic != '' && (
            <div className="container-image-profile-clinic flex flex-col md:flex-row items-center" >
              <img src={logoClinic} alt="Logo" width="150" height="150" className="img-profile mr-4 " />
            </div>
          )}

          <button
            className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white font-bold hover:bg-blue-300 py-2 px-4 rounded"
            onClick={toggleDropdown}
          >
            ☰
          </button>
          {isDropdownOpen && (
            <div className="absolute top-14 right-0 mt-2 w-60 bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 rounded-lg shadow-lg" style={{ right: '-15px' }}>
              <nav className="flex flex-col p-2">
                <button
                  className="text-left bg-transparent text-white hover:bg-blue-300 py-2 px-4 rounded"
                  onClick={openChangePasswordModal}
                >
                  <FontAwesomeIcon icon={faLock} className="mr-2" /> {/* Icono de cambio de contraseña */}

                  Cambiar Contraseña
                </button>
                <button
                  className="text-left bg-transparent text-white hover:bg-blue-300 py-2 px-4 rounded"
                  onClick={() => setActiveView('logs')}
                >
                    <FontAwesomeIcon icon={faFileAlt} className="mr-2" /> {/* Icono de documentos asignados */}

                  Logs
                </button>
                <button
                  className="text-left bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white  hover:bg-blue-300 py-2 px-4 rounded"
                  onClick={handleLogout}
                >
                          <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" /> {/* Icono de cerrar sesión */}

                  Cerrar Sesión
                </button>
              </nav>
            </div>
          )}
        </nav>
      </header>
      <Modal
        isOpen={isChangePasswordModalOpen}
        onRequestClose={closeChangePasswordModal}
        contentLabel="Cambiar Contraseña"
        className="bg-white p-6 rounded-lg shadow-lg w-96 mx-auto mt-20"
        overlayClassName="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center"
      >
        <h2 className="text-xl font-bold mb-4">Cambiar Contraseña</h2>
        <p className="mb-4 font-bold">Correo electrónico:</p>
        <p className="mb-4 ">
          {role === 'clinic' ? email : emailAdmin}
        </p>
        <div className="mb-4">
          <label className="block font-bold mb-2">Contraseña Actual</label>
          <input
            type="password"
            className="w-full p-2 border border-gray-300 rounded"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
        </div>
        <div className="mb-4">
          <label className="block font-bold mb-2">Nueva Contraseña</label>
          <input
            type="password"
            className="w-full p-2 border border-gray-300 rounded"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </div>
        <div className="flex justify-between">
          <button
            className="bg-gray-500 text-white font-bold py-2 px-4 rounded hover:bg-gray-700"
            onClick={closeChangePasswordModal}
          >
            Cancelar
          </button>
          <button
            className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white font-bold py-2 px-4 rounded hover:bg-blue-700"
            onClick={handleChangePassword}
          >
            Cambiar Contraseña
          </button>
        </div>
      </Modal>
      {/* Menú lateral para pantallas grandes */}
      <div className="flex">
        {/* Menú lateral */}
        <aside className={`hidden md:block w-1/6 p-4 border-r`}>
  <ul className="space-y-4">
    <li
      className={`cursor-pointer p-2 rounded ${activeView === 'dashboard' ? 'bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white' : 'hover:bg-blue-600'} active:bg-blue-600`}
      onClick={() => setActiveView('dashboard')}
    >
      <i className="fas fa-tachometer-alt mr-2"></i> Tablero de Control
    </li>
    <li
      className={`cursor-pointer p-2 rounded ${activeView === 'admin' ? 'bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white' : 'hover:bg-blue-600'} active:bg-blue-600`}
      onClick={() => setActiveView('admin')}
    >
      <i className="fas fa-users-cog mr-2"></i> Administradores
    </li>
    {/* <li
      className="cursor-pointer p-2 rounded hover:bg-blue-600"
      onClick={() => setActiveComponent('rolesGrupo')}
    >
      <i className="fas fa-user-shield mr-2"></i> Roles de Grupo
    </li> */}
    <li
      className={`cursor-pointer p-2 rounded ${activeView === 'doctor' ? 'bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white' : 'hover:bg-blue-600'} active:bg-blue-600`}
      onClick={() => setActiveView('doctor')}
    >
      <i className="fas fa-user-md mr-2"></i> Médicos
    </li>
    <li
      className={`cursor-pointer p-2 rounded ${activeView === 'patient' ? 'bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white' : 'hover:bg-blue-600'} active:bg-blue-600`}
      onClick={() => setActiveView('patient')}
    >
      <i className="fas fa-procedures mr-2"></i> Pacientes
    </li>
    {/*<li
      className="cursor-pointer p-2 rounded hover:bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900"
      onClick={() => setActiveComponent('facturacion')}
    >
      <i className="fas fa-file-invoice-dollar mr-2"></i> Facturación
    </li>
    <li
      className={`cursor-pointer p-2 rounded ${activeView === 'report' ? 'bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white' : 'hover:bg-blue-600'} active:bg-blue-600`}
      onClick={() => setActiveView('report')}
    >
      <i className="fas fa-chart-line mr-2"></i> Reportes
    </li>
    <li
      className="cursor-pointer p-2 rounded hover:bg-blue-600"
      onClick={() => setActiveComponent('soporte')}
    >
      <i className="fas fa-life-ring mr-2"></i> Soporte
    </li> */}
    <li
      className={`cursor-pointer p-2 rounded ${activeView === 'perfil' ? 'bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white' : 'hover:bg-blue-600'} active:bg-blue-600`}
      onClick={() => setActiveView('perfil')}
    >
      <i className="fas fa-user-circle mr-2"></i> Mi Perfil
    </li>
  </ul>
</aside>


        {/* Contenido principal */}
        <main className="w-full p-4">
          {renderView()}
        </main>
      </div>

      {/* Menú lateral para pantallas pequeñas */}
      {/* El margin boton (mb-9) es pa que se vea el boton menu */}
      <div className="md:hidden flex flex-col mb-9">
        <button
          className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 hover:bg-blue-500 text-white font-bold py-2 px-4 rounded mb-2"
          onClick={toggleMenu}
        >
          Menú
        </button>
        {isMenuOpen && (
          <aside className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white font-bold w-full h-full fixed top-0 left-0 p-4 border-r z-50">
            <button
              className="absolute top-2 right-2 text-white text-2xl"
              onClick={toggleMenu}
            >
              &times;
            </button>
            <ul className="space-y-4">
              <li
                className={`cursor-pointer p-2 rounded ${activeView === 'dashboard' ? 'bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900' : 'hover:bg-blue-700'}`}
                onClick={() => {
                  setActiveView('dashboard');
                  setIsMenuOpen(false); // Cierra el menú al seleccionar una opción
                }}
              >
                Tablero de Control
              </li>
              <li
                className={`cursor-pointer p-2 rounded ${activeView === 'admin' ? 'bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white' : 'hover:bg-blue-700'}`}
                onClick={() => {
                  setActiveView('admin');
                  setIsMenuOpen(false); // Cierra el menú al seleccionar una opción
                }}
              >
                Administradores
              </li>
              <li
                className={`cursor-pointer p-2 rounded ${activeView === 'doctor' ? 'bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white' : 'hover:bg-blue-700'}`}
                onClick={() => {
                  setActiveView('doctor');
                  setIsMenuOpen(false); // Cierra el menú al seleccionar una opción
                }}
              >
                Médicos
              </li>
              <li
                className={`cursor-pointer p-2 rounded ${activeView === 'patient' ? 'bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white' : 'hover:bg-blue-700'}`}
                onClick={() => {
                  setActiveView('patient');
                  setIsMenuOpen(false); // Cierra el menú al seleccionar una opción
                }}
              >
                Pacientes
              </li>
              <li
                className="cursor-pointer p-2 rounded hover:bg-blue-600"
                onClick={() => setActiveComponent('facturacion')}
              >
                Facturación
              </li>
              <li
                className={`cursor-pointer p-2 rounded ${activeView === 'reportes' ? 'bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white' : 'hover:bg-blue-700'}`}
                onClick={() => {
                  setActiveView('report');
                  setIsMenuOpen(false); // Cierra el menú al seleccionar una opción
                }}
              >
                Reportes
              </li>
              <li
                className="cursor-pointer p-2 rounded hover:bg-blue-600"
                onClick={() => setActiveComponent('soporte')}
              >
                Soporte
              </li>
              <li
                className={`cursor-pointer p-2 rounded ${activeView === 'perfil' ? 'bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white' : 'hover:bg-blue-700'}`}
                onClick={() => {
                  setActiveView('perfil');
                  setIsMenuOpen(false); // Cierra el menú al seleccionar una opción
                }}
              >
                Mi Perfil
              </li>
            </ul>
          </aside>
        )}
        <div className="fixed bottom-0 left-0 w-full bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 p-4 flex justify-between items-center md:hidden">
          {logoClinic != '' && (
            <div className="container-image-profile-clinic m-0 p-0 items-center" >
              <img src={logoClinic} alt="Logo" width="50" height="50" className="img-profile mr-4" />
            </div>
          )}
          <button
            className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white font-bold hover:bg-blue-600 py-2 px-4 rounded"
            onClick={toggleDropdown}
          >
            ☰
          </button>
          {isDropdownOpen && (
            <div className="absolute bottom-full left-0 mb-2 w-48 bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 rounded-lg shadow-lg">
              <nav className="flex flex-col p-2">
                <button
                  className="text-left bg-transparent text-white hover:bg-blue-300 py-2 px-4 rounded"
                  onClick={openChangePasswordModal}
                >
                  Cambiar Contraseña
                </button>
                <button
                  className="text-left bg-transparent text-white hover:bg-blue-300 py-2 px-4 rounded"
                  onClick={() => setActiveComponent('logs')}
                >
                  Logs
                </button>
                <button
                  className="text-left bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 transparent text-white  hover:bg-blue-300 py-2 px-4 rounded"
                  onClick={handleLogout}
                >
                  Cerrar Sesión
                </button>
              </nav>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
