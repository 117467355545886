import React, { useContext, useState, useEffect } from 'react';
import MainOfficeModal from './MainOfficeModal';
import OfficeModal from './OfficeModal';
import { FormContext } from './FormContext';

const Sedes = ({ handleNext, handlePrevious }) => {
    const { formState, updateFormState } = useContext(FormContext);
    const [mainOffice, setMainOffice] = useState(formState.sedes[0] || null);
    const [offices, setOffices] = useState(formState.sedes.slice(1) || []);
    const [isMainOfficeModalOpen, setIsMainOfficeModalOpen] = useState(false);
    const [isOfficeModalOpen, setIsOfficeModalOpen] = useState(false);

    useEffect(() => {
        setMainOffice(formState.sedes[0] || null);
        setOffices(formState.sedes.slice(1) || []);
    }, [formState.sedes]);

    const addMainOffice = (officeData) => {
        setMainOffice(officeData);
    };

    const addOffice = (officeData) => {
        setOffices([...offices, officeData]);
    };

    const removeOffice = (index) => {
        const newOffices = [...offices];
        newOffices.splice(index, 1);
        setOffices(newOffices);
    };

    const handleNextStep = () => {
        updateFormState('sedes', [mainOffice, ...offices]);
        handleNext();
    };

    return (
        <div className="max-w-4xl mx-auto p-4 bg-white shadow-md rounded-lg">
            <div className="mb-4">
                <h2 className="text-xl font-bold mb-2">Sede principal</h2>
                <div className="flex items-center mb-2">
                    <div className="flex-grow border p-2 rounded bg-gray-100">
                        {mainOffice ? (
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div><strong>Nombre de Sede:</strong> {mainOffice.name}</div>
                                <div><strong>Departamento:</strong> {mainOffice.departmentName}</div>
                                <div><strong>Ciudad:</strong> {mainOffice.mainCity}</div>
                                <div><strong>Código:</strong> {mainOffice.code}</div>
                                <div><strong>Dirección:</strong> {mainOffice.address}</div>
                                <div><strong>Teléfono:</strong> {mainOffice.phone}</div>
                                <div><strong>Correo Electrónico:</strong> {mainOffice.email}</div>
                                <div><strong>Zona:</strong> {mainOffice.zone}</div>
                                <div><strong>Nivel de Atención:</strong> {mainOffice.attentionLevel}</div>
                                <div><strong>Complejidad:</strong> {mainOffice.complexity}</div>
                            </div>
                        ) : ''}
                    </div>
                    <button
                        className="ml-4 bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white font-bold py-2 px-4 rounded hover:bg-blue-700"
                        onClick={() => setIsMainOfficeModalOpen(true)}
                    >
                        Agregar
                    </button>
                </div>
            </div>
            <hr className="mb-4" />
            <div className="mb-4">
                <h2 className="text-xl font-bold mb-2">Sedes</h2>
                {offices.map((office, index) => (
                    <div key={index} className="flex items-center mb-2">
                        <div className="flex-grow border p-2 rounded bg-gray-100">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div><strong>Nombre de Sede:</strong> {office.name}</div>
                                <div><strong>Departamento:</strong> {office.departmentName}</div>
                                <div><strong>Ciudad:</strong> {office.City}</div>
                                <div><strong>Código:</strong> {office.code}</div>
                                <div><strong>Dirección:</strong> {office.address}</div>
                                <div><strong>Teléfono:</strong> {office.phone}</div>
                                <div><strong>Correo Electrónico:</strong> {office.email}</div>
                                <div><strong>Zona:</strong> {office.zone}</div>
                                <div><strong>Nivel de Atención:</strong> {office.attentionLevel}</div>
                                <div><strong>Complejidad:</strong> {office.complexity}</div>
                            </div>
                        </div>
                        <button
                            className="ml-4 bg-red-500 text-white font-bold py-2 px-4 rounded hover:bg-red-700"
                            onClick={() => removeOffice(index)}
                        >
                            Eliminar
                        </button>
                    </div>
                ))}
                <button
                    className="mt-2 bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-700"
                    onClick={() => setIsOfficeModalOpen(true)}
                >
                    Agregar
                </button>
            </div>
            <hr className="mb-4" />
            <div className="flex justify-between mt-4">
                <button
                    className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-700"
                    onClick={handlePrevious}
                >
                    Anterior
                </button>
                <button
                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
                    onClick={handleNextStep}
                >
                    Siguiente
                </button>
            </div>
            <MainOfficeModal
                isOpen={isMainOfficeModalOpen}
                onClose={() => setIsMainOfficeModalOpen(false)}
                onSave={addMainOffice}
            />
            <OfficeModal
                isOpen={isOfficeModalOpen}
                onClose={() => setIsOfficeModalOpen(false)}
                onSave={addOffice}
            />
        </div>
    );
};

export default Sedes;
