import React, { useState, useEffect } from 'react'
import axios from 'axios';
import '../../styles/Doctor.css';

const DocumentsVideoCall = ({ appointmentId }) => {
    const [documentos, setDocumentos] = useState(null);
    const [notasConsulta, setNotasConsulta] = useState(null);

    console.log('appointmentId:', appointmentId);

    useEffect(() => {
        const token = localStorage.getItem('token');
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/doctor/getDocumentsAppointment/${appointmentId}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((response) => {
            console.log("documentos: ", response.data)
            setDocumentos(response.data);
        })
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/doctor/getNotesAppointment/${appointmentId}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((response) => {
            console.log("notas: ", response.data)
            setNotasConsulta(response.data);
        })

    }, [appointmentId])


    const onHandleClickView = (url) => {
        window.open(url, '_blank');
    }

    return (
        <div className="container-documents-video-call">
            <h1 className="mb-2 title-resume-video-call text-center uppercase text-gray-600"> Resumen de la Video Consulta </h1>
            {documentos && (
                <>
                    <h2 className="mt-1 font-bold text-left uppercase text-gray-600"> Documentos Generados </h2>
                    <div className="overflow-x-auto w-full max-h-50">
                        <table className="overflow-auto mt-2 w-full min-w-full divide-y border border-gray-300 divide-gray-300 border-collapse rounden-lg">
                            <thead className="bg-gray-200 text-center">
                                <tr>
                                    <th scope="col" className="px-6 py-3 text-sm font-medium text-gray-900 uppercase tracking-wider">
                                        Prescripción
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-sm font-medium text-gray-900 uppercase tracking-wider">
                                        Documento
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-300">
                                {documentos && Object.entries(documentos).map(([key, value]) => (
                                    <>
                                        {value && value.map((document, index) => (
                                            <tr key={index} className="divide-x divide-darkgray-200">
                                                <td className="px-4 py-2">
                                                    <p className=" uppercase text-sm text-wrap text-center font-medium text-gray-500"> {key + '_' + document._id} </p>
                                                </td>

                                                <td className="px-4 py-2">
                                                    <div className="flex flex-col md:flex-row items-center justify-center space-x-0 md:space-x-2 space-y-2 md:space-y-0">
                                                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 w-full md:w-auto rounded" onClick={() => onHandleClickView(document.url)} > Ver </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </>
            )}
            {notasConsulta && (
                <>
                    <h2 className="mt-4 font-bold text-left uppercase text-gray-600"> Notas de Consulta </h2>
                    <div className="max-h-64 overflow-y-auto">
                        {notasConsulta && Object.entries(notasConsulta).map(([key, value]) => (
                            <>
                                {value && value.notas.map((note, index) => (
                                    <dl key={index}>
                                        <dt className="mt-2 text-gray-600"> {note.titulo} </dt>
                                        <dd className="text-gray-500 text-sm"> {note.descripcion} </dd>
                                    </dl>
                                ))}
                            </>
                        ))}
                    </div>
                </>
            )}
        </div>
    )
}

export default DocumentsVideoCall;