import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import Select from 'react-select';
import { useCombobox } from 'downshift';

const Medicamentos = ({ appointmentId }) => {
  const [appointmentInfo, setAppointmentInfo] = useState({
    idType: '',
    idNumber: '',
    dob: '',
    firstLastName: '',
    secondLastName: '',
    firstName: '',
    secondName: '',
    gender: '',
    neighborhood: '',
    email: '',
    cellNumber: '',
    notes: '',
    fullName: '',
    service: '',
    nit: '',
    clinicName: ''

  });
  const [formData, setFormData] = useState({
    institucionNombre: '',
    institucionDireccion: '',
    institucionNIT: '',
    institucionTelefono: '',
    medicamentos: [],
  });


  const [medicamento, setMedicamento] = useState({
    denominacionComun: '',
    concentracion: '',
    formaFarmaceutica: '',
    dosis: '',
    viaAdministracion: '',
    unidadFrecuencia: "horas",

    frecuencia: '',
    diasTratamiento: '',
    cantidadNumeros: '',
    cantidadLetras: '',
    indicaciones: '',
  });



  const [medicamentosOptions, setMedicamentosOptions] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedMedicamento, setSelectedMedicamento] = useState(null);
  const [manualEntryMode, setManualEntryMode] = useState(false);
  const [manualEntryText, setManualEntryText] = useState('');
  useEffect(() => {
    fetchAppointmentInfo();
    loadFormDataFromLocalStorage();
  }, [appointmentId]);

  useEffect(() => {
    saveFormDataToLocalStorage();
  }, [formData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleMedicamentoChange = (e) => {
    const { name, value } = e.target;
    setMedicamento(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const numeroALetras = (num) => {
    const unidades = ["", "uno", "dos", "tres", "cuatro", "cinco", "seis", "siete", "ocho", "nueve"];
    const decenas = ["", "diez", "veinte", "treinta", "cuarenta", "cincuenta", "sesenta", "setenta", "ochenta", "noventa"];
    const centenas = ["", "cien", "doscientos", "trescientos", "cuatrocientos", "quinientos", "seiscientos", "setecientos", "ochocientos", "novecientos"];

    if (num < 10) return unidades[num];
    if (num < 100) return decenas[Math.floor(num / 10)] + (num % 10 !== 0 ? " y " + unidades[num % 10] : "");
    if (num < 1000) return centenas[Math.floor(num / 100)] + (num % 100 !== 0 ? " " + numeroALetras(num % 100) : "");

    return num.toString(); // Por simplicidad, solo convertimos hasta cientos.
  };
  useEffect(() => {
    const { dosis, frecuencia, diasTratamiento } = medicamento;

    if (dosis && frecuencia && diasTratamiento) {
      const cantidadTotal = dosis * (24 / frecuencia) * diasTratamiento;
      setMedicamento((prev) => ({
        ...prev,
        cantidadNumeros: cantidadTotal,
        cantidadLetras: numeroALetras(cantidadTotal)
      }));
    }
  }, [medicamento.dosis, medicamento.frecuencia, medicamento.diasTratamiento]);


  const handleDenominacionChange = (selectedOption) => {
    setMedicamento(prevState => ({
      ...prevState,
      denominacionComun: selectedOption ? selectedOption.value : '',
    }));
  };

  const handleAddMedicamento = () => {
    const {
      denominacionComun,
      concentracion,
      formaFarmaceutica,
      dosis,
      viaAdministracion,
      frecuencia,
      diasTratamiento,
      cantidadNumeros,
      cantidadLetras,
      indicaciones
    } = medicamento;

    // Validar que todos los campos estén diligenciados
    if (!denominacionComun) {
      return Swal.fire('Error', 'Por favor, completa el campo Denominación Común', 'error');
    }

    if (!formaFarmaceutica) {
      return Swal.fire('Error', 'Por favor, completa el campo Forma Farmacéutica', 'error');
    }
    if (!dosis) {
      return Swal.fire('Error', 'Por favor, completa el campo Dosis', 'error');
    }
    if (!viaAdministracion) {
      return Swal.fire('Error', 'Por favor, completa el campo Vía de Administración', 'error');
    }
    if (!frecuencia) {
      return Swal.fire('Error', 'Por favor, completa el campo Frecuencia', 'error');
    }
    if (!diasTratamiento) {
      return Swal.fire('Error', 'Por favor, completa el campo Días de Tratamiento', 'error');
    }
    if (!cantidadNumeros) {
      return Swal.fire('Error', 'Por favor, completa el campo Cantidad en Números', 'error');
    }
    if (!cantidadLetras) {
      return Swal.fire('Error', 'Por favor, completa el campo Cantidad en Letras', 'error');
    }
    if (!indicaciones) {
      return Swal.fire('Error', 'Por favor, completa el campo Indicaciones', 'error');
    }

    // Si todo está completo, se ejecuta la lógica de añadir el medicamento
    setFormData(prevState => ({
      ...prevState,
      medicamentos: [...prevState.medicamentos, { ...medicamento }],
    }));

    // Reiniciar todos los campos del medicamento después de agregar
    setMedicamento({
      denominacionComun: '',
      concentracion: '',
      formaFarmaceutica: '',
      dosis: '',
      viaAdministracion: '',
      frecuencia: '',
      diasTratamiento: '',
      cantidadNumeros: '',
      cantidadLetras: '',
      indicaciones: '',
    });



  };


  const handleRemoveMedicamento = (index) => {
    setFormData(prevState => ({
      ...prevState,
      medicamentos: prevState.medicamentos.filter((_, i) => i !== index),
    }));
  };

  const fetchAppointmentInfo = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/clinic/appointment-info/${appointmentId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAppointmentInfo(response.data);
    } catch (error) {
      console.error('Error al obtener la información de la cita:', error);
    }
  };

  useEffect(() => {
    if (appointmentInfo) {
      setFormData({
        institucionNombre: appointmentInfo.clinicName || '',
        institucionDireccion: appointmentInfo.address || '',
        institucionNIT: appointmentInfo.nit || '',
        institucionTelefono: appointmentInfo.phone || '',
        medicamentos: []
      });
    }
  }, [appointmentInfo]);

  const handleSearchQueryChange = async (inputValue) => {
    setSearchQuery(inputValue);
    if (inputValue.length > 2) {
      await fetchMedicamentosOptions(inputValue);
    } else {
      setMedicamentosOptions([]);
    }
  };
  const normalizeAndSplit = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().split(" ");
  };


  const fetchMedicamentosOptions = async (query) => {
    setLoading(true);
    try {
      // Normalizar y dividir la consulta
      const normalizedQueryWords = normalizeAndSplit(query); // Normalizar el query
      const token = localStorage.getItem('token');

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/clinic/searchCums`,
        {
          params: { query: normalizedQueryWords.join(" ") }, // Unir palabras normalizadas
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log('Response:', response.data);
      const options = response.data.map((med) => ({
        value: med.Codigo,
        label: `${med.Nombre} - ${med.Descripcion}`, // Concatenar aquí
      }));

      console.log('Fetched options:', options);
      setMedicamentosOptions(options);
    } catch (error) {
      console.error('Error al obtener la lista de medicamentos:', error);
    } finally {
      setLoading(false);
    }
  };
  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getItemProps,
    highlightedIndex,
    reset,
  } = useCombobox({
    items: medicamentosOptions,
    onInputValueChange: ({ inputValue }) => {
      fetchMedicamentosOptions(inputValue); // Fetch options as user types
    },
    onSelectedItemChange: ({ selectedItem }) => {
      setSelectedMedicamento(selectedItem); // Maneja el medicamento seleccionado
      setMedicamento((prev) => ({
        ...prev,
        denominacionComun: selectedItem ? selectedItem.label : '', // Guarda el valor en denominacionComun
      }));
    },
    itemToString: (item) => (item ? item.label : ''),
  });

  const handleManualEntryToggle = () => {
    setManualEntryMode(!manualEntryMode);
    if (!manualEntryMode) {
      reset(); // Resetear la selección cuando se activa el modo manual
    }
  };

  const handleManualEntrySubmit = () => {
    setMedicamento((prev) => ({
      ...prev,
      denominacionComun: manualEntryText,
    }));
  };



  const saveFormDataToLocalStorage = () => {
    localStorage.setItem(`formData`, JSON.stringify(formData));
  };

  const loadFormDataFromLocalStorage = () => {
    const storedData = localStorage.getItem(`formData`);
    if (storedData) {
      setFormData(JSON.parse(storedData));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      const clinicId = localStorage.getItem('clinicId');

      if (!formData.medicamentos || formData.medicamentos.length === 0) {
        Swal.fire({
          title: 'Error',
          text: 'Debes agregar al menos un medicamento.',
          icon: 'warning',
          confirmButtonText: 'Aceptar',
        });
        return; // Detener la ejecución si no hay medicamentos
      }

      const response = await axios({
        url: `${process.env.REACT_APP_BACKEND_URL}/api/pdf/generateMedicamentosPDF`,
        method: 'POST',
        data: {
          appointmentId,
          clinicId: clinicId,
          patientInfo: {
            patientId: appointmentInfo.patientId,
            firstName: appointmentInfo.firstName,
            secondName: appointmentInfo.secondName,
            firstLastName: appointmentInfo.firstLastName,
            secondLastName: appointmentInfo.secondLastName,
            documentNumber: appointmentInfo.documentNumber,
            dob: appointmentInfo.dob,
            idType: appointmentInfo.idType,
            gender: appointmentInfo.gender,
            cellNumber: appointmentInfo.cellNumber,
          },
          doctorInfo: {
            doctorId: appointmentInfo.doctorId,
            fullName: appointmentInfo.fullName,
            service: appointmentInfo.service,
            idNumber: appointmentInfo.idNumber,
            cellNumber: appointmentInfo.cellNumber,
            notes: appointmentInfo.notes,
          },
          formData: {
            institucionNombre: formData.institucionNombre,
            institucionDireccion: formData.institucionDireccion,
            institucionNIT: formData.institucionNIT,
            institucionTelefono: formData.institucionTelefono,
            medicamentos: formData.medicamentos,
          },
        },
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `medicamentos-${appointmentId}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();

      Swal.fire({
        title: '¡PDF Generado!',
        text: 'El archivo PDF ha sido generado y descargado exitosamente.',
        icon: 'success',
        confirmButtonText: 'Aceptar',
      });
      setMedicamento({
        denominacionComun: '',
        concentracion: '',
        formaFarmaceutica: '',
        dosis: '',
        viaAdministracion: '',
        frecuencia: '',
        diasTratamiento: '',
        cantidadNumeros: '',
        cantidadLetras: '',
        indicaciones: '',
      });
      fetchAppointmentInfo();
    } catch (error) {
      console.error('Error al generar el PDF de medicamentos:', error);
      Swal.fire({
        title: 'Error',
        text: 'Hubo un problema al generar el PDF.',
        icon: 'error',
        confirmButtonText: 'Aceptar',
      });
    }
  };


  return (
    <div className="h-[380px] max-w-[930px] overflow-y-auto p-2">
      <h2 className="text-center font-bold bg-blue-600 p-2 text-white  text-lg">
        Fórmula Médica
      </h2>
      <hr className="my-4" />

      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Nombre de la IPS:
          </label>
          <input
            type="text"
            name="institucionNombre"
            value={appointmentInfo.clinicName}
            readOnly
            className="w-full p-2 border bg-gray-200 border-gray-300 rounded" />

        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Dirección:
          </label>
          <input
            type="text"
            name="institucionDireccion"
            value={appointmentInfo.address}
            readOnly
            className="w-full p-2 border bg-gray-200 border-gray-300 rounded" />

        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            NIT:
          </label>
          <input
            type="text"
            name="institucionNIT"
            value={appointmentInfo.nit}
            readOnly
            className="w-full p-2 border bg-gray-200 border-gray-300 rounded" />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Teléfono:
          </label>
          <input
            type="text"
            name="institucionTelefono"
            value={appointmentInfo.phone}
            readOnly
            className="w-full p-2 border bg-gray-200 border-gray-300 rounded" />
        </div>

        <h3 className="text-lg font-bold mt-4">Agregar Medicamento</h3>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Denominación Común Internacional:
          </label>
          <div className="mt-1 block w-full">
            {!manualEntryMode && (
              <>
                <input
                  {...getInputProps()}
                  placeholder="Buscar medicamento..."
                  className="border rounded p-2 w-full"
                  defaultValue={medicamento.denominacionComun} // Mostrar el valor guardado
                />
                <ul {...getMenuProps()} className="border rounded max-h-48 overflow-y-auto">
                  {isOpen && (loading ? (
                    <li>Cargando...</li>
                  ) : (
                    medicamentosOptions.map((option, index) => (
                      <li
                        key={option.value}
                        {...getItemProps({ item: option, index })}
                        className={`p-2 cursor-pointer ${highlightedIndex === index ? 'bg-gray-200' : ''}`}
                      >
                        {option.label}
                      </li>
                    ))
                  ))}
                </ul>
              </>
            )}
            {manualEntryMode && (
              <div>
                <input
                  type="text"
                  value={manualEntryText}
                  onChange={(e) => setManualEntryText(e.target.value)}
                  placeholder="Ingrese el medicamento manualmente..."
                  className="border rounded p-2 w-full mb-2"
                />
                <button
                  type="button"
                  onClick={handleManualEntrySubmit}
                  className="px-4 py-2 rounded bg-blue-500 text-white"
                >
                  Guardar entrada manual
                </button>
              </div>
            )}
            <button
              type="button"
              onClick={handleManualEntryToggle}
              className={`mt-2 px-4 py-2 rounded ${manualEntryMode ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
            >
              {manualEntryMode ? 'Cancelar entrada manual' : 'Entrada manual'}
            </button>
          </div>
        </div>


        <div>
          <label className="block text-sm font-medium text-gray-700">
            Concentración:
          </label>
          <input
            type="text"
            name="concentracion"
            value={medicamento.concentracion}
            onChange={handleMedicamentoChange}
            className="mt-1 block w-full border border-gray-300 rounded-md  p-2 shadow-sm"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Forma Farmacéutica:
          </label>
          <select
            name="formaFarmaceutica"
            value={medicamento.formaFarmaceutica}
            onChange={handleMedicamentoChange}
            className="mt-1 block w-full border border-gray-300 rounded-md  p-2 shadow-sm"
          >
            <option value="">Selecciona una opción</option>
            <option value="comprimido">Comprimido</option>
            <option value="capsula">Cápsula</option>
            <option value="sobre">Sobre</option>
            <option value="jarabe">Jarabe</option>
            <option value="gotas">Gotas</option>
            <option value="ampolla">Ampolla</option>
            <option value="pomada">Pomada</option>
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Dosis:
          </label>
          <input
            type="number"
            name="dosis"
            value={medicamento.dosis}
            onChange={handleMedicamentoChange}
            className="mt-1 block w-full border border-gray-300 rounded-md p-2 shadow-sm"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Frecuencia:
          </label>
          <input
            type="number"
            name="frecuencia"
            value={medicamento.frecuencia}
            onChange={handleMedicamentoChange}
            className="mt-1 block w-full border border-gray-300 rounded-md p-2 shadow-sm"
          />
          <input
            name="unidadFrecuencia"
            readOnly
            value='Horas'
            onChange={handleMedicamentoChange}
            className="w-full p-2 border bg-gray-200 border-gray-300 rounded"
          >


          </input>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Vía de Administración:
          </label>
          <select
            name="viaAdministracion"
            value={medicamento.viaAdministracion}
            onChange={handleMedicamentoChange}
            className="mt-1 block w-full border border-gray-300 rounded-md  p-2 shadow-sm"
          >
            <option value="">Selecciona una opción</option>
            <option value="oral">Oral</option>
            <option value="intravenosa">Intravenosa</option>
            <option value="intramuscular">Intramuscular</option>
            <option value="subcutánea">Subcutánea</option>
            <option value="tópica">Tópica</option>
          </select>
        </div>



        <div>
          <label className="block text-sm font-medium text-gray-700">
            Días de Tratamiento:
          </label>
          <input
            type="number"
            name="diasTratamiento"
            value={medicamento.diasTratamiento}
            onChange={handleMedicamentoChange}
            className="mt-1 block w-full border border-gray-300 rounded-md p-2 shadow-sm"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Cantidad en Números:
          </label>
          <input
            type="text"
            name="cantidadNumeros"
            value={medicamento.cantidadNumeros}
            readOnly
            className="mt-1 block w-full border border-gray-300 rounded-md p-2 shadow-sm bg-gray-100"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Cantidad en Letras:
          </label>
          <input
            type="text"
            name="cantidadLetras"
            value={medicamento.cantidadLetras}
            readOnly
            className="mt-1 block w-full border border-gray-300 rounded-md p-2 shadow-sm bg-gray-100"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Indicaciones:
          </label>
          <textarea
            name="indicaciones"
            value={medicamento.indicaciones}
            onChange={handleMedicamentoChange}
            className="mt-1 block w-full border border-gray-300 rounded-md  p-2 shadow-sm"
          />
        </div>

        <button
          type="button"
          onClick={handleAddMedicamento}
          className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white px-4 py-2 rounded-md"
        >
          Agregar Medicamento
        </button>

        <table className="min-w-full mt-4 text-xs border border-gray-300">
          <thead>
            <tr>
              <th className="border-b px-4 py-2 text-left">Denominación</th>
              <th className="border-b px-4 py-2 text-left">Concentración</th>
              <th className="border-b px-4 py-2 text-left">Forma</th>
              <th className="border-b px-4 py-2 text-left">Dosis</th>
              <th className="border-b px-4 py-2 text-left">Vía</th>
              <th className="border-b px-4 py-2 text-left">Frecuencia</th>
              <th className="border-b px-4 py-2 text-left">Días</th>
              <th className="border-b px-4 py-2 text-left">Cant. Números</th>
              <th className="border-b px-4 py-2 text-left">Cant. Letras</th>
              <th className="border-b px-4 py-2 text-left">Indicaciones</th>
              <th className="border-b px-4 py-2 text-left">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {formData.medicamentos.map((med, index) => (
              <tr key={index}>
                <td className="border-b px-4 py-2">{med.denominacionComun.toUpperCase()}</td>
                <td className="border-b px-4 py-2">{med.concentracion.toUpperCase()}</td>
                <td className="border-b px-4 py-2">{med.formaFarmaceutica.toUpperCase()}</td>
                <td className="border-b px-4 py-2">{med.dosis.toUpperCase()}</td>
                <td className="border-b px-4 py-2">{med.viaAdministracion.toUpperCase()}</td>
                <td className="border-b px-4 py-2">
                  {`CADA ${med.frecuencia.toUpperCase()} HORAS`}
                </td>
                <td className="border-b px-4 py-2">{med.diasTratamiento.toUpperCase()}</td>
                <td className="border-b px-4 py-2">{med.cantidadNumeros}</td>
                <td className="border-b px-4 py-2">{med.cantidadLetras.toUpperCase()}</td>
                <td className="border-b px-4 py-2">{med.indicaciones.toUpperCase()}</td>
                <td className="border-b px-4 py-2">
                  <button
                    onClick={() => handleRemoveMedicamento(index)}
                    className="bg-red-500 text-white py-1 px-2 rounded-md"
                  >
                    Eliminar
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <button
          type="submit"
          className="bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 text-white px-4 py-2 rounded-md"
        >
          Enviar
        </button>
      </form>
    </div>
  );
};

export default Medicamentos;
