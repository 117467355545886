import axios from 'axios';
import React, { useEffect, useState } from 'react';
import 'tailwindcss/tailwind.css';
import DoctorCard from './DoctorCard';
import Swal from 'sweetalert2';

const Marketplace = ({ setActiveComponent }) => {
    const [servicio, setServicio] = useState('');
    const [orden, setOrden] = useState('');
    const [doctors, setDoctors] = useState([]);


    useEffect(() => {
        const token = localStorage.getItem('token');
        const clinicId = localStorage.getItem('clinicId');
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/patient/getDoctors/${clinicId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            console.log(response.data);
            if (response.data) {
                setDoctors(response.data);
            }
        }).catch(error => {
            Swal.fire({
                icon: 'error',
                toast: true,
                position: 'top-right',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                text: 'Ocurrió un error al cargar los doctores',
            })
        })
    }, [])

    const doctorsFiltrados = doctors
        .filter(doc => (servicio ? doc.service === servicio : true))
        .sort((a, b) => {
            if (orden === 'precio-asc') return a.priceService - b.priceService;
            if (orden === 'precio-desc') return b.priceService - a.priceService;
            return 0;
        });

    const resetFiltros = () => {
        setServicio('');
        setOrden('');
    };

    return (

        <div className="flex flex-col md:flex-row p-4">
            <Filtros
                orden={orden} setOrden={setOrden}
                servicio={servicio} setServicio={setServicio}
                resetFiltros={resetFiltros}
            />

            <div className="container mx-auto px-4 py-8">
                <div className="max-w-full mx-auto">
                    {doctorsFiltrados && doctorsFiltrados.map(doc => (
                        <DoctorCard key={doc._id} doctor={doc} setActiveComponent={setActiveComponent} />
                    ))}
                </div>
            </div>
        </div>
    );
};

const Filtros = ({ orden, setOrden, servicio, setServicio, resetFiltros }) => {
    return (
        <div className="w-full md:w-1/4 p-4 bg-gray-100">
            <h2 className="text-xl font-bold mb-4">Filtros</h2>
            <div className="mb-4">
                <label className="block text-gray-700 font-bold mb-2">Servicio</label>
                <div className="flex flex-wrap">
                    <label className="mr-4 mb-2">
                        <input
                            type="radio"
                            name="servicio"
                            value="medicina familiar"
                            onChange={() => setServicio('medicina familiar')}
                            checked={servicio === 'medicina familiar'}
                            className="mr-2"
                        />
                        Familiar
                    </label>
                    <label className="mr-4 mb-2">
                        <input
                            type="radio"
                            name="servicio"
                            value="medicina general"
                            onChange={() => setServicio('medicina general')}
                            checked={servicio === 'medicina general'}
                            className="mr-2"
                        />
                        General
                    </label>
                </div>
            </div>

            <h2 className="text-xl font-bold mb-4">Ordenar por</h2>
            <div>
                <label className="block text-gray-700 font-bold mb-2">Precio</label>
                <div className="flex flex-wrap">
                    <label className="mr-4 mb-2">
                        <input
                            type="radio"
                            name="orden"
                            value="precio-asc"
                            onChange={() => setOrden('precio-asc')}
                            checked={orden === 'precio-asc'}
                            className="mr-2"
                        />
                        Más Bajo
                    </label>
                    <label className="mr-4 mb-2">
                        <input
                            type="radio"
                            name="orden"
                            value="precio-desc"
                            onChange={() => setOrden('precio-desc')}
                            checked={orden === 'precio-desc'}
                            className="mr-2"
                        />
                        Más Alto
                    </label>
                </div>
            </div>
            <button
                onClick={resetFiltros}
                className="bg-red-500 text-white px-4 py-2 rounded w-full mt-2"
            >
                Resetear Filtros
            </button>
        </div>
    )
}

export default Marketplace;
